import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import Datatable from "components/DataTable";

function TSalaryProcessingNew() {

  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, } = configConstants;

  // UseRefs Hooks.
  const comboDataFunc = useRef();
  // ****** navigate
  const navigate = useNavigate();

  // combo's options hooks;
  const [employeeProps, setEmployeeProps] = new useState([]);
  const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
  const [monthOpts, setMonthOpts] = useState([
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ]);
  // FINANCIAL_SHORT_NAME wants like 2024
  const [yearOpts, setYearOpts] = useState(
    Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
      .map((year) => ({ label: year, value: year }))
  );

  // Input field's Hooks;
  const [cmb_employee_type, setEmployeeType] = useState('');
  // const [cmb_salary_processing_month, setSalaryProcessingMonth] = useState(new Date().getMonth());
  // const [cmb_salary_processing_year, setSalaryProcessingYear] = useState(new Date().getFullYear());

  const [cmb_salary_processing_month, setSalaryProcessingMonth] = useState('');
  const [cmb_salary_processing_year, setSalaryProcessingYear] = useState('');

  // Hooks For table;
  const [earningHeadsCols, setEarningHeadsCols] = useState([]);
  const [deductionHeadsCols, seDeductionHeadsCols] = useState([]);
  const [salaryProcessingDtlTblData, setSalaryProcessingDtlTblData] = useState([]);
  const [salaryProcessingDtlTblCols, setsalaryProcessingDtlTblCols] = useState([]);
  const [departmentOpts, setDepartmentOpts] = useState([]);
  const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
  const [cmb_departmentId, setDepartmentId] = useState(0);
  const [allDepartmentsList, setAllDepartmentsList] = useState([]);
  const [cmb_sub_department_id, setSubDepartmentId] = useState(0);

  // UseRefs Hooks For Searchable combos.
  let employeeTypesComboRef = useRef(null);
  let salaryProMonthComboRef = useRef(null);
  let salaryProYearComboRef = useRef(null);
  let cmb_department_id_ref = useRef(null);
  let subDepartmentComboRef = useRef(null);


  // Helping hooks;
  const [isLoading, setIsLoading] = useState(false);

  // Success Modal.
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const FnCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    // goBack();  // Go back after save/updated.
  };

  // Error Modal.
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const FnCloseErrModal = () => {
    setShowErrorMsgModal(false)
  };

  useEffect(() => {
    const loadDataOnload = async () => {
      setIsLoading(true);
      await FnFillComobos();
      setIsLoading(false);
    }
    loadDataOnload();
  }, [])

  const FnFillComobos = async () => {
    try {
      // Set Default Year;
      let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
      setSalaryProcessingYear(defaultYear.value);
      salaryProYearComboRef.current = defaultYear;
      // Set Default Month;
      let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
      setSalaryProcessingMonth(defaultMonth.value);
      salaryProMonthComboRef.current = defaultMonth;

      // Load employee types
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
      globalQuery.table = "amv_properties"
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdEmplTypes => {
          setEmployeeProps(rcvdEmplTypes);
          let emplTypes = [
            { value: '', label: 'Select', field_id: '', field_name: '', }
          ]
          emplTypes = rcvdEmplTypes
            .filter(prop => prop.properties_master_name === 'EmployeeType')
            .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
          setEmployeeTypesOpts(emplTypes);
        });

      resetGlobalQuery();
      globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
      globalQuery.table = "cm_department"
      globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdDepts => {
          const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
            if (department.department_type === "M") {
              acc.mainDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            } else if (department.department_type === "S") {
              acc.subDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            }
            return acc;
          }, { mainDepartments: [], subDepartments: [] });
          setAllDepartmentsList([...mainDepartments, ...subDepartments]);

          mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
          setDepartmentOpts(mainDepartments);
          subDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartments);
        });

    } catch (error) {
      console.log("error on filling combos: ", error)
      navigate('/Error')
    }
  }


  // ****-------Get Salary Processing Data Functions Starts--------------***
  const FnStartSalaryProcessing = async () => {
    try {
      if (FnValidateSalaryProcessingFrm()) {
        setIsLoading(true);
        // First Set Default Department.
        let selectedDepartment = cmb_department_id_ref.current?.department_id;
        if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
          let defaultDept = departmentOpts.find(department => department.value == 'All');
          setDepartmentId(defaultDept.value);
          cmb_department_id_ref.current = defaultDept;

          // Also Load All Sub-Department
          const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
          setSubDepartmentId('All');
          selectedDepartment = '';
        }

        // Second Set Default Sub-Department.
        let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
        if (selectedSubDepartment === '' || selectedSubDepartment === undefined || selectedSubDepartment === null) {

          const subDepartmentList = allDepartmentsList.filter(department =>
            (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
          );
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
          setSubDepartmentId('All');
        }

        let selectedEmplGrp = employeeProps.find(prop => prop.properties_master_name === 'EmployeeTypeGroup' && prop.property_value === employeeTypesComboRef.current.property_group);
        // Load Earning Heads.
        resetGlobalQuery();
        globalQuery.columns = ["earning_heads_id", "earning_head_name", "head_position",];
        globalQuery.table = "hm_earning_heads";
        if (selectedEmplGrp?.field_name && selectedEmplGrp?.field_name !== '') {
          globalQuery.conditions.push({ field: "earning_group", operator: "IN", values: ['All', selectedEmplGrp?.field_name] });
        }
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.orderBy = ["head_position Asc"];
        const rcvdEarningHeads = await comboDataFunc.current.fillFiltersCombo(globalQuery);
        setEarningHeadsCols(rcvdEarningHeads);

        // Load Decuction Heads.
        resetGlobalQuery();
        globalQuery.columns = ["deduction_heads_id", "deduction_head_name", "head_position",];
        globalQuery.table = "hm_deduction_heads";
        if (selectedEmplGrp?.field_name && selectedEmplGrp?.field_name !== '') {
          globalQuery.conditions.push({ field: "deduction_group", operator: "IN", values: ['All', selectedEmplGrp?.field_name] });
        }
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.orderBy = ["head_position Asc"];
        const rcvdDeductionHeads = await comboDataFunc.current.fillFiltersCombo(globalQuery);
        seDeductionHeadsCols(rcvdDeductionHeads);

        let jsonPayload = {
          filters: {
            company_id: parseInt(COMPANY_ID),
            employee_type: employeeTypesComboRef.current?.label || '',
            employee_group: selectedEmplGrp?.field_name || '',
            salary_month: salaryProMonthComboRef.current?.label || '',
            salary_year: salaryProYearComboRef.current?.label || '',
            department_id: cmb_department_id_ref.current?.department_id || '',
            sub_department_id: subDepartmentComboRef.current?.department_id || '',

          }
        }

        const formData = new FormData();
        formData.append(`MtSalaryProcessingFilters`, JSON.stringify(jsonPayload));
        const requestOptions = { method: 'POST', body: formData };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtSalarySummaryNew/FnDisplaySalariesCalculations/${COMPANY_ID}`, requestOptions)
        const responce = await apicall.json();
        // console.log(responce);
        if (responce !== null && apicall.status === 200) {
          let dtlsData = responce.data || [];

          if (dtlsData.length > 0) {
            // Create Header&Accessors for TableColumns
            let tblCols = [ // Default Static Columns.
              { Headers: 'Sr No', accessor: 'Sr No', Cell: (row) => row.index + 1, width: 50, },
              { Headers: 'Employee Name', accessor: 'employee_name', width: 200, },
              { Headers: 'Employee Code', accessor: 'employee_code', width: 100, },
              { Headers: 'Punch Code', accessor: 'old_employee_code', width: 100, },
              { Headers: 'Employee Type', accessor: 'employee_type', width: 100, },
              { Headers: 'Department Name', accessor: 'department_name', width: 150, },
              { Headers: 'Month Days', accessor: 'Month Days', width: 100, },
              { Headers: 'Present Days', accessor: 'Present Days', width: 100, },
            ];

            // Some Extra Columns for the Workers.
            if (selectedEmplGrp?.field_name === 'Workers') {
              tblCols.push({ Headers: 'Worked Hrs', accessor: 'Worked Hrs', width: 100, });
              // tblCols.push({ Headers: 'PerDay Salary', accessor: 'PerDay Salary', width: 100, });
              // tblCols.push({ Headers: 'PerHr Salary', accessor: 'PerHr Salary', width: 100, });
            }

            // Add the earning_heads cols
            rcvdEarningHeads
              ?.filter(earningType => !excludedColumns.includes(earningType.earning_head_name))
              ?.map((earningType) => {
                tblCols.push({ Headers: earningType.earning_head_name, accessor: earningType.earning_head_name, width: 100, });
              });
            tblCols.push({ Headers: 'Total Earning', accessor: 'Total Earning', width: 100, });

            // Add the deduction_heads cols
            rcvdDeductionHeads
              ?.filter(deductionType => !excludedColumns.includes(deductionType.deduction_head_name))
              ?.map((deductionType) => {
                tblCols.push({ Headers: deductionType.deduction_head_name, accessor: deductionType.deduction_head_name, width: 100, });
              });

            tblCols.push({ Headers: 'Total Deduction', accessor: 'Total Deduction', width: 100, });
            tblCols.push({ Headers: 'Net Salary', accessor: 'Net Salary', width: 100, });
            setsalaryProcessingDtlTblCols(tblCols);   // Set Table Columns.
            setSalaryProcessingDtlTblData(dtlsData);  // Set Table Data.
          } else {
            setsalaryProcessingDtlTblCols([]);
            setSalaryProcessingDtlTblData([]);
          }
        } else {
          setsalaryProcessingDtlTblCols([]);
          setSalaryProcessingDtlTblData([]);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const excludedColumns = ['Employee Id', 'E-PF1', 'E-PF2'];
  const FnRenderSalaryProcessingTblData = useMemo(() => {
    if (salaryProcessingDtlTblData.length === 0) {
      return null;
    }
    let selectedEmplGrp = employeeProps
      .find(prop => prop.properties_master_name === 'EmployeeTypeGroup' && prop.property_value === employeeTypesComboRef.current.property_group);

    return <>
      <Table className="erp_table" id='SalaryProcessingDtlsTbl' bordered striped hover>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Sr. No.</th>
            <th className="erp_table_th">Employee Name</th>
            <th className="erp_table_th">Employee Code</th>
            <th className="erp_table_th">Employee Type</th>
            <th className="erp_table_th">Department Name</th>
            <th className="erp_table_th">Month Days</th>
            <th className="erp_table_th">Present Days</th>

            {/*  Some Additional Fields For the Workers.  */}
            {
              selectedEmplGrp?.field_name === 'Workers'
                ? <>
                  <th className="erp_table_th">Worked Hrs</th>
                  <th className="erp_table_th">PerDay</th>
                  <th className="erp_table_th">PerHr</th>
                </>
                : null
            }

            {/* Earning Heads Cols */}
            {
              earningHeadsCols
                ?.filter(earningType => !excludedColumns.includes(earningType.earning_head_name))
                ?.map((earningType, index) => (
                  <>
                    <th className="erp_table_th">{earningType.earning_head_name}</th>
                  </>
                ))
            }
            <th className="erp_table_th">Total Earning</th>
            {/* Deduction Heads Cols */}
            {
              deductionHeadsCols
                ?.filter(deductionType => !excludedColumns.includes(deductionType.deduction_head_name))
                ?.map((deductionType, index) => (
                  <>
                    <th className="erp_table_th">{deductionType.deduction_head_name}</th>
                  </>
                ))
            }
            <th className="erp_table_th">Total Deduction</th>
            <th className="erp_table_th">Net Salary</th>
          </tr>
        </thead>
        <tbody>
          {salaryProcessingDtlTblData?.map((salaryDtl, index) => (
            <>
              <tr rowIndex={index}>
                <td className="erp_table_td ">{index + 1}</td>
                <td className="erp_table_td ">{salaryDtl['employee_name']}</td>
                <td className="erp_table_td ">{salaryDtl['employee_code']}</td>
                <td className="erp_table_td ">{salaryDtl['old_employee_code']}</td>
                <td className="erp_table_td ">{salaryDtl['employee_type']}</td>
                <td className="erp_table_td ">{salaryDtl['department_name']}</td>
                <td className="erp_table_td ">{salaryDtl['Month Days']}</td>
                <td className="erp_table_td ">{salaryDtl['Present Days']}</td>


                {/*  Some Additional Fields For the Workers.  */}
                {
                  selectedEmplGrp?.field_name === 'Workers'
                    ? <>
                      <td className="erp_table_td ">{salaryDtl['Worked Hrs']}</td>
                      <td className="erp_table_td ">{salaryDtl['PerDay Salary']}</td>
                      <td className="erp_table_td ">{salaryDtl['PerHr Salary']}</td>
                    </>
                    : null
                }

                {/* Earning Heads Cols */}
                {
                  earningHeadsCols
                    ?.filter(earningType => !excludedColumns.includes(earningType.earning_head_name))
                    ?.map((earningType, index) => (
                      <>
                        {
                          salaryDtl.hasOwnProperty(earningType.earning_head_name)
                            ? <td className={`erp_table_td ${isNumericString(salaryDtl[earningType.earning_head_name]) ? 'text-end' : ''} `}>{salaryDtl[earningType.earning_head_name]}</td>
                            : null
                        }
                      </>
                    ))
                }
                <td className="erp_table_td text-end">{salaryDtl['Total Earning']}</td>
                {/* Deduction Heads Cols */}
                {
                  deductionHeadsCols
                    ?.filter(deductionType => !excludedColumns.includes(deductionType.deduction_head_name))
                    ?.map((deductionType, index) => (
                      <>
                        {
                          salaryDtl.hasOwnProperty(deductionType.deduction_head_name)
                            ? <td className={`erp_table_td ${isNumericString(salaryDtl[deductionType.deduction_head_name]) ? 'text-end' : ''} `}>{salaryDtl[deductionType.deduction_head_name]}</td>
                            : null
                        }
                      </>
                    ))
                }
                <td className="erp_table_td text-end">{salaryDtl['Total Deduction']}</td>
                <td className="erp_table_td text-end">{salaryDtl['Net Salary']}</td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </>
  }, [salaryProcessingDtlTblData, earningHeadsCols, deductionHeadsCols]);
  // ****-------Get Salary Processing Data Functions Ends--------------***

  // ****-------Validation Functions Starts--------------***
  // Function for validate the fields at the time of button click;
  const FnValidateSalaryProcessingFrm = () => {
    if (cmb_employee_type === '') {
      $('#error_cmb_employee_type').text('Please select the atleast one...!')
      $('#error_cmb_employee_type').show();
      return false;
    } else {
      $('#error_cmb_employee_type').hide();
    }

    if (cmb_salary_processing_month === '') {
      $('#error_cmb_salary_processing_month').text('Please select the atleast one...!')
      $('#error_cmb_salary_processing_month').show();
      return false;
    } else {
      $('#error_cmb_salary_processing_month').hide();
    }

    if ((cmb_salary_processing_year.toString()) === '') {
      $('#error_cmb_salary_processing_year').text('Please select the atleast one...!')
      $('#error_cmb_salary_processing_year').show();
      return false;
    } else {
      $('#error_cmb_salary_processing_year').hide();
    }
    return true;
  }

  // Function for validate the fields on change
  const FnValidateFields = () => {
    setSalaryProcessingDtlTblData([]);
    const isEmpty = str => {
      if (typeof str === 'number' && Number.isInteger(str)) {
        str = str.toString();
      }
      return str === undefined || str === null || str.trim() !== '';
    };
    if (isEmpty(employeeTypesComboRef?.current?.value || '')) { $('#error_cmb_employee_type').hide(); }
    if (isEmpty(salaryProMonthComboRef?.current?.value || '')) { $('#error_cmb_salary_processing_month').hide(); }
    if (isEmpty(salaryProYearComboRef?.current?.value || '')) { $('#error_cmb_salary_processing_year').hide(); }
  }

  // ****-------Validation Functions Ends--------------***

  // ***----------- Helping Functions.
  function isNumericString(value) {
    const number = Number(value);
    return typeof number === 'number' && !isNaN(number) && isFinite(number);
  }

  const goBack = () => {
    navigate("/Dashboard")
  }

  // ***----------- CRUD Functions.
  const FnSaveSalaryProcessingData = async () => {
    try {
      if (FnValidateSalaryProcessingFrm() && salaryProcessingDtlTblData.length > 0) {
        setIsLoading(true);
        // Create a mapping from earning_head_name to earning_heads_id
        const earningHeadMap = earningHeadsCols.reduce((map, earning) => {
          map[earning.earning_head_name] = earning.earning_heads_id;
          return map;
        }, {});

        // Create a mapping from deduction_head_name to deduction_heads_id
        const deductionHeadMap = deductionHeadsCols.reduce((map, deduction) => {
          map[deduction.deduction_head_name] = deduction.deduction_heads_id;
          return map;
        }, {});

        let allEarningHeadColsName = earningHeadsCols.map(earning => earning.earning_head_name);
        let allDeductionHeadColsName = deductionHeadsCols.map(deduction => deduction.deduction_head_name);

        let JsonForSave = { 'AllEmployeeSalarySummaryData': [], 'AllEmployeeSalaryEarningsData': [], 'AllEmployeeSalaryDeductionsData': [], 'commonFields': {} };
        JsonForSave.commonFields = {
          company_id: parseInt(COMPANY_ID),
          company_branch_id: parseInt(COMPANY_BRANCH_ID),
          financial_year: FINANCIAL_SHORT_NAME,
          employee_type: employeeTypesComboRef.current?.label || '',
          salary_month: salaryProMonthComboRef.current?.value || '',
          salary_year: salaryProYearComboRef.current?.label || '',
          created_by: UserName,
        }

        JsonForSave.AllEmployeeSalarySummaryData = salaryProcessingDtlTblData?.map((emplSalaryDtl) => {
          const earnings = Object.entries(emplSalaryDtl)
            .filter(([key]) => allEarningHeadColsName.includes(key))
            .map(([key, value]) => ({
              ...JsonForSave.commonFields,
              salary_transaction_id: 0,
              salary_earnings_id: 0,
              salary_version: 1,
              employee_id: emplSalaryDtl.employee_id,
              department_id: emplSalaryDtl.department_id,
              sub_department_id: emplSalaryDtl.sub_department_id,
              designation_id: emplSalaryDtl.designation_id,
              head_name: key,
              earning_amount: value,
              earning_heads_id: earningHeadMap[key]  // Add the earning_heads_id
            }));

          const deductions = Object.entries(emplSalaryDtl)
            .filter(([key]) => allDeductionHeadColsName.includes(key))
            .map(([key, value]) => ({
              ...JsonForSave.commonFields,
              salary_transaction_id: 0,
              salary_deduction_id: 0,
              salary_version: 1,
              employee_id: emplSalaryDtl.employee_id,
              department_id: emplSalaryDtl.department_id,
              sub_department_id: emplSalaryDtl.sub_department_id,
              designation_id: emplSalaryDtl.designation_id,
              head_name: key,
              deduction_amount: value,
              deduction_heads_id: deductionHeadMap[key]  // Add the deduction_heads_id
            }));

          JsonForSave.AllEmployeeSalaryEarningsData.push(...earnings);
          JsonForSave.AllEmployeeSalaryDeductionsData.push(...deductions);

          return {
            ...JsonForSave.commonFields,
            salary_transaction_id: 0,
            salary_version: 1,
            employee_id: emplSalaryDtl.employee_id,
            department_id: emplSalaryDtl.department_id,
            sub_department_id: emplSalaryDtl.sub_department_id,
            designation_id: emplSalaryDtl.designation_id,
            month_days: emplSalaryDtl['Month Days'],
            salary_days: emplSalaryDtl['Present Days'],
            monthly_salary: emplSalaryDtl['Salary'],

            worked_hours: emplSalaryDtl['Worked Hrs'],       // Added for workers.
            salary_perday: emplSalaryDtl['PerDay Salary'],   // Added for workers.
            salary_perhour: emplSalaryDtl['PerHr Salary'],   // Added for workers.

            gross_salary: emplSalaryDtl['Total Earning'],
            total_deduction: emplSalaryDtl['Total Deduction'],
            net_salary: emplSalaryDtl['Net Salary'],
          };
        });
        console.log('JsonForSave: ', JsonForSave)

        const formData = new FormData();
        formData.append(`MtSalaryProcessingData`, JSON.stringify(JsonForSave));
        const requestOptions = { method: 'POST', body: formData };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtSalarySummaryNew/FnAddUpdateRecord/${COMPANY_ID}`, requestOptions)
        const responce = await apicall.json();
        // console.log(responce);
        if (responce !== null && apicall.status === 200) {
          if (responce.success === '0') {
            setErrMsg(responce.error)
            setShowErrorMsgModal(true)
          } else {
            setSuccMsg(responce.message)
            setShowSuccessMsgModal(true)
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }
  const comboBoxesOnChange = async (key) => {
    try {
      switch (key) {

        case "subDepartment":

          let selectedDepTS = cmb_department_id_ref.current.department_id;
          const subDepartmentLists = allDepartmentsList.filter(department =>
            (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
          );
          subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentLists);

          break;
        case 'Department':
          subDepartmentComboRef.current = null;
          setSubDepartmentId(0);
          let selectedDepartment = cmb_department_id_ref.current.department_id;
          const subDepartmentList = allDepartmentsList.filter(department =>
            (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
          );
          subDepartmentList.unshift({ department_id: 0, value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);

          break;


      }

    } catch (error) {
      console.log("error : ", error)
      navigate('/Error')
    }
  }


  return (
    <>
      <ComboBox ref={comboDataFunc} />

      <DashboardLayout>
        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        {/* Card Starts*/}
        <div className="card mt-4">
          <div className="card-header py-0 main_heding">
            <label className="erp-form-label-lg">Salary Processing</label>
          </div>

          {/* Card's Body Starts*/}
          <div className="card-body">
            <div className="row">
              {/* First Column Starts*/}
              <div className="col-sm-4 erp_form_col_div">
                <div className="row">
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Department</Form.Label>
                  </div>
                  <div className='col'>
                    <Select
                      ref={cmb_department_id_ref}
                      options={departmentOpts}
                      inputId="cmb_departmentId"
                      value={departmentOpts.length > 1 ? departmentOpts.find(option => option.value === cmb_departmentId) : null}
                      onChange={(selectedOpt) => {
                        setDepartmentId(selectedOpt.value);
                        cmb_department_id_ref.current = selectedOpt;
                        // comboBoxesOnChange("department");
                        comboBoxesOnChange('Department')
                      }}
                      placeholder="Search for a Department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                  <div className='col'>
                    <Select ref={subDepartmentComboRef}
                      options={subDepartmentOpts}
                      inputId="cmb_sub_department_id"
                      value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                      onChange={(selectedOpt) => {
                        setSubDepartmentId(selectedOpt.value);
                        subDepartmentComboRef.current = selectedOpt;
                        comboBoxesOnChange("subDepartment");
                      }}
                      placeholder="Search for a sub-department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>


              </div>
              {/* First Column Ends*/}

              {/* Second Column Starts*/}
              <div className="col-sm-3 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Month </Form.Label> </div>
                  <div className='col'>
                    <Select ref={salaryProMonthComboRef}
                      options={monthOpts}
                      inputId="cmb_salary_processing_month" // Provide the ID for the input box
                      value={monthOpts.find(option => option.value == cmb_salary_processing_month)}
                      onChange={(selectedOpt) => {
                        setSalaryProcessingMonth(selectedOpt.value);
                        salaryProMonthComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a month..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_processing_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Year </Form.Label> </div>
                  <div className='col'>
                    <Select ref={salaryProYearComboRef}
                      options={yearOpts}
                      inputId="cmb_salary_processing_year" // Provide the ID for the input box
                      value={yearOpts.find(option => option.value == cmb_salary_processing_year)}
                      onChange={(selectedOpt) => {
                        setSalaryProcessingYear(selectedOpt.value);
                        salaryProYearComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a year..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_processing_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* Second Column Ends*/}

              {/* First Third Column Starts*/}
              <div className="col-sm-3 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={employeeTypesComboRef}
                      options={employeeTypesOpts}
                      inputId="cmb_employee_type" // Provide the ID for the input box
                      value={employeeTypesOpts.find(option => option.value == cmb_employee_type)}
                      onChange={(selectedOpt) => {
                        setEmployeeType(selectedOpt.value);
                        employeeTypesComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a employee type..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>


              </div>
              {/* First Third Column Ends*/}

              {/* First Fourth Column Starts*/}
              <div className="col-sm-3 erp_form_col_div"></div>
              {/* First Fourth Column Ends*/}

            </div>

            <div className="row justify-content-center mt-2">
              <div className="col text-center">
                <MDButton type="button" id="save-btn" className="erp-gb-button" onClick={(e) => { FnStartSalaryProcessing(); }} variant="button" fontWeight="regular">Process</MDButton>
              </div>
            </div>

            <hr />
            {
              salaryProcessingDtlTblData.length === 0
                ? <>
                  {/* <Card id="NoRcrdId" >
                    <Card.Body>No records found...</Card.Body>
                  </Card> */}
                </>
                : <>
                  {/* <div className={`table-responsive ${salaryProcessingDtlTblData.length > 5 ? 'erp_table_scroll' : ''}`} style={{ height: '450px' }}>
                    {FnRenderSalaryProcessingTblData}
                  </div> */}

                  {/* For Freeze columns. */}
                  <Datatable data={salaryProcessingDtlTblData} columns={salaryProcessingDtlTblCols} freezeColumnCount={2} />

                </>
            }

            <div className="row text-center mt-3">
              <div className=''>
                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                  className={`erp-gb-button float-center`}
                  onClick={(e) => { goBack(); }}  > Back </MDButton>

                <MDButton type="button" id="save-btn" variant="button" fontWeight="regular"
                  className={`erp-gb-button float-center ms-1  ${salaryProcessingDtlTblData.length > 0 ? 'display' : 'd-none'}`}
                  onClick={(e) => { FnSaveSalaryProcessingData(); }}  > Save </MDButton>

              </div>
            </div>

          </div>
          {/* Card's Body Ends*/}


        </div>
        {/* Card Ends*/}

        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => FnCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      </DashboardLayout>
    </>
  );
}

export default TSalaryProcessingNew;
