import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import ReactPaginate from "react-paginate";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import { Button, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {useNavigate } from "react-router-dom";

// Import React icons
import { RxCrossCircled } from "react-icons/rx";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { CircularProgress } from "@material-ui/core";


// File Import
import ComboBox from "Features/ComboBox";
import Document from "Features/Document";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"


//Export Related imports
import ExcelExport from "Features/Exports/ExcelExport";
import PdfExport from 'Features/Exports/PdfExport';
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import { FiDownload } from "react-icons/fi";
import FrmProductUnitEntry from "Masters/MProductUnit/FrmProductUnitEntry";
import Datatable from "components/DataTable";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import ProductList from "FrmGeneric/ProductList";



const FrmMBillOfMaterialEntry = ({ goBack, values }) => {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME } = configConstants;

    const { productRmId, productRmName, keyForViewUpdate, ptShortName } = values
    const [productBomNoTodel, setProductBomNoTodel] = useState()

    //  Form Fields
    const [customeOrderNoList, setCustomerOrderNos] = useState([])
    const [product_rm_bom_id, setProductBomId] = useState(0)
    const [txt_product_rm_bom_name, setProductRmBomName] = useState('')
    const [cmb_customer_id, setPCustomerId] = useState('0');
    const [cmb_customer_order_id, setPCustomerOrderId] = useState('0');
    const [txt_product_rm_drawing_no, setPDrawingNo] = useState('');
    const [txt_product_rm_tech_spect, setPTechnicalSpecification] = useState('');
    const [txt_product_parent_rm_quantity, setPParentRmQuantity] = useState('1');
    const [txt_product_parent_rm_weight, setPParentWeight] = useState('1');
    const [cmb_product_parent_rm_unit_id, setPParentUnitId] = useState('6');
    const [cmb_product_rm_bom_status, setPBomStatus] = useState('');
    const [txt_product_rm_bom_no, setProductRmBomNo] = useState('');
    const [txt_remark, setPRemark] = useState('');
    const [txt_product_rm_bom_version, setProductRmBomVersion] = useState(1);
    const [cmb_raw_material, setRawMaterial] = useState('');

    // Option Box
    const [customerOptions, setCustomerOptions] = useState([])
    const [productUnitOptions, setProductUnitOptions] = useState([])
    const [rawMaterialOptions, setRawMaterialOptions] = useState([])

    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => setShowDocumentForm(false);
    const viewDocumentForm = () => setShowDocumentForm(true);
    const docGroup = "Bill Of Material"
    let group_id = txt_product_rm_bom_no.replace(/\//g, "_");


    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // useref togenerate bom no
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const navigate = useNavigate();


    const reportName = 'BOM Registers'
    // Bom Filter 
    const [bomfilteredData, setBomfilteredData] = useState([])
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    let requiredCols = ['company_name', 'product_name', 'product_tech_spect', 'product_drawing_no',
        'product_rm_quantity', 'product_rm_weight', 'product_std_weight', 'product_unit_name', 'product_category1_name',
        'product_category2_name', 'product_category3_name', 'product_category4_name', 'product_category5_name',
        'product_material_type_name', 'product_material_grade_name', 'product_make_name', 'product_unit_id',
        'product_id'];

    const viewBomFilterForm = async () => {
        localStorage.setItem('requiredCols', JSON.stringify(requiredCols));
        setShowBomFilterForm(true);
    }


    const handleCloseBomFilterForm = () => {
        debugger;
        setShowBomFilterForm(false)
        const getData = JSON.parse(localStorage.getItem('filteredMaterialData'))
        const comboFilter = JSON.parse(sessionStorage.getItem(`comboFilterData`))
        if (comboFilter !== '' && comboFilter !== null) { setRawMaterialOptions(comboFilter) }
        setBomfilteredData(getData !== null && getData.length !== 0 ? getData : [])
        if (getData !== '' && getData !== null && getData.length !== 0) { $('.showData').show() } else { $('.showData').hide() }

        sessionStorage.setItem('isComboFilterExist', false)
        // Remove Bom Filter Session
        var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`))
        console.log('LocalArr: ', LocalArr)
        for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
            localStorage.removeItem(LocalArr[localArrIndex])
        }
        removeSessions()
        setTimeout(() => {
            categoryDisplay();
        }, 300);

    };
    // Get Checked data
    const [importedData, setImportedData] = useState([]);

    // use refs
    const comboBox = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const pdfExp = useRef();
    const jsonExp = useRef();
    const csvExp = useRef();

    // Pagination Variables 
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
    ]
    var [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[0].value);
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);

    // Popup Fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);



    // Table Data
    const [data, setRmBomData] = useState([]);
    const [columns, setColumns] = useState([]);

    // Loader
    const [isLoading, setIsLoading] = useState(false);

    useEffect(async () => {
        try {
            setIsLoading(true);
            localStorage.removeItem('filteredMaterialData');
            await removeSessions();
            await FnFillCombos();
            await FnShowAllBomMstRecords();
            await crBomCode();
            setIsLoading(false);
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')
        }
    }, [])

    const removeSessions = async () => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const crBomCode = async () => {
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo(
            "sm_product_rm_bom_mst",
            "product_rm_bom_no",
            ptShortName,
            "RM",
            "6"
        );

        setProductRmBomNo(autoNoApiCall);
        // Set Raw Material 
        setRawMaterial(productRmId)
        comboOnChange('rawMaterial')
        return autoNoApiCall;
    }



    const FnFillCombos = async () => {
        try {
            const customeIdList = await comboBox.current.fillMasterData("cmv_customer", "", "")
            const productUnitList = await comboBox.current.fillMasterData("smv_product_unit", "", "")
            // const rmList = await comboBox.current.fillMasterData("smv_product_rm", "bom_applicable", "yes")

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "smv_product_rm"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "bom_applicable", operator: "=", value: 'yes' });
            const rmList = await comboBox.current.fillFiltersCombo(globalQuery)

            setProductUnitOptions(productUnitList)
            setCustomerOptions(customeIdList)
            setRawMaterialOptions(rmList)

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }

    const FnShowAllBomRecrdToExport = async () => {
        try {
            const bomMstRecordsApiCallToExport = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductRmBomMst/FnShowAllActiveRecordsToExport/${productRmId}`)
            const bomMstRecordResponce = await bomMstRecordsApiCallToExport.json();
            return bomMstRecordResponce;
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

    const FnShowAllBomMstRecords = async () => {
        try {
            const bomMstRecordsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductRmBomMst/FnShowParticularRecords/${productRmId}/?page=0&size=${entriesPerPage}`)
            const bomMstRecordResponce = await bomMstRecordsApiCall.json();
            console.log("bomMstRecordResponce: ", bomMstRecordResponce)
            if (bomMstRecordResponce.content.length > 0) {
                $('#header-listing').show();
                $("#filter_display_controls").show();
                $("#display_exports").show();


                const total = bomMstRecordResponce.totalElements;
                setpageCount(Math.ceil(total / entriesPerPage));

                var bomMstColumn = [];
                var bomMstColumnHeads = [];
                for (let keys in bomMstRecordResponce.content[0]) { bomMstColumnHeads.push(keys) };
                console.log("column heads: ", bomMstColumnHeads)
                for (let colKey = 0; colKey < bomMstColumnHeads.length; colKey++) {
                    if (colKey === 0) {
                        bomMstColumn.push({
                            Headers: "Action",
                            accessor: "Action",
                            Cell: row => (
                                <div style={{ display: "flex" }}>
                                    {
                                        keyForViewUpdate === 'view' ? null :
                                            <>
                                                <MdModeEdit className="erp-edit-btn" onClick={e => viewUpdateDelete(row.original, 'update')} />
                                                <MdDelete className="erp-delete-btn" onClick={e => viewUpdateDelete(row.original, 'delete')} />

                                            </>
                                    }
                                    <AiFillEye className="erp-view-btn" onClick={e => viewUpdateDelete(row.original, 'view')} />
                                </div>
                            ),
                        });
                    }
                    if (!bomMstColumnHeads[colKey].includes('_id') && !bomMstColumnHeads[colKey].includes('is_') && !bomMstColumnHeads[colKey].includes('_on') && !bomMstColumnHeads[colKey].includes('_by') && !bomMstColumnHeads[colKey].includes('field_name') && !bomMstColumnHeads[colKey].includes('company_')) {
                        bomMstColumn.push({ Headers: bomMstColumnHeads[colKey], accessor: bomMstColumnHeads[colKey] });
                    }
                }
                setColumns(bomMstColumn)
                setRmBomData(bomMstRecordResponce.content)
                return bomMstRecordResponce.content;

            } else {
                $('#header-listing').hide();
                $("#filter_display_controls").hide();
                $("#display_exports").hide();

                return data;
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        
        }
    }

    const fetchComments = async (currentPage) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductRmBomMst/FnShowParticularRecords/${productRmId}?page=${currentPage}&size=${entriesPerPage}`);
            const data = await res.json();
            if (data.content.length === 0) {
                $('#header-listing').hide();
                $("#filter_display_controls").hide();
                $("#display_exports").hide();

            }
            setRmBomData(data.content);
            return data.content;
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    };

    const handlePageClick = async (pageNo) => {
        console.log(pageNo.selected)
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        const commentsFormServer = await fetchComments(currentPage, entriesPerPage);
        console.log("commentsFormServer: ", commentsFormServer)

    }

    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        setcurrentPage(0)
        await FnShowAllBomMstRecords();
    }

    const viewUpdateDelete = async (data, key) => {
        debugger
        let product_rm_bom_id = data.product_rm_bom_id
        setProductBomNoTodel(data.product_rm_bom_no)
        setProductBomId(product_rm_bom_id)
        switch (key) {
            case 'update': infoForUpdate(product_rm_bom_id, 'update'); break;
            case 'delete': setShow(true); break;
            case 'view': infoForUpdate(product_rm_bom_id, 'view'); break;
        }

    }
    const [bomTblKeyForViewOrUpdate, setBomTblKeyForViewOrUpdate] = useState('')
    const infoForUpdate = async (product_rm_bom_id, keyForViewUpdate) => {
        try {
            setBomTblKeyForViewOrUpdate(keyForViewUpdate)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductRmBomMst/FnShowParticularRecordForUpdate/${product_rm_bom_id}`)
            const updateRes = await apiCall.json();
            setRawMaterial(updateRes.content[0].product_parent_rm_id)
            await comboOnChange('rawMaterial')
            setPTechnicalSpecification(updateRes.content[0].product_parent_rm_tech_spect)
            setPParentUnitId(updateRes.content[0].product_parent_rm_unit_id)
            setPCustomerId(updateRes.content[0].customer_id)
            if (updateRes.content[0].customer_id !== '') {
                await comboOnChange('customer')
            }

            setProductRmBomVersion(updateRes.content[0].product_rm_bom_version)
            setProductRmBomNo(updateRes.content[0].product_rm_bom_no)
            setPCustomerOrderId(updateRes.content[0].customer_order_id)
            setPDrawingNo(updateRes.content[0].product_parent_rm_drawing_no)
            setPParentRmQuantity(updateRes.content[0].product_parent_rm_quantity)
            setPParentWeight(updateRes.content[0].product_parent_rm_weight)
            setPBomStatus(updateRes.content[0].product_rm_bom_status)
            setPRemark(updateRes.content[0].remark)
            setPTechnicalSpecification(updateRes.content[0].product_parent_rm_tech_spect)
            switch (data.is_active) {
                case true:
                    document.querySelector('input[name="isBomActive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isBomActive"][value="0"]').checked = true;
                    break;
            }
            let detailsData = updateRes.content
            detailsData = detailsData.map(item => {
                // Create a new object with the updated key name
                const newItem = {
                    ...item,

                    product_id: item.product_child_rm_id,
                    product_name: item.product_child_rm_name,
                    product_rm_quantity: item.product_child_rm_quantity,
                    product_drawing_no: item.product_child_rm_drawing_no,
                    product_tech_spect: item.product_child_rm_tech_spect,
                    product_rm_weight: item.product_child_rm_weight,
                    product_std_weight: item.product_std_weight,
                    product_unit_name: item.product_child_rm_stock_unit_name,
                    product_material_type_name: item.product_child_type_name,
                    product_category1_name: item.product_child_category1_name,
                    product_category2_name: item.product_child_category2_name,
                    product_category3_name: item.product_child_category3_name,
                    product_category4_name: item.product_child_category4_name,
                    product_category5_name: item.product_child_category5_name,
                    product_make_name: item.product_child_make_name,
                    product_material_grade_name: item.product_child_material_grade_name,
                    product_unit_id: item.product_child_rm_unit_id
                };
                return newItem;
            })
            setBomfilteredData(detailsData)
            categoryDisplay();
            localStorage.setItem('filteredMaterialData', JSON.stringify(detailsData))
            $('.showData').show()
            switch (keyForViewUpdate) {
                case 'update':
                    $("input[type=radio]").attr('disabled', false);
                    $("#import-btn").attr('disabled', true);
                    await validate.current.removeReadOnlyAttr("billOfMaterialEntryFormId");
                    $('#txt_product_rm_bom_no').attr('readonly', true);
                    $('#cmb_raw_material').attr('readonly', true);
                    break;
                case 'view':
                    $("input[type=radio]").attr('disabled', true);
                    $("#import-btn").attr('disabled', true);
                    await validate.current.readOnly("billOfMaterialEntryFormId");
                    break;
            }


        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const importedExcelFile = async (event) => {
        try {
            if (event.target.files) {
                var file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductRmBomMst/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();
                if (fetchRes.success === '1') {
                    if (fetchRes.data.length !== 0) {
                        $('.showData').show()

                        for (let index = 0; index < fetchRes.data.length; index++) {
                            var crJsonData = new Map();
                            for (let secIndex = 0; secIndex < fetchRes.columns.length; secIndex++) {
                                crJsonData.set(fetchRes.columns[secIndex], fetchRes.data[index][secIndex])
                            }
                            var json = {};
                            crJsonData.forEach(function (value, key) {
                                json[key] = value;
                            });
                            importedData.push(json)
                        }

                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                var comboVal;
                                if (fieldVal !== "" && fieldName === 'Unit' || fieldName === 'BOM Status' || fieldName === 'Customer' || fieldName === 'Raw Material') {
                                    const splitVal = fieldVal.split('(')
                                    comboVal = splitVal[1].replace(')', '')
                                }
                                switch (fieldName) {
                                    case 'Drawing No.':
                                        setPDrawingNo(fieldVal)
                                        break;
                                    case 'BOM Name':
                                        setProductRmBomName(fieldVal)
                                        break;
                                    case 'Remark':
                                        setPRemark(fieldVal)
                                        break;
                                    case 'Technical Specification':
                                        setPTechnicalSpecification(fieldVal)
                                        break;
                                    case 'Quantity':
                                        setPParentRmQuantity(fieldVal)
                                        break;
                                    case 'Unit':
                                        setPParentUnitId(comboVal)
                                        break;
                                    case 'Weight':
                                        setPParentWeight(fieldVal)
                                        break;
                                    case 'BOM Code':
                                        setProductRmBomNo(field[1] + ':' + field[3] + ':' + field[3])
                                        break;
                                    case 'BOM Status':
                                        setPBomStatus(comboVal)
                                        break;
                                    case 'Customer':
                                        setPCustomerId(comboVal)
                                        break;
                                    case 'Raw Material':
                                        setRawMaterial(comboVal)
                                        comboOnChange('rawMaterial')
                                        break;
                                    default:
                                        break;
                                }

                            }
                        }

                        sessionStorage.setItem('bomfilteredData', JSON.stringify(importedData))
                        setBomfilteredData(importedData)
                        categoryDisplay();
                        console.log("fetchRes: ", fetchRes)
                        return fetchRes;

                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setShowErrorMsgModal(true)
                }
            } else {
                $('.showData').hide()
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const deleteRecord = async () => {
        debugger
        try {
            const method = { method: 'POST' }
            const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductRmBomMst/FnDeleteRecord/${UserName}/${COMPANY_ID}?product_rm_bom_no=${productBomNoTodel}`, method)
            const fetchRes = await apicall.json();
            if (fetchRes.error !== "") {
                setErrMsg(fetchRes.error)
                setShow(false)
                setShowErrorMsgModal(true)
            } else {
                await FnShowAllBomMstRecords();
                setShow(false)
            }

        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')
        }
    }

    const comboOnChange = async (key) => {
        try {

            switch (key) {
                case 'customer':
                    let customerValue = parseInt($('#cmb_customer_id').val());
                    setPCustomerId(customerValue)
                    if (customerValue !== 0 && !isNaN(customerValue)) {
                        resetGlobalQuery();
                        globalQuery.columns = ["field_name"]
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerValue });
                        globalQuery.table = "mtv_sales_order_master_trading_list"
                        const getCustomeOrderNoList = await comboBox.current.fillFiltersCombo(globalQuery)
                        setCustomerOrderNos(getCustomeOrderNoList)
                    }
                    break;
                case 'customerOrderId':
                    var customerOrderValue = $('#cmb_customer_order_id').val();
                    setPCustomerOrderId(customerOrderValue)
                    break;
                case 'productUnitId':
                    var unitValue = $('#cmb_product_parent_rm_unit_id').val();
                    setPParentUnitId(unitValue)

                    if (unitValue === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Units')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css("padding-top", "0px");
                        }, 100)
                    }
                    break;
                case 'bomStatus':
                    var bomStatusValue = $('#cmb_product_rm_bom_status').val();
                    setPBomStatus(bomStatusValue)
                    break;
                case 'rawMaterial':
                    var rmValue = $('#cmb_raw_material').val();
                    setRawMaterial(rmValue)
                    if (rmValue === "Filter") {
                        setShowBomFilterForm(true)
                        sessionStorage.setItem('isComboFilterExist', true)
                    } else if (rmValue === '') {
                        sessionStorage.setItem('isComboFilterExist', false)
                    } else {
                        sessionStorage.setItem('isComboFilterExist', false)

                        // For Technical Specifications
                        resetGlobalQuery();
                        globalQuery.columns.push("product_rm_tech_spect");
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "product_rm_id", operator: "=", value: rmValue });
                        globalQuery.table = "smv_product_rm_summary"
                        var productTechSpect = await comboBox.current.fillFiltersCombo(globalQuery)


                        // For Units
                        resetGlobalQuery();
                        globalQuery.columns.push("product_rm_stock_unit_id");
                        globalQuery.columns.push("product_rm_stock_unit_name");
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "product_rm_id", operator: "=", value: rmValue });
                        globalQuery.table = "smv_product_rm_summary"
                        var productUnits = await comboBox.current.fillFiltersCombo(globalQuery)

                        if (productTechSpect.length !== 0) {
                            setPTechnicalSpecification(productTechSpect[0].product_rm_tech_spect)
                        }
                        if (productUnits.length !== 0) {
                            setPParentUnitId(productUnits[0].product_rm_stock_unit_id)
                        }
                    }
                    break;



            }

        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')
        }
    }

    const addBOM = async () => {
        try {

            let latestBomNo = txt_product_rm_bom_no;
            let checkIsValidate = await validate.current.validateForm("billOfMaterialEntryFormId");
            if (checkIsValidate) {
                setIsLoading(true)
                if (bomfilteredData.length !== 0) {
                    var jsonObj = { 'TransHeaderData': {}, 'TransDetaildata': [] }
                    var active;
                    var activeValue = document.querySelector('input[name=isBomActive]:checked').value
                    switch (activeValue) {
                        case '0': active = false; break;
                        case '1': active = true; break;
                    }

                    if (product_rm_bom_id === 0) {
                        latestBomNo = await crBomCode()
                    }

                    const data = {
                        product_rm_bom_id: product_rm_bom_id,
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        product_parent_rm_id: productRmId,
                        product_rm_bom_no: latestBomNo,
                        product_rm_bom_version: txt_product_rm_bom_version,
                        product_rm_bom_name: txt_product_rm_bom_name,
                        customer_id: cmb_customer_id,
                        customer_order_id: cmb_customer_order_id,
                        product_parent_rm_drawing_no: txt_product_rm_drawing_no,
                        product_parent_rm_tech_spect: txt_product_rm_tech_spect,
                        product_parent_rm_quantity: txt_product_parent_rm_quantity,
                        product_parent_rm_weight: txt_product_parent_rm_weight,
                        product_parent_rm_unit_id: cmb_product_parent_rm_unit_id,
                        product_rm_bom_status: cmb_product_rm_bom_status,
                        remark: txt_remark,
                        is_active: active,
                        created_by: UserName,
                        modified_by: product_rm_bom_id !== 0 ? UserName : null

                    };

                    for (let bomDetailIndex = 0; bomDetailIndex < bomfilteredData.length; bomDetailIndex++) {
                        var crJson = {}
                        const bomfilteredDataElement = bomfilteredData[bomDetailIndex];
                        crJson['company_id'] = COMPANY_ID
                        crJson['company_branch_id'] = COMPANY_BRANCH_ID
                        crJson['product_rm_bom_no'] = latestBomNo
                        crJson['product_child_rm_id'] = bomfilteredDataElement.product_id
                        crJson['product_child_rm_quantity'] = bomfilteredDataElement.product_rm_quantity
                        crJson['product_child_rm_drawing_no'] = bomfilteredDataElement.product_drawing_no
                        crJson['product_child_rm_tech_spect'] = bomfilteredDataElement.product_tech_spect
                        crJson['product_child_rm_weight'] = bomfilteredDataElement.product_rm_weight
                        crJson['product_child_rm_unit_id'] = bomfilteredDataElement.product_unit_id
                        crJson['created_by'] = UserName
                        crJson['modified_by'] = product_rm_bom_id !== 0 ? UserName : null
                        jsonObj['TransDetaildata'].push(crJson)
                    }
                    jsonObj['TransHeaderData'] = data
                    let formData = new FormData();
                    formData.append(`bomDetailData`, JSON.stringify(jsonObj))
                    const forwardModelData = {
                        method: 'POST',
                        body: formData
                    };

                    const productBOMApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductRmBomMst/FnAddUpdateRecord`, forwardModelData)
                    const responce = await productBOMApiCall.json()
                    console.log("response error: ", responce);
                    if (responce.success !== "1") {
                        setErrMsg(responce.error)
                        setShowErrorMsgModal(true)

                    } else {
                        // const resData = responce.data;

                        setSuccMsg(responce.message)
                        setShowSuccessMsgModal(true);
                        await clearFormFields();
                    }

                } else {
                    setErrMsg('BOM items not found!...')
                    setShowErrorMsgModal(true)
                }
                setIsLoading(false)
            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

    }

    const onImportClick = () => {
        importFile.current.click();
    };

    const ExportToExcel = async () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        const isValidated = await validate.current.validateForm("billOfMaterialEntryFormId");
        var counter = 1;
        if (isValidated === true) {
            if (bomfilteredData.length !== 0) {
                var rawMaterial = $("#cmb_raw_material option:selected").text();

                if (rawMaterial === 'Select') {
                    rawMaterial = ''
                }
                var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [4] }

                const customerIdTxt = ($("#cmb_customer_id option:selected").text() === 'Select') ? '' : $("#cmb_customer_id option:selected").text();
                const customerOrderNoTxt = ($("#cmb_customer_order_id option:selected").text() === 'Select') ? '' : $("#cmb_customer_order_id option:selected").text();
                const unitTxt = ($("#cmb_product_parent_rm_unit_id option:selected").text() === 'Select') ? '' : $("#cmb_product_parent_rm_unit_id option:selected").text();

                jsonToExportExcel['filtrKeyValue']['0'] = "Raw Material" + ' : ' + $("#cmb_raw_material option:selected").text() + "(" + cmb_raw_material + ")"
                jsonToExportExcel['filtrKeyValue']['1'] = "BOM Code" + ' : ' + txt_product_rm_bom_no
                jsonToExportExcel['filtrKeyValue']['2'] = "Customer" + ' : ' + customerIdTxt + "(" + cmb_customer_id + ")"
                jsonToExportExcel['filtrKeyValue']['3'] = "Customer Order No." + ' : ' + customerOrderNoTxt + "(" + cmb_customer_order_id + ")"
                jsonToExportExcel['filtrKeyValue']['4'] = "Remark" + ' : ' + txt_remark
                jsonToExportExcel['filtrKeyValue']['5'] = "Drawing No." + ' : ' + txt_product_rm_drawing_no
                jsonToExportExcel['filtrKeyValue']['6'] = "Technical Specification" + ' : ' + txt_product_rm_tech_spect
                jsonToExportExcel['filtrKeyValue']['7'] = "Quantity" + ' : ' + txt_product_parent_rm_quantity
                jsonToExportExcel['filtrKeyValue']['8'] = "Weight" + ' : ' + txt_product_parent_rm_weight
                jsonToExportExcel['filtrKeyValue']['9'] = "Unit" + ' : ' + unitTxt + "(" + cmb_product_parent_rm_unit_id + ")"

                // Check categories
                for (let checkCatcount = 0; checkCatcount < requiredCols.length; checkCatcount++) {
                    if (requiredCols[checkCatcount] === 'product_child_category' + counter + '_name') {
                        if (counter > parseInt(catCount)) {
                            requiredCols.splice(checkCatcount, 1);
                            counter++;
                        } else {
                            counter++;
                        }
                    }
                }

                // columns
                for (let col = 0; col < requiredCols.length; col++) {
                    jsonToExportExcel.columns.push({ "Headers": requiredCols[col], "accessor": requiredCols[col] })
                }

                // data
                for (let arrKey = 0; arrKey < bomfilteredData.length; arrKey++) {
                    jsonToExportExcel['allData'][arrKey] = bomfilteredData[arrKey];
                }

                jsonToExportExcel['headings']['ReportName'] = "Bill Of Material"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')

                console.log("jsonToExportExcel: ", jsonToExportExcel)
                exlsExp.current.excel(jsonToExportExcel, txt_product_rm_bom_no)
            } else {
                setErrMsg('Please add at least one material!... ')
                setShowErrorMsgModal(true)
            }
        }
    }

    const deleteFooterRecrd = (product_child_rm_id) => {
        let detailsData = [...bomfilteredData]
        detailsData = detailsData.filter((item) => item.product_id !== product_child_rm_id)
        setBomfilteredData(detailsData)
        sessionStorage.setItem('bomfilteredData', JSON.stringify(detailsData))

    }

    const updateBomDetailsTblRow = async (rowData, event) => {
        let enteredValue = event.target.value
        const detailData = [...bomfilteredData]
        let clickedColName = event.target.getAttribute('Headers');
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        let qty = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue), 4);

        rowData[clickedColName] = qty
        rowData['product_rm_weight'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(qty * rowData.product_std_weight), 4);
        detailData[arrayIndex] = rowData
        setBomfilteredData(detailData);
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('billOfMaterialEntryFormId')
    }

    const clearFormFields = async () => {
        const content = await FnShowAllBomMstRecords();
        setProductBomId(0)
        crBomCode(content);
        setBomfilteredData([])
        sessionStorage.removeItem('bomfilteredData')
        $('.showData').hide()
        setPCustomerId('0')
        setPCustomerOrderId('0')
        setPDrawingNo('')
        setPParentRmQuantity('')
        setPParentWeight('')
        setPRemark('')
    }


    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('erp-bomDetails-table');
        var rows = table.getElementsByTagName('tr');

        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#erp-bomDetails-table').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('erp-bomDetails-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }


    const exporttoPdf = async () => {
        debugger
        const dataExport = await FnShowAllBomRecrdToExport();
        if (dataExport.length !== 0) {
            var columnExport = [];
            for (let keys in dataExport[0]) { columnExport.push({ Headers: keys, accessor: keys }) };
            pdfExp.current.pdf(dataExport, columnExport, reportName + "(" + dataExport[0].company_name + ")", "")
        }
    }


    const exporttoExcel = async () => {
        debugger
        const dataExport = await FnShowAllBomRecrdToExport();
        var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'reportExport' }
        if (dataExport.length !== 0) {
            var columnExport = [];
            for (let keys in dataExport[0]) { columnExport.push(keys) };
            for (let col = 0; col < columnExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": columnExport[col], "accessor": columnExport[col] })
            }
            for (let arrKey = 0; arrKey < dataExport.length; arrKey++) {
                jsonToExportExcel['allData'][arrKey] = dataExport[arrKey];
            }
            jsonToExportExcel['headings']['ReportName'] = reportName
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, reportName + "(" + dataExport[0].company_name + ")")
        }
    }
    const exporttoJSON = async () => { 
        const dataExport = await FnShowAllBomRecrdToExport();
        if (dataExport.length !== 0) {
            jsonExp.current.json(dataExport, reportName + "(" + dataExport[0].company_name + ")")
        }
    }

    

    const exportToCSV = async () => {
        var columnExport = [];
        const dataExport = await FnShowAllBomRecrdToExport();
        for (let keys in dataExport[0]) { columnExport.push({ "Headers": columnExport[keys], "accessor": columnExport[keys] }) };
        if (dataExport.length !== 0) {
            let data = dataExport.map(element => {
                return columnExport.reduce((dataJson, col) => {
                    dataJson[col.accessor] = element[col.accessor];
                    return dataJson;
                }, {});
            });
            csvExp.current.csv(data, columnExport, reportName + "(" + dataExport[0].company_name + ")")
        }
    }


    // Show ADd record Modal
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {

            case 'Units':
                const productUnitList = await comboBox.current.fillMasterData("smv_product_unit", "", "")
                setProductUnitOptions(productUnitList)
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Units':
                return <FrmProductUnitEntry closeAddModal={handleCloseRecModal} btn_disabled={true} />

            default:
                return null;
        }
    }





    return (
        <>
            <ComboBox ref={comboBox} />
            <ExcelExport ref={exlsExp} />
            <PdfExport ref={pdfExp} />
            <JsonExport ref={jsonExp} />
            <CSVExport ref={csvExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div> :
                null}

            <div className='main_heding'>
                <label className='erp-form-label-lg main_heding'> {productRmName} </label>
            </div>

            <div className="row btn_row_class">
                <div className="col-6 add_btn" id="filter_display_controls" style={{ display: 'none' }}>
                    <span>
                        <span className="page_entries" >
                            <MDTypography component="label" variant="button" className="erp-form-label-md">Entries per page: &nbsp;</MDTypography>

                            <select onChange={handlePageCountClick} className="erp_page_select erp_form_control" id="page_entries_id" >
                                {pageEntriesOptions.map(pageEntriesOptions => (
                                    <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                                ))}
                            </select>
                        </span>
                    </span>
                </div>
                <div className="col-6 pagination_id" id="display_exports" style={{ display: 'none' }}>
                    <span className="exports">
                        <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoPdf()}>PDF<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                        <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoExcel()}>EXCEL<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                        <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exportToCSV()}>CSV<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                        <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoJSON()}>JSON<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                    </span>
                </div>
            </div>

            <div id="header-listing">
                {
                    data.length !== 0 ? <>
                        <Datatable data={data} columns={columns} />
                        <ReactPaginate
                            className='erp_pagination'
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link erp-gb-button"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link erp-gb-button"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link erp-gb-button"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"} />
                    </> : <Card id="NoRcrdId" style={{ display: "none" }}>
                        <Card.Body>No records found...</Card.Body>
                    </Card>
                }

            </div>
            <form id="billOfMaterialEntryFormId">
                <div className='row'>
                    <div className='col-sm-6 erp_form_col_div'>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">BOM Code & Version : <span className="required">*</span> </Form.Label>
                            </div>
                            <div className='col'>
                                <span className='erp_phone'>
                                    <Form.Control type="text" id="txt_product_rm_bom_no" className="erp_input_field erp_right_border" value={txt_product_rm_bom_no} onChange={e => { setProductRmBomNo(e.target.value); validateFields(); }} maxLength="20" readOnly={true} />
                                    <Form.Control type="text" style={{ width: "30%" }} id="txt_product_rm_bom_version" className="erp_input_field  erp_align-right erp_phn_border" value={txt_product_rm_bom_version} maxLength="20" optional='optional' readOnly={true} />
                                </span>
                                <MDTypography variant="button" id="error_txt_product_rm_bom_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>




                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Parrent Raw Material : <span className="required">*</span> </Form.Label>
                            </div>
                            <div className='col'>
                                <select id="cmb_raw_material" className="form-select form-select-sm" value={cmb_raw_material} onChange={() => comboOnChange('rawMaterial')} disabled>
                                    <option value="">Select</option>
                                    <option value="Filter">Filter +</option>
                                    {rawMaterialOptions?.map((rmOption) => (
                                        <option value={rmOption.field_id}>{rmOption.field_name}</option>

                                    ))}

                                </select>
                                <MDTypography variant="button" id="error_cmb_raw_material" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Technical Specification : </Form.Label>
                            </div>
                            <div className='col'>
                                <Form.Control type="text" id="txt_product_rm_tech_spect" className="erp_input_field" value={txt_product_rm_tech_spect} onChange={e => setPTechnicalSpecification(e.target.value)} maxLength="500" optional='optional' />
                                <MDTypography variant="button" id="error_txt_product_rm_tech_spect" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>

                            </div>
                        </div>



                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Drawing No. : </Form.Label>
                            </div>
                            <div className='col'>
                                <Form.Control type="text" id="txt_product_rm_drawing_no" className="erp_input_field" value={txt_product_rm_drawing_no} onChange={e => setPDrawingNo(e.target.value)} maxLength="500" optional='optional' />
                                <MDTypography variant="button" id="error_txt_product_rm_drawing_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Quantity : <span className="required">*</span> </Form.Label>
                            </div>
                            <div className='col'>
                                <Form.Control type="text" id="txt_product_parent_rm_quantity" className="erp_input_field  erp_align-right" value={txt_product_parent_rm_quantity} onChange={(e) => {
                                    if (validateNumberDateInput.current.decimalNumber(e.target.value, 4)) {
                                        setPParentRmQuantity(e.target.value)
                                    }
                                }} maxLength="19" />
                                <MDTypography variant="button" id="error_txt_product_parent_rm_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Weight : <span className="required">*</span> </Form.Label>
                            </div>
                            <div className='col'>
                                <Form.Control type="text" id="txt_product_parent_rm_weight" className="erp_input_field  erp_align-right" value={txt_product_parent_rm_weight} onChange={(e) => {
                                    if (validateNumberDateInput.current.decimalNumber(e.target.value, 4)) {
                                        setPParentWeight(e.target.value)
                                    }
                                }} maxLength="18" />
                                <MDTypography variant="button" id="error_txt_product_parent_rm_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Units : <span className="required">*</span> </Form.Label>
                            </div>
                            <div className='col'>
                                <select id="cmb_product_parent_rm_unit_id" className="form-select form-select-sm" value={cmb_product_parent_rm_unit_id} onChange={() => comboOnChange('productUnitId')}>
                                    <option value="">Select</option>
                                    <option value="0">Add New Record+</option>
                                    {productUnitOptions?.map((units) => (
                                        <option value={units.field_id}>{units.field_name}</option>

                                    ))}

                                </select>
                                <MDTypography variant="button" id="error_cmb_product_parent_rm_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-6 erp_form_col_div'>

                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Customer : </Form.Label>
                            </div>
                            <div className='col'>
                                <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={() => comboOnChange('customer')} optional='optional'>
                                    <option value="">Select</option>
                                    {customerOptions?.map((customer) => (
                                        <option value={customer.field_id}>{customer.field_name}</option>

                                    ))}

                                </select>
                                <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label">Customer Order No : </Form.Label>
                            </div>
                            <div className='col'>
                                <select id="cmb_customer_order_id" className="form-select form-select-sm" value={cmb_customer_order_id} onChange={() => comboOnChange('customerOrderId')} optional='optional'>
                                    <option value="">Select</option>
                                    {customeOrderNoList?.map((customer) => (
                                        <option value={customer.field_name}>{customer.field_name}</option>

                                    ))}
                                </select>
                                <MDTypography variant="button" id="error_cmb_customer_order_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-sm-4'>
                                <Form.Label className="erp-form-label"> Remark : </Form.Label>
                            </div>
                            <div className='col'>
                                <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_remark" value={txt_remark} onChange={e => setPRemark(e.target.value)} maxLength="255" optional='optional' />
                                <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <Form.Label className="erp-form-label">BOM Active : </Form.Label>
                            </div>
                            <div className="col">

                                <div className="erp_form_radio">
                                    <div className="fCheck">
                                        <Form.Check
                                            className="erp_radio_button"
                                            label="Yes"
                                            type="radio"
                                            value="1"
                                            name="isBomActive"
                                            defaultChecked

                                        />
                                    </div>
                                    <div className="sCheck">
                                        <Form.Check
                                            className="erp_radio_button"
                                            label="No"
                                            value="0"
                                            type="radio"
                                            name="isBomActive"

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </form>
            <hr />

            <div className="d-flex">
                <MDButton type="button" className={`erp-gb-button ${bomTblKeyForViewOrUpdate === 'view' || keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                    fontWeight="regular" onClick={viewBomFilterForm}>Add Materials</MDButton>
                <Form.Control type="text" id="txt-detail-data-highliter" style={{ display: 'none' }} className={`erp_input_field txt-filter-bom ${bomfilteredData.length !== 0 ? 'display' : 'd-none'} showdata`} />
                <MDButton type="button" className="erp-gb-button ms-2 showData" style={{ display: 'none' }} variant="button" onClick={() => scrollToTableRow()}
                    fontWeight="regular">Search</MDButton>
            </div>

            <div className="mt-2">
                <Table className={`erp_table ${bomfilteredData.length === 0 ? 'd-none' : 'display'}`} id="erp-bomDetails-table" responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className={`erp_table_td ${bomTblKeyForViewOrUpdate === 'view' ? 'd-none' : 'display'}`}>Action</th>
                            <th className="erp_table_th">Raw Material</th>
                            <th className="erp_table_th">Technical Specifications</th>
                            <th className="erp_table_th">Drawing No.</th>
                            <th className="erp_table_th">Quantity</th>
                            <th className="erp_table_th">Weight</th>
                            <th className="erp_table_th">Unit</th>
                            <th className="erp_table_th cat1Id" style={{ display: 'none' }}>Category-1</th>
                            <th className="erp_table_th cat2Id" style={{ display: 'none' }}>Category-2</th>
                            <th className="erp_table_th cat3Id" style={{ display: 'none' }}>Category-3</th>
                            <th className="erp_table_th cat4Id" style={{ display: 'none' }}>Category-4</th>
                            <th className="erp_table_th cat5Id" style={{ display: 'none' }}>Category-5</th>
                            <th className="erp_table_th">Material Type</th>
                            <th className="erp_table_th">Material Grade</th>
                            <th className="erp_table_th">Material Make</th>
                            <th className="erp_table_th">Standard Weight</th>
                            {/* <th className="erp_table_th">Product Rm Id</th>
                         <th className="erp_table_th">Unit Id</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {bomfilteredData?.map((item, index) =>
                            <tr rowindex={index} id={item.product_id}>
                                <td className={`erp_table_td ${bomTblKeyForViewOrUpdate === 'view' || keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                                    <MdDelete className="erp-delete-btn" onClick={() => deleteFooterRecrd(item.product_id)} />
                                </td>
                                <td className="erp_table_td filter">{item.product_name}</td>
                                <td className="erp_table_td">{item.product_tech_spect}</td>
                                <td className="erp_table_td">{item.product_drawing_no}</td>
                                <td className="erp_table_td text-end">
                                    {bomTblKeyForViewOrUpdate === 'view' || keyForViewUpdate === 'view' ? item.product_rm_quantity :
                                        <input type="text"
                                            id={`product_rm_quantity_${item.product_id}`}
                                            className="erp_input_field_table_txt mb-0 text-end"
                                            value={item.product_rm_quantity}
                                            onChange={(e) => { updateBomDetailsTblRow(item, e); }}
                                            Headers='product_rm_quantity'
                                            maxLength='14'
                                        />
                                    }
                                </td>

                                <td className="erp_table_td">{item.product_rm_weight}</td>
                                <td className="erp_table_td">{item.product_unit_name}</td>
                                <td className="erp_table_td cat1Id" style={{ display: 'none' }}>{item.product_category1_name}</td>
                                <td className="erp_table_td cat2Id" style={{ display: 'none' }}>{item.product_category2_name}</td>
                                <td className="erp_table_td cat3Id" style={{ display: 'none' }}>{item.product_category3_name}</td>
                                <td className="erp_table_td cat4Id" style={{ display: 'none' }}>{item.product_category4_name}</td>
                                <td className="erp_table_td cat5Id" style={{ display: 'none' }}>{item.product_category5_name}</td>
                                <td className="erp_table_td">{item.product_material_type_name}</td>
                                <td className="erp_table_td">{item.product_material_grade_name}</td>
                                <td className="erp_table_td">{item.product_make_name}</td>
                                <td className="erp_table_td">{item.product_std_weight}</td>
                                {/* <td className="erp_table_td">{item.product_rm_id}</td>
                                <td className="erp_table_td">{item.product_rm_stock_unit_id}</td> */}
                            </tr>

                        )}
                    </tbody>
                </Table>
            </div>
            <div className="erp_frm_Btns">
                <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                <MDButton type="button" className="erp-gb-button" variant="button" onClick={() => goBack(productRmId)}
                    fontWeight="regular">Back</MDButton>
                <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || bomTblKeyForViewOrUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={addBOM}
                    fontWeight="regular">Save</MDButton>
                <MDButton type="button" id="export-btn" className="erp-gb-button ms-2" variant="button" onClick={ExportToExcel}
                    fontWeight="regular">Export</MDButton>
                <MDButton type="button" id="import-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || bomTblKeyForViewOrUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={onImportClick}
                    fontWeight="regular">Import</MDButton>
                <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || bomTblKeyForViewOrUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp;

            </div>


            {/* Document modal */}
            {showDocumentForm ?
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <Document group_id={group_id} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal> : null}

            {/* ADD Filter modal */}
            {showBomFilterForm ?
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={handleCloseBomFilterForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Raw Material Filter</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseBomFilterForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductList closeModal={handleCloseBomFilterForm} props={{ selectedProduct: ['smv_product_rm_summary'] }} />
                        </React.Fragment>
                    </Modal.Body>
                </Modal> : null
            }

            {/* Delete Modal */}
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                <Modal.Body className='erp_modal_body'>
                    <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                    <h6>Are you sure?</h6>
                    <div className="erp-form-label">Do you wish to delete this record ?</div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                        Cancel
                    </Button>&nbsp;
                    <Button variant="danger" className='erp-gb-button' onClick={deleteRecord}>Delete</Button>
                </Modal.Footer>
            </Modal>


            {/* Add new Record Popup */}
            {showAddRecModal ?
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal > : null
            }


            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        </>
    )
}
export default FrmMBillOfMaterialEntry;