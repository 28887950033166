import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress, Tooltip } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";

import "react-datepicker/dist/react-datepicker.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { FaSave } from 'react-icons/fa'; // Example icon for Save
import { Toast, ToastContainer } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import { RxCheckCircled } from "react-icons/rx";



function CompLeaveRequestForm() {

    // Globally Constants.
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, COMPANY_NAME, FINANCIAL_SHORT_NAME, COMPANY_ADDRESS, USER_ACCESS } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    // ****** Hook For Navigation
    const navigate = useNavigate();
    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let salaryMonthComboRef = useRef(null);
    let salaryYearComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);

    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);
    const [txt_employee_code, setEmployeeCode] = useState();


    // Input field's Hooks;
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('0');
    const [cmb_salary_month, setSalaryMonth] = useState('');
    const [cmb_salary_year, setSalaryYear] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');

    // combo's options hooks;
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    // Hooks For table;
    const [attendanceDtlTblData, setAttendanceDtlTblData] = useState([]);
    const [attendanceDtlTblCols, setAttendanceDtlTblCols] = useState([]);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [approveRowIndex, setApproveRowIndex] = useState(null);

    const [isApprove, setIsApprove] = useState(false);
    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    const [errorRows, setErrorRows] = useState({});
    const [show, setShow] = useState(false);
    const [toastMessage, setToastMessage] = useState(''); // State for the dynamic message


    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);

    // FINANCIAL_SHORT_NAME wants like 2024
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );
    // const access_control=[];
    const [access_control, setAccessControl] = useState([]);

    // On Page Load.
    useEffect(() => {
        debugger
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            const access = await FnCheckUserAccess();
            setAccessControl(access)
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnCheckUserAccess = () => {
        debugger
        const currentRoute = window.location.pathname;
        const obj = USER_ACCESS ? USER_ACCESS.find(item => item.listing_navigation_url === currentRoute) : null;
        console.log("Particular Page user access: ", obj);
        return obj;
    }
    const validateShiftManagementDetails = () => {
        if (cmb_employee_type_id === '') {
            let errorElement = document.getElementById("error_cmb_employee_type_id");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_employee_type_id").focus();
            return false;
        }

        return true;
    }


    const FnComboOnChange = async (comboName) => {
        debugger
        try {
            switch (comboName) {
                case "Department":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);

                    let selectedEmployeeTypes = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpt = departmentComboRef.current?.department_id || 'All';

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmployeeTypes && selectedEmployeeTypes !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeTypes });
                    }
                    if (selectedDpt && selectedDpt !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpt });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name }));
                            rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });

                    setEmployeeId('');
                    // }
                    break;

                case "subDepartment":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedDepTS = departmentComboRef.current.department_id;
                    const subDepartmentLists = allDepartmentsList.filter(department =>
                        (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                    );
                    subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentLists);

                    // subDepartmentComboRef.current = null;
                    // setSubDepartmentId('');
                    let selectedEmpType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpts = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDPts = subDepartmentComboRef.current?.department_id || 'All';

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmpType && selectedEmpType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmpType });
                    }
                    if (selectedDpts && selectedDpts !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpts });
                    }
                    if (selectedSubDPts && selectedSubDPts !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDPts });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name }));
                            rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });

                    setEmployeeId('');

                    break;
                case "EmployeeType":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedEmployeeType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDepartments = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDepartment = subDepartmentComboRef.current?.department_id || 'All';
                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmployeeType && selectedEmployeeType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeType });
                    }
                    if (selectedDepartments && selectedDepartments !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDepartments });
                    }
                    if (selectedSubDepartment && selectedSubDepartment !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name }));
                            rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });

                    setEmployeeId('');
                    break;
                case 'EmployeeCode':
                    debugger
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);
                    let findEmployee = null;

                    if (serachemployeeCode.length >= 3) {
                        findEmployee = employeeOpts.find((employee) => {
                            return employee.employee_code === serachemployeeCode
                                || employee?.label?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                || employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.employee_id);
                        employeeComboRef.current = {
                            employee_id: findEmployee.employee_id,
                            employee_code: findEmployee.employee_code,
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                        };
                    }
                    break;
                case 'EmplCode':
                    // setEmployeeCode(employeeComboRef.current.employee_code);
                    if (employeeComboRef.current && employeeComboRef.current.employee_code) {
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    }

                    break;
                case 'Employee':
                    debugger
                    var employeeID = employeeComboRef.current?.employee_id;
                    if (employeeID !== "") {
                        // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeComboRef.current.employee_id);
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    } else {
                        setEmployeeCode('');
                    }

                default:
                    break;
            }

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    const FnFillComobos = async () => {
        try {
            // Set Default Year.
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setSalaryYear(defaultYear.value);
            salaryYearComboRef.current = defaultYear;
            // Set Default Month.
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setSalaryMonth(defaultMonth.value);
            salaryMonthComboRef.current = defaultMonth;

            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmpls => {
                    rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name, old_employee_code: prop.old_employee_code }));
                    rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All', old_employee_code: '' });
                    setEmployeeOpts(rcvdEmpls);
                });



            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "amv_properties"
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeTypeGroup' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    const emplTypes = [
                        // { value: '', label: 'Select', field_id: '', field_name: '', },
                        { value: '0', label: 'All', field_id: 'All', field_name: 'All', },
                        ...rcvdEmplTypes.map((emplType) => ({ ...emplType, value: emplType.field_id, label: emplType.field_name, })),
                    ];
                    setEmployeeTypesOpts(emplTypes);
                });
            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    // subDepartments.unshift({ value: 'All', label: 'All' });
                    // setSubDepartmentOpts(subDepartments);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {

        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
        if (isEmpty(salaryMonthComboRef.current?.value)) { $('#error_cmb_salary_month').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    const goBack = () => {
        navigate("/Dashboard")
    }

    const updateRowData = (currentRowData, e) => {
        debugger
        // Get the column name that is being edited
        let clickedColName = e.target.getAttribute('Headers');
        delete e.target.parentElement.dataset.tip; // Clear any tooltip if exists

        if (!currentRowData.hasOwnProperty('status') || currentRowData.status === null) {
            currentRowData['status'] = 'Pending';
        }
        switch (clickedColName) {
            // Handle remark column
            case 'remark':
                currentRowData[clickedColName] = e.target.value;
                currentRowData['status'] = 'Pending';
                break;

            // Handle status column
            case 'status':
                currentRowData[clickedColName] = e.target.value;
                break;
            // Handle status column
            case 'approval_remark':
                currentRowData[clickedColName] = e.target.value;
                currentRowData['status'] = 'Approved';
                break;
            default:
                break;
        }

        // Update the row data in the main data array
        const updatedData = [...attendanceDtlTblData];
        const rowIndex = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex')); // Get the row index from the element
        updatedData[rowIndex] = currentRowData; // Update the row data at the specified index
        setAttendanceDtlTblData(updatedData); // Set the updated data back into state
    };



    const renderCoffRequestDetails = useMemo(() => {
        return (
            <Table className="erp_table" bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th text-center">Action</th>
                        <th className="erp_table_th text-center">Sr. No.</th>
                        <th className="erp_table_th text-center">Att. Date</th>
                        <th className="erp_table_th">Employee Name</th>
                        <th className="erp_table_th text-center">Empl. Code</th>
                        <th className="erp_table_th text-center">Punch Code</th>
                        <th className="erp_table_th text-center">Department</th>
                        <th className="erp_table_th text-center">Sub Department</th>
                        <th className="erp_table_th text-center">Weekly Off</th>
                        <th className="erp_table_th text-center">Pre.Status</th>
                        <th className="erp_table_th text-center" style={{ width: "140px" }}>Remark</th>
                        {/* {isApprove && ( */}
                        <th className="erp_table_th text-center" style={{ width: "140px" }}>Approval Remark</th>
                        {/* )} */}
                        <th className="erp_table_th text-center" style={{ width: "140px" }}>Status</th>

                    </tr>
                </thead>
                <tbody>
                    {attendanceDtlTblData.length > 0 ? (
                        attendanceDtlTblData.map((employeeItem, index) => (
                            <tr key={index} rowIndex={index}>

                                <td className="erp_table_td"><div style={{ display: "flex" }}>

                                    <Tooltip title="Approve" placement="top">
                                        <MDTypography className="erp-view-btn" >
                                            < BsFillCheckCircleFill className={`erp-view-btn ${access_control.all_access || access_control.read_access ? 'display' : 'd-none'}`}
                                                // onClick={() => handleApproveClick(index)}
                                                onClick={employeeItem.status !== "Approved" ? () => handleApproveClick(index) : null} // Disable onClick if status is "Approved"
                                                // onClick={employeeItem.status !== "Approved" ? (e) => handleApproveClick(index, employeeItem, e) : undefined} Headers="status"

                                                // disabled={employeeItem.status === "Approved"} 
                                                style={{ display: employeeItem.status === "Approved" ? 'none' : 'inline' }} // Hide if status is "Approved"

                                            />
                                        </MDTypography>
                                    </Tooltip>
                                    <Tooltip title="Edit" placement="top" >
                                        <MDTypography className="erp-view-btn" >
                                            <MdModeEdit className="erp-edit-btn"
                                                // onClick={() => handleEditClick(index)} 
                                                onClick={employeeItem.status !== "Approved" ? () => handleEditClick(index) : null} // Disable onClick if status is "Approved"

                                                style={{ display: employeeItem.status === "Approved" ? 'none' : 'inline' }}
                                            />
                                        </MDTypography>
                                    </Tooltip>
                                </div>
                                </td>
                                <td className="erp_table_td text-center">{index + 1}</td>
                                <td className="erp_table_td">{employeeItem.att_date_time}</td>
                                <td className="erp_table_td">{employeeItem.employee_name}</td>
                                <td className="erp_table_td">{employeeItem.employee_code}</td>
                                <td className="erp_table_td"> {employeeItem.old_employee_code}</td>
                                <td className="erp_table_td">{employeeItem.department_name}</td>
                                <td className="erp_table_td"> {employeeItem.sub_department_name}</td>
                                <td className="erp_table_td"> {employeeItem.weeklyoff_name}</td>
                                <td className="erp_table_td"> {employeeItem.job_type}</td>
                                {/* {employeeItem.status === "Approved" ? (
                                    <>
                                        <td className="erp_table_td"> {employeeItem.remark}</td>
                                        <td className="erp_table_td"> {employeeItem.approval_remark}</td>
                                        <td className="erp_table_td"> {employeeItem.status}</td>
                                    </>
                                ) : (
                                    <> */}
                                <td className="erp_table_td">
                                    <div style={{
                                        display: 'flex', // Use flexbox to align items horizontally
                                        alignItems: 'center' // Vertically align items to the center
                                    }}>
                                        <textarea
                                            className="erp_input_field mb-0"
                                            Headers="remark"
                                            value={employeeItem.remark}
                                            maxLength={500}
                                            onChange={(e) => updateRowData(employeeItem, e)}
                                            disabled={editRowIndex !== index} // Enable when editing or approving
                                            style={{
                                                flex: 1, // Allow the textarea to take up available space
                                                marginRight: '8px', // Add space between textarea and icon
                                                borderColor: 'green', // Set the border color to green
                                                borderWidth: '1px', // Optionally set border width
                                                borderStyle: 'solid', // Ensure the border is visible
                                            }}
                                        />
                                        {/* Show Save Icon when editing or approving */}
                                        {editRowIndex === index && (
                                            <Tooltip title="Save" placement="top">
                                                <FaSave
                                                    className="save-icon"
                                                    onClick={() => handleSaveClick(index, "Pending")}
                                                    style={{
                                                        cursor: 'pointer',
                                                        fontSize: '16px', // Example size, adjust as necessary
                                                        color: 'green'
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                    {errorRows[index]?.remark && (
                                        <small style={{ color: 'red' }}>{errorRows[index].remark}</small>
                                    )}
                                </td>
                                {/* {isApprove && ( */}

                                <td className="erp_table_td">
                                    <div style={{
                                        display: 'flex', // Use flexbox to align items horizontally
                                        alignItems: 'center' // Vertically align items to the center
                                    }}>
                                        <textarea
                                            className="erp_input_field mb-0"
                                            Headers="approval_remark" // Changed to distinguish from other remarks
                                            value={employeeItem.approval_remark || ''} // Use a different property if needed
                                            maxLength={500}
                                            onChange={(e) => updateRowData(employeeItem, e)}
                                            disabled={approveRowIndex !== index} // Only editable if in approve mode
                                            style={{
                                                flex: 1, // Allow the textarea to take up available space
                                                marginRight: '8px', // Add space between textarea and icon
                                                borderColor: 'green', // Set the border color to green
                                                borderWidth: '1px', // Optionally set border width
                                                borderStyle: 'solid', // Ensure the border is visible
                                            }}
                                        />
                                        {approveRowIndex === index && (
                                            <Tooltip title="Save" placement="top">
                                                <FaSave
                                                    className="save-icon"
                                                    onClick={() => handleSaveClick(index, "Approved")}
                                                    style={{
                                                        cursor: 'pointer',
                                                        fontSize: '16px', // Example size, adjust as necessary
                                                        color: 'green'
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                    {/* {errorRows[index]?.approval_remark && (
                                        <small style={{ color: 'red' }}>{errorRows[index].approval_remark}</small>
                                    )} */}

                                </td>
                                {/* )} */}

                                <td className="erp_table_td">
                                    <select
                                        id={`employeestatus_${index}`}
                                        className="form-select form-select-sm"
                                        Headers="status"
                                        value={employeeItem.status || "Pending"}
                                        onChange={(e) => updateRowData(employeeItem, e)}
                                        disabled={approveRowIndex !== index}
                                    // disabled
                                    >

                                        <option value="Pending">Pending</option>
                                        <option value="Approved">Approved</option>
                                        {/* <option value="Rejected">Rejected</option> */}
                                    </select>
                                </td>
                                {/* </>
                                )} */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                className="erp_table_td"
                                colSpan="20"
                                style={{ textAlign: "center" }}
                            >
                                No records found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table >
        );
    }, [attendanceDtlTblData, editRowIndex, approveRowIndex, errorRows]);

    // ********* PrintOut Functionalities Ends. ****************************************


    const capitalizeStr = str => str.charAt(0).toUpperCase() + str.slice(1);
    const formatTableHeader = key => key.split('_').map(capitalizeStr).join(' ');


    const FnLoadAttendanceData = async () => {
        debugger;
        try {
            setIsLoading(true);
            setAttendanceDtlTblData([]);
            const validate = validateShiftManagementDetails();
            if (validate) {

                let selectedYear = salaryYearComboRef.current?.value || null;
                let selectedMonth = salaryMonthComboRef.current?.value || null;
                let selectedDepartment = departmentComboRef.current?.department_id || null;
                let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
                let selectedEmplType = employeeTypesComboRef.current?.field_name || null;
                let selectedEmployee = employeeComboRef.current?.employee_code || null;

                if (selectedEmployee === null) {
                    FnComboOnChange("subDepartment");
                    setEmployeeId('All');
                }

                if (selectedDepartment === null) {
                    setDepartmentId('All');
                }

                if (selectedSubDepartment === null) {
                    setSubDepartmentId('All');
                }


                resetGlobalQuery();
                globalQuery.columns = [
                    'empl.employee_name', 'empl.department_name', 'empl.sub_department_name', 'empl.weeklyoff_name', 'htatdl.att_date_time', 'htatdl.job_type',
                    'htatdl.employee_code', 'empl.employee_type', 'empl.old_employee_code', 'htatdl.employee_id', 'compoff.status', , 'compoff.remark', , 'compoff.approval_remark'

                ];
                globalQuery.table = "ht_attendance_daily htatdl";
                globalQuery.conditions.push({ field: "htatdl.is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "htatdl.company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "htatdl.attendance_month", operator: "=", value: selectedMonth });  // Here pass the selected monthNumber
                globalQuery.conditions.push({ field: "htatdl.attendance_year", operator: "=", value: selectedYear });
                globalQuery.conditions.push({ field: "htatdl.job_type", operator: "=", value: "WO" });
                globalQuery.conditions.push({ field: "htatdl.in_time", operator: "!=", value: null || 0 });
                globalQuery.conditions.push({ field: "htatdl.out_time", operator: "!=", value: null || 0 });
                // if (selectedEmplType && selectedEmplType !== 'All') {
                //     globalQuery.conditions.push({ field: "htatdl.employee_type", operator: "=", value: selectedEmplType });
                // }
                if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                    globalQuery.conditions.push({ field: "htatdl.employee_type", operator: "=", value: selectedEmplType });
                }
                if (selectedEmployee && selectedEmployee !== 'All') {
                    globalQuery.conditions.push({ field: "htatdl.employee_code", operator: "=", value: selectedEmployee });
                }
                if (selectedDepartment && selectedDepartment !== 'All') {
                    globalQuery.conditions.push({ field: "empl.department_id", operator: "=", value: selectedDepartment });
                }
                if (selectedSubDepartment && selectedSubDepartment !== 'All') {
                    globalQuery.conditions.push({ field: "empl.sub_department_id", operator: "=", value: selectedSubDepartment });
                }
                globalQuery.joins = [
                    {
                        "table": `cmv_employee empl`,
                        "type": "LEFT",
                        "on": [
                            { "left": "empl.employee_code", "right": "htatdl.employee_code", },
                            { "left": "empl.company_id", "right": "htatdl.company_id", },
                        ]
                    },
                    {
                        "table": `ht_comp_off_intimation_details compoff`,
                        "type": "LEFT",
                        "on": [
                            { "left": "compoff.employee_code", "right": "htatdl.employee_code", },
                            { "left": "compoff.att_date_time", "right": "htatdl.att_date_time", },
                            { "left": "compoff.company_id", "right": "htatdl.company_id", },
                        ]
                    },
                ]
                globalQuery.orderBy = ['empl.department_id DESC', 'empl.sub_department_id DESC', 'empl.employee_code Asc', 'htatdl.att_date_time'];
                let getAttendanceData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                const excludedKeysToDisplay = ["company_id", "attendance_process_id",
                    "process_date",
                    "company_id", "company_branch_id", ,
                ];
                if (getAttendanceData.length > 0) {
                    getAttendanceData = getAttendanceData.map((record, index) => ({
                        sr_no: index + 1,
                        ...record,
                    }));
                    const headersArray = Object.keys(getAttendanceData[0])
                        .filter(key => !excludedKeysToDisplay.includes(key))
                        .map(key => ({
                            Headers: formatTableHeader(key),
                            accessor: key
                        }));

                    setAttendanceDtlTblCols(headersArray);
                    setAttendanceDtlTblData(getAttendanceData);
                    console.table(getAttendanceData);
                } else {
                    setAttendanceDtlTblData([]);
                }
            }
        } catch (error) {
            console.log("error on filling combos: ", error);
            navigate('/Error');
        } finally {
            setIsLoading(false);
        }
    };

    //************************************* */

    const handleEditClick = (index) => {
        setEditRowIndex(index);  // Enable only remark editing
        setApproveRowIndex(null); // Ensure the approve mode is reset
        setIsApprove(false); // Hide approval remark column
    };
    // Handle Approve button click
    const handleApproveClick = async (index) => {

        debugger
        // $(`#employeestatus_${index}`).val('Approved');
        setApproveRowIndex(index); // Enable both remark and status editing
        setEditRowIndex(null); // Ensure the edit row is reset
        const updatedData = [...attendanceDtlTblData];
        const rowToUpdate = updatedData[index];
        let employeeCode = rowToUpdate.employee_code;
        let attendanceDate = rowToUpdate.att_date_time;
        setIsApprove(true);
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmCompoffDetails/FnShowParticularRecordForUpdate/${COMPANY_ID}/${employeeCode}/${attendanceDate}`)
        const response = await apiCall.json();

        const details = response.data;
        if (details !== null) {
            // Check if each field exists in `details`, otherwise set to null
            rowToUpdate.remark = details.remark !== undefined ? details.remark : '';
            rowToUpdate.approval_remark = details.approval_remark !== undefined ? details.approval_remark : '';
            // rowToUpdate.status = details.status !== undefined ? details.status : null;
            $(`#employeestatus_${index}`).val('Approved');
            rowToUpdate.status = $(`#employeestatus_${index}`).val();
            rowToUpdate.comp_off_intimation_details_id = details.comp_off_intimation_details_id !== undefined ? details.comp_off_intimation_details_id : '';

        }
    };


    // Function to validate rows
    const validateRows = (index, field) => {
        const errors = { ...errorRows }; // Create a copy of error state
        let hasError = false;

        if (field === "remark" && (!attendanceDtlTblData[index].remark || attendanceDtlTblData[index].remark.trim() === "")) {
            errors[index] = { ...errors[index], remark: "Remark is required." };
            hasError = true;
        } else if (errors[index]?.remark) {
            delete errors[index].remark; // Remove error if no longer applicable
        }

        // if (field === "approval_remark" && ((!attendanceDtlTblData[index].approval_remark || attendanceDtlTblData[index].approval_remark.trim() === "") || (!attendanceDtlTblData[index].remark || attendanceDtlTblData[index].remark.trim() === ""))) {
        //     errors[index] = { ...errors[index], approval_remark: "Approval remark is required." };
        //     hasError = true;
        // } else if (errors[index]?.approval_remark) {
        //     delete errors[index].approval_remark; // Remove error if no longer applicable
        // }

        setErrorRows(errors); // Update the state with errors

        return !hasError; // Return validation result
    };

    const handleSaveClick = async (index, key) => {
        debugger
        const updatedData = [...attendanceDtlTblData];
        const rowToSave = updatedData[index];
        const isEditMode = key === 'Pending';
        const isApproveMode = key === 'Approved';
        let isRemarkValid = true; // Initialize with a default value
        let isApprovalRemarkValid = true; // Initialize with a default value

        if (key === 'Pending') {
            isRemarkValid = validateRows(index, "remark");
        } else {
            isApprovalRemarkValid = validateRows(index, "approval_remark");
        }
        if (isRemarkValid && isApprovalRemarkValid) {
            try {
                const data = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    approved_by_id: isApproveMode ? UserId : '',
                    approved_date: isApproveMode ? today() : null,
                    created_by: UserName,
                    punch_code: rowToSave.old_employee_code,
                    employee_id: rowToSave.employee_id,
                    comp_off_intimation_details_id: rowToSave.comp_off_intimation_details_id !== undefined && rowToSave.comp_off_intimation_details_id !== 0
                        ? rowToSave.comp_off_intimation_details_id
                        : 0,
                    att_date_time: rowToSave.att_date_time,
                    employee_code: rowToSave.employee_code,
                    status: rowToSave.status,
                    weeklyoff_name: rowToSave.weeklyoff_name,
                    remark: rowToSave.remark,
                    employee_type: rowToSave.employee_type,
                    approval_remark: rowToSave.approval_remark,
                }
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                };

                const getCompOffDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmCompoffDetails/FnAddUpdateRecord`, requestOptions)
                const response = await getCompOffDetails.json()
                if (response.success === 1) {
                    setToastMessage(response.data); // Set dynamic message
                    setShow(true)
                } else {

                }

            } catch (error) {
                console.log("error", error);
            } finally {
                setIsLoading(false);
            }
            setEditRowIndex(null);
            setApproveRowIndex(null);
        };
    };

    return (
        <>
            <ComboBox ref={comboDataFunc} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}
                <>
                    <div className="App">
                        {/* <Button onClick={() => setShow(true)}>Show Toast</Button> */}

                        <ToastContainer position="top-center" className="p-3" style={{ marginTop: '20%' }}>
                            <Toast show={show} onClose={() => setShow(false)} delay={3000} autohide>
                                <Toast.Header>
                                    {/* <span className='erp_modal_success_icon me-auto erp_modal_body'><RxCheckCircled /></span> */}
                                    <div style={{display: 'flex',flexDirection: 'column',width: '100%',alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                        <span className='erp_modal_success_icon erp_modal_body'>
                                            <RxCheckCircled />
                                        </span>
                                        <span className="d-block">
                                            {toastMessage}
                                        </span>
                                    </div>

                                </Toast.Header>
                                <Toast.Body className='erp_modal_body'>
                                    {/* {toastMessage}  */}
                                </Toast.Body>

                            </Toast>
                        </ToastContainer>
                    </div>
                </>

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Comp. Leave Request Form</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">

                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryYearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_salary_year"
                                            value={yearOpts.find(option => option.value == cmb_salary_year) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryYear(selectedOpt.value);
                                                salaryYearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryMonthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_salary_month"
                                            value={monthOpts.find(option => option.value == cmb_salary_month) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryMonth(selectedOpt.value);
                                                salaryMonthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>


                            </div>

                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnComboOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnComboOnChange("subDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>


                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnComboOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Employee Name :<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-3">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                            onChange={(e) => { FnComboOnChange('EmployeeCode'); }}
                                            onBlur={(e) => { FnComboOnChange('EmplCode'); }}
                                            maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                    <div className="col">
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                                FnComboOnChange('Employee');
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="save-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnLoadAttendanceData(); }}
                                >Show</MDButton>

                            </div>
                        </div>

                        <hr />

                        <div className="row px-lg-2 d-block">
                            <div className="col-lg-12 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-md-lg">Employee's Comp. Leave Details</label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">
                                        {
                                            attendanceDtlTblData.length === 0
                                                ? <>
                                                    <Card id="NoRcrdId" >
                                                        <Card.Body>No records found...</Card.Body>
                                                    </Card>
                                                </>
                                                : <>
                                                    {renderCoffRequestDetails}
                                                    {/* <Datatable data={attendanceDtlTblData} columns={attendanceDtlTblCols} freezeColumnCount={3} striped /> */}
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>

        </>
    )
}
export default CompLeaveRequestForm;