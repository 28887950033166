import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';

const GenericWorkerSalaryTable = ({
    earningHeads,
    deductionHeads,
    salarySummaryDtlTblData,
    salaryEarningDtlTblData,
    salaryDeductionDtlTblData,
    departmentName,
    subDepartmentName,
    isLastTable = false,
    allDataForGrandTotal,
}) => {
    debugger
    // Destructure allDataForGrandTotal
    const { allEmployeesSummery, allEmployeesEarnings, allEmployeesDeductions } = allDataForGrandTotal;

    const tableContent = useMemo(() => {
        if (salarySummaryDtlTblData.length === 0) {
            return null;
        }

        return (
            <>
                <style>
                    {`
                        @page { 
                            size: legal landscape;
                            margin: 1.5%;
                        }
                        .erp_table {
                            font-size: 3px !important; /* Default font size */
                        }
                        .erp_table_th, .erp_table_td {
                            border: 1px solid black;
                            padding-top: 2px !important;
                            padding-bottom: 2px !important;
                        }
                        .erp_table td {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    `}
                </style>

                <label className="erp-form-label-lg"> Department : {subDepartmentName} {`[${departmentName}]`}  </label>


                {/* table for worker. */}
                <Table className="erp_table" id='MonthlySalaryDtlsTbl' style={{ whiteSpace: 'normal', width: '100%' }} bordered>
                    <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" style={{ width: '2%' }}>Sr. No.</th>
                            <th className="erp_table_th align-middle" style={{ width: '3%' }}>Code</th>
                            <th className="erp_table_th align-middle" style={{ width: '5%' }}>Employee Name</th>
                            <th className="erp_table_th align-middle" style={{ width: '5%' }}>Design.</th>
                            <th className="erp_table_th align-middle" style={{ width: '3%' }}>Rate</th>
                            {/* <th className="erp_table_th align-middle" style={{ width: '3%' }}>Working Days(Work Hours)</th> */}
                            <th className="erp_table_th align-middle" style={{ width: '3%' }}>W. Days</th>
                            <th className="erp_table_th align-middle" style={{ width: '3%' }}>W. Hours</th>
                            <th className="erp_table_th align-middle" style={{ width: '2%' }}>Leave</th>
                            {/* <th className="erp_table_th align-middle" style={{ width: '3%' }}>PerDay</th>
                            <th className="erp_table_th align-middle" style={{ width: '3%' }}>PerHour</th> */}

                            {/* Earning Heads Cols */}
                            {
                                earningHeads?.map((earningType, index) => (
                                    <> <th className="erp_table_th align-middle" style={{ width: '3%' }}>{earningType.earning_head_name}</th> </>
                                ))
                            }
                            <th className="erp_table_th align-middle" style={{ width: '4%' }}>Total Earning</th>

                            {/* Deduction Heads Cols */}
                            {
                                deductionHeads?.map((deductionType, index) => (
                                    <> <th className="erp_table_th align-middle" style={{ width: '3%' }}>{deductionType.deduction_head_name}</th> </>
                                ))
                            }

                            <th className="erp_table_th align-middle" style={{ width: '5%' }}>Total Deduction</th>
                            <th className="erp_table_th align-middle" style={{ width: '4%' }}>Net Salary</th>
                            <th className="erp_table_th align-middle" style={{ width: '4%' }}>Signature</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Start content Rows  */}
                        {salarySummaryDtlTblData?.map((salaryDtl, index) => {
                            let currentEmployeeEarnings = salaryEarningDtlTblData.filter(earning => earning.employee_id === salaryDtl.employee_id);
                            let currentEmployeeDeductions = salaryDeductionDtlTblData.filter(deduction => deduction.employee_id === salaryDtl.employee_id);
                            return (
                                <React.Fragment key={`employee-${index}`}>
                                    <tr style={{ whiteSpace: 'nowrap' }}>
                                        <td className="erp_table_td">{index + 1}</td>
                                        {/* <td className="erp_table_td">{salaryDtl['employee_code']}</td> */}
                                        <td className="erp_table_td">{salaryDtl['old_employee_code']}</td>
                                        <td className="erp_table_td">{salaryDtl['employee_name']}</td>
                                        <td className="erp_table_td">{salaryDtl['skill_type']}</td>
                                        <td className="erp_table_td text-end">{salaryDtl['job_type_rate']}</td>
                                        {/* <td className="erp_table_td text-end">{salaryDtl['salary_days'] `(${salaryDtl['worked_hours']})`}</td> */}
                                        {/* <td className="erp_table_td text-end">{`${salaryDtl['salary_days']} (${salaryDtl['worked_hours']})`}</td> */}
                                        <td className="erp_table_td text-end">{salaryDtl['salary_days']}</td>
                                        <td className="erp_table_td text-end">{salaryDtl['worked_hours']}</td>
                                        <td className="erp_table_td text-end">{`${salaryDtl['month_days'] - salaryDtl['salary_days']}`}</td>

                                        {/* <td className="erp_table_td text-end">{salaryDtl['salary_perday']}</td>
                                        <td className="erp_table_td text-end">{salaryDtl['salary_perhour']}</td> */}

                                        {/* Earning Heads Cols */}
                                        {
                                            earningHeads?.map((earningType, index) => {
                                                let currentEarning = currentEmployeeEarnings.find(earning => earning.earning_heads_id === earningType.earning_heads_id);
                                                return (
                                                    <td key={`emp-earning-${index}`} className="erp_table_td text-end"> {currentEarning?.earning_amount} </td>
                                                );
                                            })
                                        }
                                        <td className="erp_table_td text-end table-success">{salaryDtl['gross_salary']}</td>

                                        {/* Deduction Heads Cols */}
                                        {
                                            deductionHeads?.map((deductionType, index) => {
                                                let currentDeduction = currentEmployeeDeductions.find(deduction => deduction.deduction_heads_id === deductionType.deduction_heads_id);
                                                return (
                                                    <td key={`emp-deduction-${index}`} className="erp_table_td text-end"> {currentDeduction?.deduction_amount} </td>
                                                );
                                            })
                                        }
                                        <td className="erp_table_td text-end table-danger">{salaryDtl['total_deduction']}</td>

                                        <td className="erp_table_td text-end">{salaryDtl['net_salary']}</td>
                                        <td className="erp_table_td"></td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                        {/* Ends content Rows  */}

                        {/* Footer Summary Row Starts */}
                        <tr className='table-secondary'>
                            <th className="erp_table_th align-middle" colSpan={8}>Department Summary: </th>
                            {/* Earning Heads Cols */}
                            {
                                earningHeads?.map((earningType, index) => {
                                    const sumAmount = salaryEarningDtlTblData.reduce((sum, earning) => {
                                        return earning.earning_heads_id === earningType.earning_heads_id
                                            ? sum + earning.earning_amount
                                            : sum;
                                    }, 0);
                                    return (<th key={`summary-earning-${index}`} className="erp_table_th text-end" > {sumAmount.toFixed(2)} </th>);
                                })
                            }

                            <th className="erp_table_th text-end table-success">
                                {salarySummaryDtlTblData.reduce((sum, summary) => sum + summary.gross_salary, 0).toFixed(2)}
                            </th>

                            {/* Deduction Heads Cols */}
                            {
                                deductionHeads?.map((deductionType, index) => {
                                    const sumAmount = salaryDeductionDtlTblData.reduce((sum, deduction) => {
                                        return deduction.deduction_heads_id === deductionType.deduction_heads_id
                                            ? sum + deduction.deduction_amount
                                            : sum;
                                    }, 0);
                                    return (<th key={`summary-deduction-${index}`} className="erp_table_th text-end"> {sumAmount.toFixed(2)} </th>);
                                })
                            }

                            <th className="erp_table_th text-end table-danger">
                                {salarySummaryDtlTblData.reduce((sum, summary) => sum + summary.total_deduction, 0).toFixed(2)}
                            </th>

                            <th className="erp_table_th text-end">
                                {salarySummaryDtlTblData.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)}
                            </th>
                            <th className="erp_table_td"></th>
                        </tr>
                        {/* Footer Summary Row Ends */}


                        {/* Show the Grand Totals Starts */}
                        {
                            isLastTable
                                ? <>
                                    <tr style={{ height: '20px' }}>
                                        <td className="erp_table_td" colSpan={10 + deductionHeads.length + earningHeads.length}></td>
                                    </tr>

                                    <tr className='table-secondary'>
                                        <th className="erp_table_th align-middle" colSpan={8}> Grand Total</th>
                                        {/* Earning Heads Cols */}
                                        {
                                            earningHeads?.map((earningType, index) => {
                                                const sumAmount = allEmployeesEarnings.reduce((sum, earning) => {
                                                    return earning.earning_heads_id === earningType.earning_heads_id
                                                        ? sum + earning.earning_amount
                                                        : sum;
                                                }, 0);
                                                return (<th key={`summary-earning-${index}`} className="erp_table_th text-end" > {sumAmount.toFixed(2)} </th>);
                                            })
                                        }

                                        <th className="erp_table_th text-end table-success">
                                            {allEmployeesSummery.reduce((sum, summary) => sum + summary.gross_salary, 0).toFixed(2)}
                                        </th>

                                        {/* Deduction Heads Cols */}
                                        {
                                            deductionHeads?.map((deductionType, index) => {
                                                const sumAmount = allEmployeesDeductions.reduce((sum, deduction) => {
                                                    return deduction.deduction_heads_id === deductionType.deduction_heads_id
                                                        ? sum + deduction.deduction_amount
                                                        : sum;
                                                }, 0);
                                                return (<th key={`summary-deduction-${index}`} className="erp_table_th text-end"> {sumAmount.toFixed(2)} </th>);
                                            })
                                        }
                                        <th className="erp_table_th text-end table-danger">
                                            {allEmployeesSummery.reduce((sum, summary) => sum + summary.total_deduction, 0).toFixed(2)}
                                        </th>

                                        <th className="erp_table_th text-end">
                                            {allEmployeesSummery.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)}
                                        </th>

                                        {/* <th className="erp_table_th text-end table-danger">
                                            {salarySummaryDtlTblData.reduce((sum, { total_deduction }) => sum + total_deduction, 0).toFixed(2)}
                                        </th>

                                        <th className="erp_table_th text-end">
                                            {salarySummaryDtlTblData.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)}
                                        </th> */}
                                        <th className="erp_table_td"></th>
                                    </tr>

                                    <tr style={{ height: '70px' }}>
                                        <th className="erp_table_th align-bottom" colSpan={12 + deductionHeads.length + earningHeads.length}>
                                            <div className='row align-text-bottom text-center'>
                                                <div className='col-2 align-text-bottom text-center'>Prepared By</div>
                                                <div className='col-2 align-text-bottom text-center'>Checked By</div>
                                                <div className='col-2 align-text-bottom text-center'>HR Manager</div>
                                                <div className='col-2 align-text-bottom text-center'>Accounts Dept.</div>
                                                <div className='col-2 align-text-bottom text-center'>Accounts Manager</div>
                                                <div className='col-2 align-text-bottom text-center'>General Manager</div>
                                            </div>
                                        </th>
                                    </tr>
                                </>
                                : null
                        }
                        {/* Show the Grand Totals Ends */}

                    </tbody>
                </Table>
            </>
        );
    }, [earningHeads, deductionHeads, salarySummaryDtlTblData, salaryEarningDtlTblData, salaryDeductionDtlTblData]);
    return tableContent;
}

export default GenericWorkerSalaryTable;
