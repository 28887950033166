import React, { useState, useEffect, useRef, useMemo } from "react";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Import for the searchable combo box.
import Select from "react-select";

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import {
  globalQuery,
  resetGlobalQuery,
} from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";

function TFrmDaillyShiftManagementNew() {
  const configConstants = ConfigConstants();
  const {
    COMPANY_ID,
    COMPANY_BRANCH_ID,
    UserName,
    COMPANY_NAME,
    COMPANY_ADDRESS,
  } = configConstants;

  // UseRefs Hooks.
  const comboDataFunc = useRef();
  const exlsExp = useRef();

  // UseRefs Hooks For Searchable combos.
  let employeeTypesComboRef = useRef(null);
  let shiftsComboRef = useRef(null);
  let cmb_sub_department_id_ref = useRef(null);
  let cmb_department_id_ref = useRef(null);
  let subDepartmentComboRef = useRef(null);
  let cmb_employee_id_ref = useRef(null);

  // Helping hooks;
  const [isLoading, setIsLoading] = useState(false);

  // combo's options hooks;
  const defaultOptions = {
    value: "0",
    label: "All",
    two_day_shift: "",
    field_name: "All",
  };
  const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
  const [shiftOpts, setShiftOpts] = useState([defaultOptions]);
  // const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);

  // Shift management data hooks;
  const [employeePunchingDetails, setEmployeePunchingDetails] = useState([]);
  const [employeeShiftHeaders, setEmployeeShiftHeaders] = useState([]);
  const [jobTypeOpts, setJobTypeOpts] = useState([]);
  const [employeeOpts, setEmployeeOpt] = useState([]);
  // Input field's Hooks;
  const [cmb_employee_type, setEmployeeType] = useState("");
  const [cmb_shift, setShift] = useState("0");
  const [dt_att_date, setAttDate] = useState("");
  const [dt_att_To_date, setAttToDate] = useState("");
  // const [subDepartmentId, setSubDepartmentId] = useState(0);
  const [chk_punch_type, setPunchType] = useState(true);

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
  };
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState("");

  const [departmentOpts, setDepartmentOpts] = useState([]);
  const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
  const [cmb_departmentId, setDepartmentId] = useState(0);
  const [allDepartmentsList, setAllDepartmentsList] = useState([]);
  const [cmb_sub_department_id, setSubDepartmentId] = useState(0);
  const [cmb_employee_id, setEmployeeId] = useState(0);
  // Invalid job type
  const [invalidJobTypeIndex, setInvalidJobTypeIndex] = useState(null);

  const nonUpdateJobCodeForIds = [2, 5, 6, 7]; // []

  useEffect(() => {
    const loadDataOnload = async () => {
      if (shiftsComboRef) shiftsComboRef.current = defaultOptions;
      setIsLoading(true);
      await FnFillComobos();
      setIsLoading(false);
    };
    loadDataOnload();
  }, []);

  const FnFillComobos = async () => {
    try {
      // Load employee types
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name"];
      globalQuery.table = "amv_properties";
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({
        field: "properties_master_name",
        operator: "=",
        value: "EmployeeTypeGroup",
      });
      comboDataFunc.current
        .fillFiltersCombo(globalQuery)
        .then((rcvdEmplTypes) => {
          const emplTypes = [
            { value: "", label: "Select", field_id: "", field_name: "" },
            ...rcvdEmplTypes.map((emplType) => ({
              ...emplType,
              value: emplType.field_id,
              label: emplType.field_name,
            })),
          ];
          setEmployeeTypesOpts(emplTypes);
        });

      // Load employee types
      // resetGlobalQuery();
      // globalQuery.columns = ["department_id", "department_name"];
      // globalQuery.table = "cm_department"
      // globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      // globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
      // comboDataFunc.current.fillFiltersCombo(globalQuery)
      //     .then(rcvdDepartment => {
      //         const departments = [
      //             { value: 0, label: 'Select', department_id: '', department_name: '', },
      //             ...rcvdDepartment.map((dept) => ({ ...dept, value: dept.department_id, label: dept.department_name, })),
      //         ];
      //         setSubDepartmentOpts(departments);
      //     });

      resetGlobalQuery();
      globalQuery.columns = [
        "department_id",
        "parent_department_id",
        "department_type",
        "department_name",
        "department_group",
      ];
      globalQuery.table = "cm_department";
      globalQuery.conditions.push({
        field: "company_id",
        operator: "IN",
        values: [0, parseInt(COMPANY_ID)],
      });
      globalQuery.conditions.push({
        field: "is_delete",
        operator: "=",
        value: 0,
      });
      comboDataFunc.current.fillFiltersCombo(globalQuery).then((rcvdDepts) => {
        const { mainDepartments, subDepartments } = rcvdDepts.reduce(
          (acc, department) => {
            if (department.department_type === "M") {
              acc.mainDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            } else if (department.department_type === "S") {
              acc.subDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            }
            return acc;
          },
          { mainDepartments: [], subDepartments: [] }
        );
        setAllDepartmentsList([...mainDepartments, ...subDepartments]);

        mainDepartments.unshift({
          department_id: 0,
          value: "All",
          label: "All",
        });
        setDepartmentOpts(mainDepartments);
        // subDepartments.unshift({ value: 'All', label: 'All' });
        // setSubDepartmentOpts(subDepartments);
      });


      resetGlobalQuery();
      globalQuery.columns = ["*"];
      globalQuery.table = "cmv_employee";
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
      comboDataFunc.current.fillFiltersCombo(globalQuery).then((emplOpt) => {
        // emplOpt = emplOpt?.map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
        emplOpt = emplOpt?.map(prop => ({ ...prop, value: prop.field_id, label: `[${prop.old_employee_code}] ${prop.field_name}` }));

        emplOpt.unshift({ employee_id: '', value: 'All', label: 'All' });
        setEmployeeOpt(emplOpt);

      });
      // Load JobTypes
      resetGlobalQuery();
      globalQuery.columns = ['job_type_id', 'job_type_name', 'job_type_short_name', 'job_type_category'];
      globalQuery.table = "hm_job_type";
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataFunc.current.fillFiltersCombo(globalQuery).then((jobTypes) => {
        jobTypes = jobTypes?.map(prop => ({ ...prop, value: prop.job_type_id, label: prop.job_type_name }));
        setJobTypeOpts(jobTypes);
      });

    } catch (error) {
      console.log("error on filling combos: ", error)
      navigator('/Error')
    }
  }

  const FnCombosOnChange = (key, value) => {
    try {
      switch (key) {
        case "EmployeeType":
          const employee_type = employeeTypesComboRef.current.value;

          if (employee_type !== "") {
            //Hide the error msg
            let errorElement = document.getElementById(
              "error_cmb_employee_type"
            );
            errorElement.style.display = "none";

            // Load shifts
            resetGlobalQuery();
            globalQuery.columns = [
              "field_id",
              "field_name",
              "two_day_shift",
              "start_time",
              "end_time",
            ];
            globalQuery.table = "cmv_shift";
            globalQuery.conditions = [
              {
                field: "employee_type_group",
                operator: "=",
                value: employeeTypesComboRef.current.field_name,
              },
            ];
            comboDataFunc.current
              .fillFiltersCombo(globalQuery)
              .then((workingShifts) => {
                const shiftsList = [
                  {
                    value: "0",
                    label: "All",
                    two_day_shift: "",
                    field_name: "All",
                  },
                  ...workingShifts.map((shifts) => ({
                    ...shifts,
                    value: shifts.field_id,
                    label:
                      shifts.field_name +
                      ` [${shifts.start_time} - ${shifts.end_time}]`, // Without Am/Pm
                    two_day_shift: shifts.two_day_shift,
                  })),
                ];
                setShiftOpts(shiftsList);
              });
          }

          setShift("0");
          if (shiftsComboRef.current) shiftsComboRef.current = defaultOptions;
          const employeeType = employeeTypesComboRef.current.field_name;
          resetGlobalQuery();
          globalQuery.columns = ["*"];
          globalQuery.table = "cmv_employee";
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employeeType });
          comboDataFunc.current.fillFiltersCombo(globalQuery).then((emplOpt) => {
            // emplOpt = emplOpt?.map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
            emplOpt = emplOpt?.map(prop => ({ ...prop, value: prop.field_id, label: `[${prop.old_employee_code}] ${prop.field_name}` }));

            emplOpt.unshift({ employee_id: '', value: 'All', label: 'All' });
            setEmployeeOpt(emplOpt);
            setEmployeeId('All');
            cmb_employee_id_ref.current = { employee_id: '', value: 'All', label: 'All' };
          });


          break;
        case "AttDate":
          if (value !== "")
            document.getElementById("error_dt_att_date").style.display = "none";

          setAttDate(value);
          break;
        case "AttToDate":
          if (value !== "")
            document.getElementById("error_dt_att_To_date").style.display = "none";

          setAttToDate(value);
          break;
        case "Shift":
          const shift = shiftsComboRef.current.value;
          if (shift !== "")
            document.getElementById("error_cmb_shift").style.display = "none";
          break;
        case "Department":
          setEmployeePunchingDetails([]);
          subDepartmentComboRef.current = null;
          setSubDepartmentId(0);
          let selectedDepartment = cmb_department_id_ref.current.department_id;
          const subDepartmentList = allDepartmentsList.filter(
            (department) =>
              (!selectedDepartment ||
                department.parent_department_id === selectedDepartment) &&
              department.department_type === "S"
          );
          subDepartmentList.unshift({
            department_id: 0,
            value: "All",
            label: "All",
          });
          setSubDepartmentOpts(subDepartmentList);

          break;
        case "subDepartment":
          setEmployeePunchingDetails([]);
          let selectedDepTS = cmb_department_id_ref.current.department_id;
          const subDepartmentLists = allDepartmentsList.filter(
            (department) =>
              (!selectedDepTS ||
                department.parent_department_id === selectedDepTS) &&
              department.department_type === "S"
          );
          subDepartmentLists.unshift({
            department_id: "",
            value: "All",
            label: "All",
          });
          setSubDepartmentOpts(subDepartmentLists);

          break;
        default:
          break;
      }

      setEmployeePunchingDetails([]); // clear employee punching details on any field change
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateShiftManagementDetails = () => {
    if (cmb_employee_type === "") {
      let errorElement = document.getElementById("error_cmb_employee_type");
      errorElement.textContent = "Please fill this field...!";
      errorElement.style.display = "block";
      document.getElementById("cmb_employee_type").focus();
      return false;
    }

    if (cmb_shift === "") {
      let errorElement = document.getElementById("error_cmb_shift");
      errorElement.textContent = "Please fill this field...!";
      errorElement.style.display = "block";
      document.getElementById("cmb_shift").focus();
      return false;
    }

    if (dt_att_date === "") {
      let errorElement = document.getElementById("error_dt_att_date");
      errorElement.textContent = "Please fill this field...!";
      errorElement.style.display = "block";
      document.getElementById("dt_att_date").focus();
      return false;
    }

    return true;
  };

  // fn to farmat date into string
  const FnFormatDate = (date) => {
    if (date !== null && date !== "") {
      return new Date(date)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .split("/")
        .reverse()
        .join("-"); // dd-mm-yyyy format
    }
  };

  const FnShowSchedulerData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/shiftManagementnew/FnShowSchedulerData`
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }

  }
  const FnShowAttendanceDetails = async () => {
    setIsLoading(true);
    try {
      let selectedDepartment =
        cmb_department_id_ref.current?.department_id || null;
      let selectedSubDepartment =
        subDepartmentComboRef.current?.department_id || null;

      let selectedEmployee = cmb_employee_id_ref.current?.field_id || null;
      if (selectedDepartment === null) {
        setDepartmentId("All");
        FnCombosOnChange("subDepartment");
      }

      if (selectedSubDepartment === null) {
        setSubDepartmentId("All");
      }
      if (selectedEmployee === null) {
        setEmployeeId("All");
      }
      // Validate required fields
      const validate = validateShiftManagementDetails();

      if (validate) {
        const data = {
          company_id: COMPANY_ID,
          employee_type_group: employeeTypesComboRef.current.field_name,
          shift_name: shiftsComboRef.current.field_name,
          two_day_shift: shiftsComboRef.current.two_day_shift,
          attendance_date: FnFormatDate(dt_att_date),
          dt_att_To_date: FnFormatDate(dt_att_To_date),
          department_id: cmb_department_id_ref.current.department_id,
          sub_department_id: subDepartmentComboRef.current.department_id,
          employee_id: cmb_employee_id_ref.current.field_id,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };

        const getEmployeeDetails = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/shiftManagementnew/FnShowEmployeesDetails`,
          requestOptions
        );
        const response = await getEmployeeDetails.json();
        if (response.success === 1) {
          setEmployeePunchingDetails(response.content);
          setEmployeeShiftHeaders(response.columns);
          console.log("Response: ", response);
        } else {
          setEmployeePunchingDetails([]);
          setErrMsg(response.error);
          setShowErrorMsgModal(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const FnExportShiftWisePunchDetails = async () => {

    try {
      setIsLoading(true);
      if (employeePunchingDetails.length !== 0) {
        let jsonToExportExcel = {
          allData: {},
          columns: [],
          filtrKeyValue: {},
          headings: {},
          key: "bomExport",
          editable_cols: [],
        }; // , 25 for remark;

        let filtrKeyValue = {};

        filtrKeyValue["0"] =
          "Employee Type : " + employeeTypesComboRef.current.field_name;
        filtrKeyValue["1"] = "Shift : " + shiftsComboRef.current.field_name;
        // filtrKeyValue["2"] = "Attendance Date : " + FnFormatDate(dt_att_date);
        // if (subDepartmentId !== 0)
        //     filtrKeyValue['3'] = "Department : " + cmb_sub_department_id_ref.current.department_name
        let departmentName = cmb_department_id_ref.current?.label || "";
        if (
          departmentName === "" ||
          departmentName === 0 ||
          departmentName === undefined
        ) {
          departmentName = "All";
        }

        filtrKeyValue["2"] = "Department Name : " + departmentName;
        // let subdepartmentName = subDepartmentComboRef.current.department_name;
        let subdepartmentName = subDepartmentComboRef.current?.label || "";
        if (
          subdepartmentName === "" ||
          subdepartmentName === 0 ||
          subdepartmentName === undefined
        ) {
          subdepartmentName = "All";
        }

        filtrKeyValue["3"] = "SubDepartment Name : " + subdepartmentName;
        filtrKeyValue["4"] = "Att. From Date : " + FnFormatDate(dt_att_date);
        // Check if dt_att_To_date is null, if yes, use dt_att_date
        if (dt_att_To_date != '') {
          filtrKeyValue["5"] = "Att. To Date : " + FnFormatDate(dt_att_To_date);
        } else {
          filtrKeyValue["5"] = "Att. To Date : " + FnFormatDate(dt_att_date);  // Set From Date as To Date
        }
        jsonToExportExcel["filtrKeyValue"] = filtrKeyValue;

        // set columns name.
        jsonToExportExcel.columns = employeeShiftHeaders;

        // Create a new object with the updated key name
        employeePunchingDetails.map((punchDetails, Index) => {
          jsonToExportExcel["allData"][Index] = punchDetails;
        });

        jsonToExportExcel["headings"]["ReportName"] = "Shift Punching Report";
        jsonToExportExcel["headings"]["CompanyName"] = COMPANY_NAME;
        jsonToExportExcel["headings"]["CompanyAddress"] = COMPANY_ADDRESS;

        await exlsExp.current.excel(
          jsonToExportExcel,
          shiftsComboRef.current.label + "-" + FnFormatDate(dt_att_date)
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const FnSaveDailyAndMonthlyAtt = async () => {
    try {
      setIsLoading(true);
      if (
        employeePunchingDetails !== null &&
        employeePunchingDetails.length !== 0
      ) {
        let requestJson = { punchingMasterData: {}, punchingDetailsData: [] };

        const punchingMasterData = {
          company_id: COMPANY_ID,
          employee_type_group: employeeTypesComboRef.current.field_name,
          shift_name: shiftsComboRef.current.field_name,
          two_day_shift: shiftsComboRef.current.two_day_shift,
          attendance_date: FnFormatDate(dt_att_date),
          dt_att_To_date: FnFormatDate(dt_att_To_date),
          // department_id: subDepartmentId
          department_id: cmb_department_id_ref.current.department_id,

          // sub_department_id: subDepartmentComboRef.current.department_id
        };

        requestJson.punchingMasterData = punchingMasterData;

        // Check for any invalid job_type_code_id                
        if (employeeTypesComboRef.current.field_name === 'Workers') {
          const invalidIndex = employeePunchingDetails.findIndex((element) => !element.job_type_code_id);
          console.log('invalidIndex:- ', invalidIndex);

          if (invalidIndex !== -1) {
            setInvalidJobTypeIndex(invalidIndex);
            return;  // Exit the function if there's an invalid job_type_code_id
          }
        }


        for (let index = 0; index < employeePunchingDetails.length; index++) {
          const element = employeePunchingDetails[index];
          const punchingDetailsJson = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            daily_attendance_id: 0,
            employee_id: element.employee_id,
            employee_code: element.employee_code,
            employee_type: element.employee_type,
            machine_employee_code: element.machine_employee_code,
            old_employee_code: element.old_employee_code,
            shift_scheduled: element.shift_scheduled,
            shift_present: element.shift_present,
            job_type_id: element.job_type_id,
            job_type: element.job_type,
            attendance_date: element.attendance_date,
            attendance_month: element.attendance_month,
            attendance_year: element.attendance_year,
            in_time: element.min_att_time,
            out_time: element.max_att_time,
            working_minutes: element.worked_hours,
            late_mark_flag: element?.late_mark_flag
              ? element.late_mark_flag
              : "N",
            early_go_flag: element?.early_go_flag ? element.early_go_flag : "N",
            gate_pass_flag: element?.gate_pass_flag
              ? element.gate_pass_flag
              : "N",
            week_off_present_flag: element?.week_off_present_flag
              ? element.week_off_present_flag
              : "N",
            night_shift_present_flag: element?.night_shift_present_flag
              ? element.night_shift_present_flag
              : "N",
            created_by: UserName,
            modified_by: UserName,
            att_date_time: FnFormatDate(dt_att_date),
            present_flag: element?.present_flag ? element.present_flag : "N",
            department_id: element.department_id,
            sub_department_id: element.sub_department_id,
            weeklyoff_name: element.weeklyoff_name,
            attendance_flag: element?.attendance_flag
              ? element.attendance_flag
              : "A",
            halfday_hours: element.halfday_hours,
            fullday_hours: element.fullday_hours,
            grace_early_time: element.grace_early_time,
            grace_late_time: element.grace_late_time,
            shift_start_end_time: element.shift_start_end_time,
            two_day_shift: element.two_day_shift,
            attendance_exclude_flag: element.attendance_exclude_flag,

            // Fields Required For Worker JobType;
            job_type_code_id: element.job_type_code_id,
            job_type_code_short_name: element.job_type_code_short_name,
            already_added_attendance: element.already_added_attendance,
            already_added_job_type_code_id:
              element.already_added_job_type_code_id,
            already_added_job_type_code_short_name:
              element.already_added_job_type_code_short_name,
            already_added_worked_hours: element.already_added_worked_hours,
          };

          requestJson.punchingDetailsData.push(punchingDetailsJson);
        }
        console.log(
          "Punching Detls For Save: ",
          requestJson.punchingDetailsData
        );

        const formData = new FormData();
        formData.append(
          `getEmployeeAttendanceDetails`,
          JSON.stringify(requestJson)
        );
        const requestOptions = {
          method: "POST",
          body: formData,
        };

        const apicall = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/shiftManagementnew/FnAddUpdateAttendanceDetails`,
          requestOptions
        );
        const responce = await apicall.json();

        if (responce.success === 0) {
          setErrMsg(responce.error);
          setShowErrorMsgModal(true);
        } else {
          setSuccMsg(responce.message);
          setShowSuccessMsgModal(true);
          setEmployeePunchingDetails([]);
          setEmployeeShiftHeaders([]);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const FnHoursOnChange = (rowItem, event) => {
    const arrayIndex = parseInt(
      event.target.parentElement.parentElement.getAttribute("rowIndex")
    );
    let clickedColName = event.target.getAttribute("Headers");

    // Parse shift start and end times
    let [shiftStart, shiftEnd] = rowItem.shift_start_end_time.split("-");
    const [shiftStartHours, shiftStartMinutes] = shiftStart
      .split(":")
      .map(Number);
    const [shiftEndHours, shiftEndMinutes] = shiftEnd.split(":").map(Number);
    const shiftStartTime =
      shiftStartHours * 60 +
      shiftStartMinutes -
      (rowItem?.shift_grace_hours_min || 0) * 60;
    const shiftEndTime =
      shiftEndHours * 60 +
      shiftEndMinutes +
      (rowItem?.shift_grace_hours_max || 0) * 60;

    // Convert the shift times back to hours and minutes
    const adjustedShiftStartHours = Math.floor(shiftStartTime / 60);
    const adjustedShiftStartMinutes = shiftStartTime % 60;
    const adjustedShiftEndHours = Math.floor(shiftEndTime / 60);
    const adjustedShiftEndMinutes = shiftEndTime % 60;

    // Format the adjusted times as HH:mm
    const adjustedShiftStart = `${adjustedShiftStartHours
      .toString()
      .padStart(2, "0")}:${adjustedShiftStartMinutes
        .toString()
        .padStart(2, "0")}`;
    const adjustedShiftEnd = `${adjustedShiftEndHours
      .toString()
      .padStart(2, "0")}:${adjustedShiftEndMinutes
        .toString()
        .padStart(2, "0")}`;

    // Function to convert time in HH:MM format to total minutes since midnight
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    // Determine if it's a night shift (crosses midnight)
    const isNightShift = rowItem.two_day_shift;

    switch (clickedColName) {
      case "min_att_time":
        const minInputTime = event.target.value;
        const inputMinutesMin = timeToMinutes(minInputTime);
        rowItem[clickedColName] = minInputTime;

        // Convert input time to total minutes
        if (event._reactName === "onBlur") {
          if (
            !isNightShift &&
            inputMinutesMin >= shiftStartTime &&
            inputMinutesMin <= shiftEndTime
          ) {
            rowItem[clickedColName] = minInputTime;
          } else if (isNightShift && inputMinutesMin >= shiftStartTime) {
            rowItem[clickedColName] = minInputTime;
          } else if (rowItem[clickedColName] !== "") {
            alert(
              `Time must be between ${adjustedShiftStart} and ${adjustedShiftEnd}`
            );
            rowItem[clickedColName] = "";
            rowItem.worked_hours = "";
          }
        }
        break;
      case "max_att_time":
        const inputTime = event.target.value;
        const inputMinutes = timeToMinutes(inputTime);
        rowItem[clickedColName] = inputTime;

        // Convert input time to total minutes
        if (event._reactName === "onBlur") {
          if (isNightShift && inputMinutes <= shiftEndTime) {
            rowItem[clickedColName] = inputTime;
          } else if (
            !isNightShift &&
            inputMinutes >= shiftStartTime &&
            inputMinutes <= shiftEndTime
          ) {
            rowItem[clickedColName] = inputTime;
          } else if (rowItem[clickedColName] !== "") {
            alert(
              `Time must be between ${adjustedShiftStart} and ${adjustedShiftEnd}`
            );
            rowItem[clickedColName] = "";
            rowItem.worked_hours = "";
          }
        }

        break;
      default:
        break;
    }

    // Calculate worked hours if both min_att_time and max_att_time are available
    if (rowItem.min_att_time && rowItem.max_att_time) {
      const minMinutes = timeToMinutes(rowItem.min_att_time);
      const maxMinutes = timeToMinutes(rowItem.max_att_time);

      let workedMinutes = maxMinutes - minMinutes;

      if (workedMinutes < 0) {
        // Handle case where max time is earlier than min time (e.g., across midnight)
        workedMinutes += 24 * 60; // Add 24 hours to convert negative to positive
      }

      const workedHours = Math.floor(workedMinutes / 60);
      const remainingMinutes = workedMinutes % 60;

      // Format worked hours as HH:MM
      const formatTime = (hours, minutes) => {
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
      };

      rowItem.worked_hours = formatTime(workedHours, remainingMinutes);
    }

    rowItem["attendance_flag"] = "M";
    const tblRows = [...employeePunchingDetails];
    tblRows[arrayIndex] = rowItem;
    setEmployeePunchingDetails(tblRows);
  };

  const FnUpdateTblData = (rowItem, event) => {
    const arrayIndex = parseInt(
      event.target.parentElement.parentElement.getAttribute("rowIndex")
    );
    let clickedColName = event.target.getAttribute("Headers");

    switch (clickedColName) {
      case "job_type_code_id":
        if (event.target.value !== "") {
          let selectedJobType = jobTypeOpts.find(
            (option) => option.job_type_id == event.target.value
          );
          if (selectedJobType) {
            rowItem.job_type_code_id = selectedJobType.job_type_id;
            rowItem.job_type_code_short_name =
              selectedJobType.job_type_short_name;
          } else {
            rowItem.job_type_code_id = "";
            rowItem.job_type_code_short_name = "";
          }
        }
        break;

      default:
        break;
    }

    // Updating the data table hook;
    const tblRows = [...employeePunchingDetails];
    tblRows[arrayIndex] = rowItem;
    setEmployeePunchingDetails(tblRows);
    console.log(tblRows);
  };

  const renderShiftManagementDetails = useMemo(() => {
    return (
      <Table className="erp_table" bordered striped hover>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th text-center">Sr. No.</th>
            <th className="erp_table_th">Employee Name</th>
            <th className="erp_table_th text-center">Employee Code</th>
            <th className="erp_table_th text-center">Punching Code</th>
            <th className="erp_table_th text-center">Old Emp. Code</th>
            {/* <th className="erp_table_th">Department Group</th> */}
            <th className="erp_table_th text-center">Department</th>
            <th className="erp_table_th text-center">Sub Department</th>
            <th className="erp_table_th text-center">Attendance Date</th>
            <th className="erp_table_th text-center">In Time</th>
            <th className="erp_table_th text-center">Out Time</th>
            <th className="erp_table_th text-center">Worked Hours</th>
            <th className="erp_table_th text-center">Shift</th>
            <th className="erp_table_th text-center">Present Status</th>
            <th className="erp_table_th text-center">Job Code</th>
            <th className="erp_table_th text-center">Extra Remark</th>
          </tr>
        </thead>
        <tbody>
          {employeePunchingDetails.length > 0 ? (
            employeePunchingDetails.map((employeeItem, index) => (
              <tr key={index} rowIndex={index}>
                <td className="erp_table_td text-center">{index + 1}</td>
                <td className="erp_table_td">{employeeItem.employee_name}</td>
                <td className="erp_table_td">{employeeItem.employee_code}</td>
                <td className="erp_table_td">
                  {employeeItem.machine_employee_code}
                </td>
                <td className="erp_table_td">
                  {employeeItem.old_employee_code}
                </td>
                {/* <td className="erp_table_td">{employeeItem.department_group}</td> */}
                <td className="erp_table_td">{employeeItem.department_name}</td>
                <td className="erp_table_td">
                  {employeeItem.sub_department_name}
                </td>
                <td className="erp_table_td">{employeeItem.att_date_time}</td>
                <td className="erp_table_td text-center">
                  {/* {employeeItem.min_att_time &&
                  employeeItem.attendance_flag !== "M" ? (
                    employeeItem.min_att_time
                  ) : (
                    <input
                      type="time"
                      placeholder="HH:MM"
                      value={employeeItem?.min_att_time}
                      className="erp_input_field mb-0"
                      Headers="min_att_time"
                      onChange={(event) => FnHoursOnChange(employeeItem, event)}
                      onBlur={(event) => FnHoursOnChange(employeeItem, event)}
                    />
                  )} */}
                  <input
                    type="time"
                    placeholder="HH:MM"
                    value={employeeItem?.min_att_time !== null ? employeeItem?.min_att_time : ''}
                    className="erp_input_field mb-0"
                    Headers="min_att_time"
                    onChange={(event) => FnHoursOnChange(employeeItem, event)}
                    onBlur={(event) => FnHoursOnChange(employeeItem, event)}
                    // disabled={employeeItem.punch_type === "In"}
                    // disabled={
                    //   (employeeItem?.min_att_time && !employeeItem?.max_att_time) || 
                    //   (!employeeItem?.min_att_time && employeeItem?.max_att_time)
                    // }
                    style={{
                      backgroundColor: employeeItem.punch_type === "In" ? "#008000" : "inherit", // Dark red background for the entire cell
                      color: employeeItem.punch_type === "In" ? "#FFFFFF" : "inherit" // Optional: Change text color to white for better contrast
                    }}
                  />
                </td>
                <td className="erp_table_td text-center">
                  {/* {employeeItem.max_att_time &&
                  employeeItem.attendance_flag !== "M" ? (
                    employeeItem.max_att_time
                  ) : (
                    <input
                      type="time"
                      placeholder="HH:MM"
                      value={employeeItem?.max_att_time}
                      className="erp_input_field mb-0"
                      Headers="max_att_time"
                      onChange={(event) => FnHoursOnChange(employeeItem, event)}
                      onBlur={(event) => FnHoursOnChange(employeeItem, event)}
                    />
                  )} */}
                  <input
                    type="time"
                    placeholder="HH:MM"
                    value={employeeItem?.max_att_time !== null ? employeeItem?.max_att_time : ''}
                    className="erp_input_field mb-0"
                    Headers="max_att_time"
                    onChange={(event) => FnHoursOnChange(employeeItem, event)}
                    onBlur={(event) => FnHoursOnChange(employeeItem, event)}
                    // disabled={employeeItem.punch_type === "Out"}
                    //  disabled={
                    //   (employeeItem?.min_att_time && !employeeItem?.max_att_time) || 
                    //   (!employeeItem?.min_att_time && employeeItem?.max_att_time)
                    // }
                    style={{
                      backgroundColor: employeeItem.punch_type === "Out" ? "#008000" : "inherit", // Dark red background for "Out"
                      color: employeeItem.punch_type === "Out" ? "#FFFFFF" : "inherit" // White for "Out", Green (#008000) for others
                    }}
                  />
                </td>
                <td className="erp_table_td text-center">
                  {employeeItem.worked_hours}
                </td>
                <td className="erp_table_td text-center fw-bold">
                  {employeeItem.shift_name +
                    " [" +
                    employeeItem.shift_start_end_time +
                    "]"}
                </td>
                <td className="erp_table_td text-center fw-bold">
                  {employeeItem.attendance_status}
                </td>
                {/* <td className="erp_table_td text-center">
                                    <select className="form-select form-select-sm mb-0 w-auto"
                                        value={employeeItem.job_type_code_id} Headers='job_type_code_id'
                                        disabled={employeeItem.job_code_iseditable !== 'Y'}     // means comboIsNotEditable
                                        onChange={(event) => FnUpdateTblData(employeeItem, event)}>
                                        <option value=''>Select</option>
                                        {
                                            jobTypeOpts
                                                ?.map(jobType => {
                                                    // if (employeeTypesComboRef.current.field_name === 'Staffs' && jobType.job_type_category === 'Standard Rates') {
                                                    //     // Means show only standard rates (It is only for Staffs)
                                                    //     return (<option value={jobType.job_type_id}>{jobType.job_type_name}</option>)
                                                    // } else if (employeeItem.job_code_iseditable !== 'Y' && jobType.job_type_category === 'Standard Rates') {
                                                    //     // Means show only standard rates (It is for absent, leave, holiday, worker)
                                                    //     return (<option value={jobType.job_type_id}>{jobType.job_type_name}</option>)
                                                    // } else if (employeeItem.job_code_iseditable === 'Y' && jobType.job_type_category === 'MachineWise Rates') {
                                                    //     // Means show only machinewise rates only (It is for workers)
                                                    //     return (<option value={jobType.job_type_id}>{jobType.job_type_name}</option>)
                                                    // }

                                                    if (employeeItem.job_code_iseditable === 'Y' && jobType.job_type_category === 'MachineWise Rates') {
                                                        return (<option value={jobType.job_type_id}>{jobType.job_type_name}</option>)
                                                    } else if (employeeItem.job_code_iseditable !== 'Y') {
                                                        return (<option value={jobType.job_type_id}>{jobType.job_type_name}</option>)
                                                    }
                                                    return (<></>)
                                                })
                                        }
                                    </select>
                                </td> */}

                <td className="erp_table_td text-center">
                  <select
                    className={`form-select form-select-sm mb-0 w-auto ${invalidJobTypeIndex === index &&
                      !employeeItem.job_type_code_id
                      ? "is-invalid"
                      : ""
                      }`}
                    value={employeeItem.job_type_code_id}
                    Headers="job_type_code_id"
                    disabled={employeeItem.job_code_iseditable !== "Y"}
                    onChange={(event) => FnUpdateTblData(employeeItem, event)}
                    ref={(el) => {
                      if (
                        invalidJobTypeIndex === index &&
                        !employeeItem.job_type_code_id
                      )
                        el?.focus();
                    }}
                  >
                    <option value="">Select</option>
                    {jobTypeOpts.map((jobType) =>
                      (employeeItem.job_code_iseditable === "Y" &&
                        jobType.job_type_category === "MachineWise Rates") ||
                        employeeItem.job_code_iseditable !== "Y" ? (
                        <option
                          key={jobType.job_type_id}
                          value={jobType.job_type_id}
                        >
                          {jobType.job_type_name}
                        </option>
                      ) : null
                    )}
                  </select>
                  {invalidJobTypeIndex === index && (
                    <div className="invalid-tooltip">Job Type is required</div>
                  )}
                </td>
                <td className="erp_table_td fw-bold text-center">
                  {employeeItem.present_extra_remark}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="erp_table_td"
                colSpan="20"
                style={{ textAlign: "center" }}
              >
                No records found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }, [employeePunchingDetails, invalidJobTypeIndex]);

  //start csv file import code
  const csvFileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (csvFileInputRef.current) {
      csvFileInputRef.current.click();
    }
  };

  const csvHandleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("csvFile", file);

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/csvimport/CSVImport/${COMPANY_ID}`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (response.ok) {
          setSuccMsg("File imported successfully!");
          setShowSuccessMsgModal(true);
        } else {
          setErrMsg("Failed to import file.");
          setShowErrorMsgModal(true);
        }
      } catch (error) {
        console.error("Error sending file to backend:", error);
        setErrMsg("An error occurred while importing the file.");
        setShowErrorMsgModal(true);
      }
    }
  };

  //end csv file import code

  //help icon button
  const HelpIconButton = styled(HelpIcon)(({ }) => ({
    color: "#831657",
    marginTop: "10px",
    marginRight: "15px",
  }));

  const navigate = useNavigate();
  const handleHelpClick = () => {
    navigate("/FrmGeneric/Help/helpCenter");
  };
  // end help icon

  return (
    <>
      <ComboBox ref={comboDataFunc} />
      <ExcelExport ref={exlsExp} />

      <DashboardLayout>
        {isLoading ? (
          <div className="spinner-overlay">
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div>
        ) : null}

        {/* Card Starts*/}
        <div className="card mt-4">
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <label className="erp-form-label-md-lg">
                Daily Shift Management New
              </label>
            </div>

            <div style={{ display: "flex" }}>
              <Tooltip title="Click for help" arrow>
                <HelpIconButton onClick={handleHelpClick} />
              </Tooltip>
            </div>
          </div>

          {/* Card's Body Starts*/}
          <div className="card-body">
            <div className="row">
              {/* First Column Starts*/}
              <div className="col-sm-3 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label-md">
                      {" "}
                      Emp. Type {" "}<span className="required">*</span>
                    </Form.Label>
                  </div>
                  <div className="col-12 col-sm-8">
                    <Select
                      ref={employeeTypesComboRef}
                      options={employeeTypesOpts}
                      inputId="cmb_employee_type" // Provide the ID for the input box
                      value={employeeTypesOpts.find(
                        (option) => option.value === cmb_employee_type
                      )}
                      onChange={(selectedOpt) => {
                        setEmployeeType(selectedOpt.value);
                        employeeTypesComboRef.current = selectedOpt;
                        FnCombosOnChange("EmployeeType");
                      }}
                      placeholder="Search for a employee type..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                      }}
                    />
                    <MDTypography
                      variant="button"
                      id="error_cmb_employee_type"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    >
                      {" "}
                    </MDTypography>
                  </div>
                </div>

                {/* </div> */}
                {/* First Column Ends*/}

                {/* Second Column Starts*/}
                {/* <div className="col-sm-3 erp_form_col_div"> */}
                <div className="row">
                  <div className="col-sm-4">
                    {" "}
                    <Form.Label className="erp-form-label-md">
                      {" "}
                      Shift{" "}
                    </Form.Label>{" "}
                  </div>
                  <div className="col">
                    <Select
                      ref={shiftsComboRef}
                      options={shiftOpts}
                      inputId="cmb_shift" // Provide the ID for the input box
                      value={shiftOpts.find(
                        (option) => option.value === cmb_shift
                      )}
                      onChange={(selectedOpt) => {
                        setShift(selectedOpt.value);
                        shiftsComboRef.current = selectedOpt;
                        FnCombosOnChange("Shift");
                      }}
                      placeholder="Search for a shift..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                      }}
                    />
                    <MDTypography
                      variant="button"
                      id="error_cmb_shift"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    >
                      {" "}
                    </MDTypography>
                  </div>
                </div>
              </div>
              {/* Second Column Ends*/}
              <div className="col-sm-4 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label-md">
                      Department
                    </Form.Label>
                  </div>
                  <div className="col">
                    <Select
                      ref={cmb_department_id_ref}
                      options={departmentOpts}
                      // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      value={
                        departmentOpts.length > 1
                          ? departmentOpts.find(
                            (option) => option.value === cmb_departmentId
                          )
                          : null
                      }
                      onChange={(selectedOpt) => {
                        setDepartmentId(selectedOpt.value);
                        cmb_department_id_ref.current = selectedOpt;
                        FnCombosOnChange("Department");
                      }}
                      placeholder="Search for a Department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      // disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                      }}
                    />
                    <MDTypography
                      variant="button"
                      id="error_cmb_department_id"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    ></MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label-md">
                      {" "}
                      Sub-Department{" "}
                    </Form.Label>{" "}
                  </div>
                  <div className="col">
                    <Select
                      ref={subDepartmentComboRef}
                      options={subDepartmentOpts}
                      inputId="cmb_sub_department_id"
                      value={
                        subDepartmentOpts.find(
                          (option) => option.value == cmb_sub_department_id
                        ) || null
                      }
                      onChange={(selectedOpt) => {
                        setSubDepartmentId(selectedOpt.value);
                        subDepartmentComboRef.current = selectedOpt;
                        FnCombosOnChange("subDepartment");
                        // FnValidateFields();
                      }}
                      placeholder="Search for a sub-department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                      }}
                    />
                    <MDTypography
                      variant="button"
                      id="error_cmb_sub_department_id"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    >
                      {" "}
                    </MDTypography>
                  </div>
                </div>
              </div>
              {/* First Third Column Starts*/}
              <div className="col-sm-5 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label-md">
                      Att. From & To Date <span className="required">*</span>
                    </Form.Label>
                  </div>
                  <div className="col-sm-4">
                    <DatePicker
                      selected={dt_att_date}
                      id="dt_att_date"
                      onChange={(date) => FnCombosOnChange("AttDate", date)}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="dd-mm-yyyy"
                      className="erp_input_field optional"
                      maxDate={new Date()}
                    />
                    <MDTypography
                      variant="button"
                      id="error_dt_att_date"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DatePicker
                      selected={dt_att_To_date}
                      id="dt_att_To_date"
                      onChange={(date) => FnCombosOnChange("AttToDate", date)}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="dd-mm-yyyy"
                      className="erp_input_field optional"
                      maxDate={new Date()}
                    />
                    <MDTypography
                      variant="button"
                      id="error_dt_att_To_date"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label-md">
                      To Date
                    </Form.Label>
                  </div>
                  <div className="col">
                    <DatePicker
                      selected={dt_att_To_date}
                      id="dt_att_To_date"
                      onChange={(date) => FnCombosOnChange("AttToDate", date)}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="dd-mm-yyyy"
                      className="erp_input_field optional"
                      maxDate={new Date()}
                    />
                    <MDTypography
                      variant="button"
                      id="error_dt_att_To_date"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label-md">
                      Employee
                    </Form.Label>
                  </div>
                  <div className="col">
                    <Select
                      ref={cmb_employee_id_ref}
                      options={employeeOpts}
                      // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      value={
                        employeeOpts.length > 1
                          ? employeeOpts.find(
                            (option) => option.value === cmb_employee_id
                          )
                          : null
                      }
                      onChange={(selectedOpt) => {
                        setEmployeeId(selectedOpt.value);
                        cmb_employee_id_ref.current = selectedOpt;
                        // FnCombosOnChange("Department");
                      }}
                      placeholder="Search for a Department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      // disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                      }}
                    />
                    <MDTypography
                      variant="button"
                      id="error_cmb_employee_id"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    ></MDTypography>
                  </div>
                </div>
              </div>
              {/* First Third Column Ends*/}

            </div>

            {/*Second row */}
            {/* <div className="row"> */}
            {/* <div className='row'>
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Department</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Select
                                            ref={cmb_sub_department_id_ref}
                                            options={subDepartmentOpts}
                                            // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            value={subDepartmentOpts.length > 1 ? subDepartmentOpts.find(option => option.value === subDepartmentId) : null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                cmb_sub_department_id_ref.current = selectedOpt;
                                                // FnCombosOnChange("Sub-Department");
                                            }}
                                            placeholder="Search for a Sub-Department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            // disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                             
                            </div>
                            <div className="col-sm-4"></div>
                        </div> */}
            {/* </div> */}

            <div className="row mt-1">
              <div className="col-auto">
                <MDButton
                  type="button"
                  className="ms-2 erp-gb-button"
                  variant="button"
                  fontWeight="regular"
                  onClick={handleButtonClick}
                >
                  Import
                </MDButton>
              </div>
              <input
                type="file"
                ref={csvFileInputRef}
                style={{ display: "none" }}
                accept=".csv,.xlsx,.xls"
                onChange={csvHandleFileChange}
              />
              <div className="col text-center">
                <MDButton
                  type="button"
                  className="erp-gb-button"
                  onClick={FnShowAttendanceDetails}
                  variant="button"
                  fontWeight="regular"
                >
                  Show
                </MDButton>
                <MDButton
                  type="button"
                  className="ms-2 erp-gb-button"
                  onClick={FnExportShiftWisePunchDetails}
                  variant="button"
                  fontWeight="regular"
                  disabled={employeePunchingDetails.length === 0}
                >
                  Export To Excel
                </MDButton>
              </div>
              <div className="col-auto">
                <MDButton
                  type="button"
                  className="erp-gb-button"
                  onClick={FnShowSchedulerData}
                  variant="button"
                  fontWeight="regular"
                >
                  Scheduler
                </MDButton>
              </div>
            </div>

            <hr />

            <div className="row px-lg-2 d-block">
              <div className="col-lg-12 col-12 erp_form_col_div">
                <div className="card">
                  <div className="card-header py-0 main_heding mb-0">
                    <label className="erp-form-label-md-lg">
                      Employee's Punching Details
                    </label>
                  </div>
                  {/* Card's Body Starts*/}
                  <div className="card-body p-0">
                    <div
                      className={`table-responsive ${employeePunchingDetails.length > 5
                        ? "erp_table_scroll"
                        : ""
                        }`}
                      style={{ height: "350px" }}
                    >
                      {renderShiftManagementDetails}
                    </div>
                    <div className="text-center mt-1">
                      <MDButton
                        type="button"
                        className="erp-gb-button ms-2"
                        variant="button"
                        onClick={() => {
                          FnSaveDailyAndMonthlyAtt();
                        }}
                        fontWeight="regular"
                      >
                        Save
                      </MDButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Card's Body Ends*/}
        </div>
        {/* Card Ends*/}
      </DashboardLayout>

      <SuccessModal
        handleCloseSuccessModal={() => handleCloseSuccessModal()}
        show={[showSuccessMsgModal, succMsg]}
      />
      <ErrorModal
        handleCloseErrModal={() => handleCloseErrModal()}
        show={[showErrorMsgModal, errMsg]}
      />
    </>
  );
}

export default TFrmDaillyShiftManagementNew;
