import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';
//  import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx'; // instead of 'xlsx-style'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Import for the DatePicker.
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ExcelExport from "Features/Exports/ExcelExport";

function DailyManPowerRegister() {

    // Global Constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_ADDRESS, UserId, DEPARTMENT_ID, USER_CODE } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const validateNumberDateInput = useRef();
    const exlsExp = useRef();

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // ****** navigate
    const navigate = useNavigate();

    // combo's options hooks;
    const [employeeProps, setEmployeeProps] = new useState([]);
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);
    const [allSkillTypes, setAllSkillTypes] = useState([]);
    const [allJobTypesRates, setAllJObTypesAndRate] = useState([]);
    const [txt_employee_code, setEmployeeCode] = useState();
    const [userDetails, setUserDetails] = useState([]);
    // Input field's Hooks;
    const [dt_att_from, setAttFrom] = useState('');
    const [dt_att_to, setAttTo] = useState('');
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');
    const [contractorOptions, setContractorOption] = useState([]);
    const [cmb_contractor_id, setContractorId] = useState('');
    const cmb_contractor_id_ref = useRef()

    // Hooks For table;
    const [attendanceDtlTblData, setAttendanceDtlTblData] = useState([]);

    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnFillComobos = async () => {
        debugger
        try {
            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "property_group", operator: "=", value: 'S' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeTypeGroup')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                });
            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code", "employee_type_group"];
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "employee_id", operator: "=", value: UserId });
            // if (DEPARTMENT_ID != 11) {
            //     globalQuery.conditions.push({ field: "reporting_to", operator: "=", value: UserId });
            // }
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setUserDetails(getEmployeeList);
    

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"]
            globalQuery.table = "cmv_contractor"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: "0" });
            comboDataFunc.current.fillFiltersCombo(globalQuery).then((contractorList) => {
                // combobox.current.fillMasterData("cmv_contractor", "", "").then((contractorList) => {
                const contractors = [
                    { value: '', label: 'Select' },
                    ...contractorList.map((contractor) => ({ ...contractor, value: contractor.field_id, label: contractor.field_name, })),
                ];
                setContractorOption(contractors);
            })
        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnCombosOnChange = async (comboName, comboVal) => {
        try {
            setIsLoading(true);

            switch (comboName) {
                case 'AttDateFrom':
                    const formattedFromDate = comboVal ? comboVal.toLocaleDateString('en-CA') : '';
                    if (formattedFromDate !== '') { document.getElementById('error_dt_att_from').style.display = 'none'; }
                    setAttFrom(formattedFromDate);
                    // If the start date is set after the end date, clear the end date
                    if (dt_att_to && new Date(formattedFromDate) > new Date(dt_att_to)) {
                        setAttTo('');
                    }
                    break;

                case 'AttDateTo':
                    const formattedToDate = comboVal ? comboVal.toLocaleDateString('en-CA') : '';
                    if (formattedToDate !== '') { document.getElementById('error_dt_att_to').style.display = 'none'; }
                    setAttTo(formattedToDate)
                    break;

                case "Department":
                    setEmployeeCode('');
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    // await FnLoadEmployees();
                    await FnSetDefaultData();
                    break;

                case "SubDepartment":
                    setEmployeeCode('');
                    // await FnLoadEmployees();
                    await FnSetDefaultData();
                    break;
                case 'Contractor':
                    await FnSetDefaultData();
                    break;
                case "EmployeeType":
                    setEmployeeCode('');
                    // await FnLoadEmployees();
                    await FnSetDefaultData();
                    break;
                case 'EmployeeCode':
                    debugger
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);
                    let findEmployee = null;

                    if (serachemployeeCode.length >= 2) {
                        findEmployee = employeeOpts.find((employee) => {
                            return employee.employee_code === serachemployeeCode
                                || employee?.label?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                || employee?.old_employee_code === serachemployeeCode
                            // || employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.employee_id);
                        employeeComboRef.current = {
                            employee_id: findEmployee.employee_id,
                            employee_code: findEmployee.employee_code,
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                        };
                    }
                    break;
                case 'EmplCode':
                    // setEmployeeCode(employeeComboRef.current.employee_code);
                    if (employeeComboRef.current && employeeComboRef.current.employee_code) {
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    }

                    break;
                case 'Employee':
                    debugger
                    var employeeID = employeeComboRef.current?.employee_id;
                    if (employeeID !== "") {
                        // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeComboRef.current.employee_id);
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    } else {
                        setEmployeeCode('');
                    }

                default:
                    break;
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }


    const FnSetDefaultData = async () => {
        debugger
        try {
            // Filters for load employee query.
            let filterConditions = [
                { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "is_delete", operator: "=", value: 0 },
            ];
            // let contractorId = cmb_contractor_id_ref.current.value;

            // First Set Default Department.
            let selectedDepartment = departmentComboRef.current?.department_id;
            if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
            } else if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
                let defaultDept = departmentOpts.find(department => department.value == 'All');
                setDepartmentId(defaultDept.value);
                departmentComboRef.current = defaultDept;

                // Also Load All Sub-Department
                const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
                selectedDepartment = '';
            }

            // Second Set Default Sub-Department.
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
            } else {
                const subDepartmentList = allDepartmentsList.filter(department =>
                    (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                );
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
            }

            // Third Set Default Employee Type.
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                filterConditions.push({ field: "employee_type_group", operator: "=", value: selectedEmplType });
            } else {
                let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                setEmployeeTypeId(defaultEmplType.value);
                employeeTypesComboRef.current = defaultEmplType;
                let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                    ?.map(item => item?.field_name)
                    ?.filter(id => id !== undefined && id !== null && id !== '');;
                filterConditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
            }

            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
            globalQuery.table = "cmv_employee"
            if (DEPARTMENT_ID != 11) {
                filterConditions.push({ field: "reporting_to", operator: "=", value: UserId });
            }
            // if (contractorId !== undefined && contractorId !== null && contractorId !== '') {
            //     filterConditions.push({ field: "contractor_id", operator: "=", value: contractorId });
            // }
            globalQuery.conditions = filterConditions;
            let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // if (getEmployeeList.length > 0) {
            getEmployeeList = getEmployeeList?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
            getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
            // setEmployeeOpts(getEmployeeList);
            const filteredUserDetails = userDetails.filter(user =>
                !getEmployeeList.some(emp => emp.employee_id === user.employee_id)
            );

            // Add userDetails to the employee options without duplicates
            const mergedEmployeeList = [
                ...getEmployeeList,
                ...filteredUserDetails.map(user => ({
                    ...user,
                    value: user.employee_id, // Assuming 'id' exists in userDetails
                    label: user.employee_name // Assuming 'name' exists in userDetails
                }))
            ];
            if (mergedEmployeeList.length > 0) {
                setEmployeeOpts(mergedEmployeeList);
                // if (DEPARTMENT_ID === 11) {
                setEmployeeId('All');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
                // } else {
                //     setEmployeeId(UserId);
                //     setEmployeeCode(USER_CODE);
                //     employeeComboRef.current = { employee_id: UserId, value: UserId, label: 'All' };
                // }

            } else {
                setEmployeeOpts([]);
                setEmployeeId('');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // ****-------Get Attendance Data Functions Starts--------------***
    const FnShowAttendanceData = async () => {
        debugger
        try {
            let isValidFilters = await FnChkIsValidFilters();
            if (!isValidFilters) { return false; }

            // *** Means Filters are valid then filter data as per the filters.
            setIsLoading(true);
            let filterConditions = [
                { field: "htatdl.att_date_time", operator: "=", value: dt_att_from },
                // { field: "htatdl.att_date_time", operator: "<=", value: dt_att_to },
                { field: "htatdl.company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "htatdl.is_delete", operator: "=", value: 0 },
            ];

            // *** Apply Filters Conditions;
            let selectedDepartment = departmentComboRef.current?.department_id;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            let selectedEmployee = employeeComboRef.current.employee_id;
            // let contractorId = cmb_contractor_id_ref.current.value;

            // Check it selected one employee or not? 
            if (selectedEmployee !== undefined && selectedEmployee !== null && selectedEmployee !== '') {
                filterConditions.push({ field: "htatdl.employee_id", operator: "=", value: selectedEmployee });
                // filterConditions.push({ field: "empl.reporting_to", operator: "=", value: UserId });
            } else {

                await FnSetDefaultData();


                if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                    filterConditions.push({ field: "empl.department_id", operator: "=", value: selectedDepartment });
                }
                // if (selectedEmplType === 'Workers' && contractorId !== undefined && contractorId !== null && contractorId !== '') {
                //     filterConditions.push({ field: "empl.contractor_id", operator: "=", value: contractorId });
                // }

                if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                    filterConditions.push({ field: "empl.sub_department_id", operator: "=", value: selectedSubDepartment });
                }

                if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                    filterConditions.push({ field: "empl.employee_type_group", operator: "=", value: selectedEmplType });
                } else {
                    let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                    setEmployeeTypeId(defaultEmplType.value);
                    employeeTypesComboRef.current = defaultEmplType;
                    let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                    filterConditions.push({ field: "empl.employee_type_group", operator: "IN", values: emplTypes });
                }
            }

            resetGlobalQuery();
            globalQuery.columns = [
                'empl.employee_name', 'empl.department_name', 'empl.sub_department_name', 'htatdl.attendance_date', 'empl.skill_type',
                'htatdl.attendance_month', 'htatdl.attendance_year', 'htatdl.shift_scheduled', 'htatdl.job_type_id',
                'htatdl.job_type', 'htatdl.job_type_code_short_name', 'htatdl.att_date_time',
                'dpt.department_req_std_worker_strength', 'dpt.department_std_worker_strength',
                'dpt.general_worker', 'dpt.trainee_worker', 'dpt.semi_skilled_worker', 'dpt.skilled_worker', 'dpt.sr_semi_skilled_worker', 'dpt.helper_worker'
            ];
            globalQuery.table = "ht_attendance_daily htatdl";
            globalQuery.conditions = filterConditions;
            globalQuery.joins = [
                {
                    "table": `cmv_employee empl`,
                    "type": "LEFT",
                    "on": [
                        { "left": "empl.employee_id", "right": "htatdl.employee_id", },
                        { "left": "empl.company_id", "right": "htatdl.company_id", },
                    ]
                },
                {
                    "table": `cm_department dpt`,
                    "type": "LEFT",
                    "on": [
                        { "left": "empl.sub_department_id", "right": "dpt.department_id", },

                    ]
                },
            ]

            globalQuery.orderBy = ['empl.department_id DESC', 'empl.sub_department_id DESC', 'empl.employee_code Asc', 'htatdl.att_date_time'];

            let getAttendanceData = await comboDataFunc.current.fillFiltersCombo(globalQuery);

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_name", "property_value", "properties_master_name"];
            globalQuery.table = "amv_properties";
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['SkillLevels'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

            let skillTypes = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setAllSkillTypes(skillTypes);


            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "hm_job_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let jobTypesRate = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setAllJObTypesAndRate(jobTypesRate);

            if (getAttendanceData.length > 0) {
                let departmentSummary = getAttendanceData.reduce((acc, record) => {
                    const { department_name, sub_department_name, skill_type } = record;

                    if (!acc[department_name]) {
                        acc[department_name] = {};
                    }
                    if (!acc[department_name][sub_department_name]) {
                        acc[department_name][sub_department_name] = {
                            total: 0,
                            salary: 0, // Initialize salary for the sub-department
                            department_req_std_worker_strength: record.department_req_std_worker_strength,
                            department_std_worker_strength: record.department_std_worker_strength,
                            trainee_worker: record.trainee_worker,
                            general_worker: record.general_worker,
                            semi_skilled_worker: record.semi_skilled_worker,
                            skilled_worker: record.skilled_worker,
                            sr_semi_skilled_worker: record.sr_semi_skilled_worker,
                            helper_worker: record.helper_worker,
                        };

                        // Initialize each dynamic skill type with 0 count
                        skillTypes.forEach(skill => {
                            acc[department_name][sub_department_name][skill.property_name] = 0;
                        });
                    }

                    // Update the count for each skill type dynamically
                    if (skillTypes.some(type => type.property_name === skill_type)) {
                        acc[department_name][sub_department_name][skill_type] += 1;
                        acc[department_name][sub_department_name].total += 1;
                    }

                    return acc;
                }, {});

                // Calculate salary based on skill type and job type rate for each sub-department
                Object.entries(departmentSummary).forEach(([departmentName, subDepartments]) => {
                    Object.entries(subDepartments).forEach(([subDepartmentName, counts]) => {
                        jobTypesRate.forEach(job => {
                            // Match the department, sub_department, and skill_type
                            if (job.department_name === departmentName &&
                                job.sub_department_name === subDepartmentName &&
                                counts[job.skill_type] !== undefined) {

                                // Calculate salary by multiplying skill type count with job type rate
                                counts.salary += counts[job.skill_type] * job.job_type_rate;
                            }
                        });
                    });
                });

                // Convert departmentSummary to an array for easier handling
                let summarizedData = [];
                Object.entries(departmentSummary).forEach(([departmentName, subDepartments]) => {
                    let departmentSubtotal = {
                        department_name: departmentName,
                        sub_department_name: "Subtotal",
                        total_count: 0,
                        salary: 0, // Initialize subtotal salary
                        department_req_std_worker_strength: 0,
                        department_std_worker_strength: 0,
                        trainee_worker: 0,
                        general_worker: 0,
                        semi_skilled_worker: 0,
                        skilled_worker: 0,
                        sr_semi_skilled_worker: 0,
                        helper_worker: 0,
                    };

                    skillTypes.forEach(skill => {
                        departmentSubtotal[skill.property_name] = 0;
                    });

                    Object.entries(subDepartments).forEach(([subDepartmentName, counts]) => {
                        let row = {
                            department_name: departmentName,
                            sub_department_name: subDepartmentName,
                            total_count: counts.total,
                            salary: counts.salary, // Include calculated salary
                            department_req_std_worker_strength: counts.department_req_std_worker_strength,
                            department_std_worker_strength: counts.department_std_worker_strength,
                            trainee_worker: counts.trainee_worker,
                            general_worker: counts.general_worker,
                            semi_skilled_worker: counts.semi_skilled_worker,
                            skilled_worker: counts.skilled_worker,
                            sr_semi_skilled_worker: counts.sr_semi_skilled_worker,
                            helper_worker: counts.helper_worker,
                        };

                        skillTypes.forEach(skill => {
                            row[skill.property_name] = counts[skill.property_name];
                            departmentSubtotal[skill.property_name] += counts[skill.property_name];
                        });

                        summarizedData.push(row);

                        departmentSubtotal.total_count += counts.total;
                        departmentSubtotal.salary += counts.salary; // Add to subtotal salary
                        departmentSubtotal.department_req_std_worker_strength += counts.department_req_std_worker_strength;
                        departmentSubtotal.department_std_worker_strength += counts.department_std_worker_strength;
                        departmentSubtotal.trainee_worker += counts.trainee_worker;
                        departmentSubtotal.general_worker += counts.general_worker;
                        departmentSubtotal.semi_skilled_worker += counts.semi_skilled_worker;
                        departmentSubtotal.skilled_worker += counts.skilled_worker;
                        departmentSubtotal.sr_semi_skilled_worker += counts.sr_semi_skilled_worker;
                        departmentSubtotal.helper_worker += counts.helper_worker;
                    });

                    summarizedData.push(departmentSubtotal); // Add subtotal row for the department
                });

                setAttendanceDtlTblData(summarizedData);
                console.table(summarizedData);
            } else {
                setAttendanceDtlTblData([]);
            }

            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error');
        }
    };
    const alldptSkillTypes = [
        { property_name: "General" },
        { property_name: "Trainee" },
        { property_name: "Semi Skilled" },
        { property_name: "Skilled" },
        { property_name: "Sr Semi Skilled" },
        { property_name: "Helper" }
    ];


    const FnRenderAttendanceTbl = useMemo(() => {
        if (attendanceDtlTblData.length === 0) { return null; }

        return (
            <>
                <style>
                    {`
                        @page { 
                            size: legal landscape;
                            margin: 3%;
                        }
                        .erp_table {
                            font-size: 3px !important; /* Default font size */
                        }
                        .erp_table_th, .erp_table_td {
                            border: 1px solid black;
                            padding-top: 2px !important;
                            padding-bottom: 2px !important;
                        }
                    `}
                </style>
                {/* <style>
                    {`
                    @media print {
                        .print-content {
                            padding: 0;
                            box-sizing: border-box;
                        }
                        
                             @page { 
                            size: legal landscape;
                            margin: 1%;
                        }
                        html, body {
                            width: 100%;
                            height: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .erp_table {
                        font-size: 3px !important;
                    }
                    .erp_table_th, .erp_table_td {
                        border: 1px solid black;
                        padding-top: 2px !important;
                        padding-bottom: 2px !important;
                    }
                `}
                </style> */}
                <Table className="erp_table" id="MonthlySalaryDtlsTbl" style={{ whiteSpace: 'normal' }} ref={printComponentRef}>
                    <thead className="erp_table_head thead-light" style={{ whiteSpace: 'normal' }}>
                        <tr>
                            <th className="erp_table_th text-center" rowSpan="2">DEPARTMENT</th>
                            <th className="erp_table_th text-center" colSpan="2">On Roll Position</th>
                            <th className="erp_table_th text-center" colSpan={allSkillTypes.length}>Should Be</th>
                            <th className="erp_table_th text-center" colSpan={allSkillTypes.length}>Present</th>
                            <th className="erp_table_th text-center" colSpan={allSkillTypes.length}>Variation</th>
                        </tr>
                        <tr>
                            <th className="erp_table_th">SKILLED</th>
                            <th className="erp_table_th">TRAINEE</th>
                            {allSkillTypes.map(skill => (
                                <th key={`should-be-${skill.property_name}`} className="erp_table_th">{skill.property_name}</th>
                            ))}
                            {allSkillTypes.map(skill => (
                                <th key={`present-${skill.property_name}`} className="erp_table_th">{skill.property_name}</th>
                            ))}
                            {allSkillTypes.map(skill => (
                                <th key={`variation-${skill.property_name}`} className="erp_table_th">{skill.property_name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceDtlTblData.map((record, index) => {
                            const isFirstRowOfDepartment = index === 0 || attendanceDtlTblData[index - 1].department_name !== record.department_name;

                            return (
                                <>
                                    {isFirstRowOfDepartment && (
                                        <tr key={`dept-${record.department_name}-${index}`}>
                                            <td className="erp_table_td" colSpan={allSkillTypes.length * 3 + 3} style={{ fontWeight: 'bold' }}>
                                                {record.department_name}
                                            </td>
                                        </tr>
                                    )}
                                    <tr className={record.sub_department_name === "Subtotal" ? "subtotal-row" : ""} key={`record-${index}`}>
                                        <td className="erp_table_td">{record.sub_department_name}</td>
                                        <td className="erp_table_td"></td>
                                        <td className="erp_table_td"></td>

                                        {allSkillTypes.map(skill => {
                                            // let actualId = `${skill.property_name.toLowerCase().replace(' ', '_')}_worker`
                                            let actualId = `${skill.property_name.toLowerCase().replace(/ /g, '_')}_worker`;

                                            // const actualValue = record[`${skill.property_name.toLowerCase().replace(' ', '_')}_worker`] || 0;
                                            const actualValue = record[actualId] || 0;
                                            return (
                                                <td className="erp_table_td" key={`worker-${skill.property_name}-${index}`}>
                                                    {actualValue}
                                                </td>
                                            );
                                        })}

                                        {/* Render counts from allSkillTypes and calculate variation */}
                                        {allSkillTypes.map(skill => {
                                            const shouldBeValue = record[skill.property_name] || 0;

                                            return (
                                                <td className="erp_table_td" key={`present-${skill.property_name}-${index}`}>
                                                    {shouldBeValue}
                                                </td>
                                            );
                                        })}

                                        {/* Calculate and render variation between alldptSkillTypes and allSkillTypes */}
                                        {allSkillTypes.map((skill, skillIndex) => {
                                            const actualValue = record[`${skill.property_name.toLowerCase().replace(/ /g, '_')}_worker`] || 0;
                                            const shouldBeValue = record[allSkillTypes[skillIndex]?.property_name] || 0; // Access corresponding allSkillTypes value

                                            return (
                                                <td key={`variation-${skill.property_name}-${index}`} className="erp_table_td">
                                                    {actualValue - shouldBeValue} {/* Calculate variation */}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </>
                            );
                        })}

<tr style={{ fontWeight: 'bold' }}>
    <td className="erp_table_td" style={{ fontWeight: 'bold' }}>Grand Total</td>

    <td className="erp_table_td"></td>
    <td className="erp_table_td"></td>

    {/* Calculate Grand Totals for On Roll (Should Be) */}
    {allSkillTypes.map(skill => {
        const grandTotalOnRoll = attendanceDtlTblData
        .filter(record => record.sub_department_name === "Subtotal")
        .reduce((acc, record) => {
            
            const onRollValue = record[`${skill.property_name.toLowerCase().replace(/ /g, '_')}_worker`] || 0;
            return acc + onRollValue;
        }, 0);
        return (
            <td key={`grand-onroll-${skill.property_name}`} className="erp_table_td">
                {grandTotalOnRoll}
            </td>
        );
    })}

    {/* Calculate Grand Totals for Present */}
    {allSkillTypes.map(skill => {
        const grandTotalPresent = attendanceDtlTblData
        .filter(record => record.sub_department_name === "Subtotal")
        .reduce((acc, record) => {
            const presentValue = record[skill.property_name] || 0;
            return acc + presentValue;
        }, 0);
        return (
            <td key={`grand-present-${skill.property_name}`} className="erp_table_td">
                {grandTotalPresent}
            </td>
        );
    })}

    {/* Calculate Variation for Grand Total (On Roll - Present) */}
    {allSkillTypes.map(skill => {
        const grandTotalOnRoll = attendanceDtlTblData
        .filter(record => record.sub_department_name === "Subtotal")
        .reduce((acc, record) => {
            const onRollValue = record[`${skill.property_name.toLowerCase().replace(/ /g, '_')}_worker`] || 0;
            return acc + onRollValue;
        }, 0);
        const grandTotalPresent = attendanceDtlTblData
        .filter(record => record.sub_department_name === "Subtotal")
        .reduce((acc, record) => {
            const presentValue = record[skill.property_name] || 0;
            return acc + presentValue;
        }, 0);
        
        // Calculate the variation (On Roll - Present)
        const grandTotalVariation = grandTotalOnRoll - grandTotalPresent;

        return (
            <td key={`grand-variation-${skill.property_name}`} className="erp_table_td">
                {grandTotalVariation}
            </td>
        );
    })}
</tr>

                    </tbody>
                </Table>


            </>
        )
    }, [attendanceDtlTblData])

    // ***----------- Helping Functions.
    const goBack = () => {
        navigate("/Dashboard")
    }

    // Get Current DateTime
    const FnGetCurrentDateTime = () => {
        // Get the current date and time
        let currentDate = new Date();
        // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }

    // ****-------Validation Functions Starts--------------***
    // Function for validate the fields at the time of button click;
    const FnChkIsValidFilters = async () => {
        if (dt_att_from === '') {
            $('#error_dt_att_from').text('Please select the attendance from date...!')
            $('#error_dt_att_from').show();
            return false;
        } else {
            $('#error_dt_att_from').hide();
        }
        if (employeeTypesComboRef.current?.label == null || employeeTypesComboRef.current.label === '') {
            $('#error_cmb_employee_type_id').text('Please select Employee Type...!')
            $('#error_cmb_employee_type_id').show();
            return false;
        } else {
            $('#error_cmb_employee_type_id').hide();
        }


        return true;
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {
        setAttendanceDtlTblData([]);
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(dt_att_to)) { $('#error_dt_att_to').hide(); }
        if (isEmpty(dt_att_from)) { $('#error_dt_att_from').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    // ****-------Validation Functions Ends--------------***


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintAttendanceSheet = useReactToPrint({
        content: () => {
            debugger
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: "Daily ManPower Register"
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1 border-bottom border-dark'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Daily ManPower Register
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <dl className="row pb-0 mb-0 px-1">
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}> Date:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: "12px" }}> {validateNumberDateInput.current.formatDateToDDMMYYYY(dt_att_from)}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>Employee Type:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: "12px" }}> {employeeTypesComboRef.current?.label || ""}</dd>
                            </dl>
                        </div>
                    </dl>
                </div>
            </>
        );
    };
 
const exportToExcelWithMergedHeaders = async () => {
    try {
        //setIsLoading(true);
        debugger
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Production Report');

        // Define the main headers
        const mainHeaders = ['Department', 'OnRoll', 'OnRoll Present', 'Variation'];

        // Define sub-headers from allSkillTypes for each of the main headers
        const subHeaders = allSkillTypes.map(skill => skill.property_name);

        // Initialize grand totals to 0
        const grandTotals = {
            onRoll: Array(subHeaders.length).fill(0),
            onRollPresent: Array(subHeaders.length).fill(0),
            variation: Array(subHeaders.length).fill(0),
        };

        // Generate the rows of headers and company details
        const dataToExport = [
            [COMPANY_NAME],  // Row for Company Name
            [COMPANY_ADDRESS],  // Row for Company Address
            [],  // First blank row
            [],  // Second blank row
            ['Department', ...Array(subHeaders.length).fill('OnRoll'), ...Array(subHeaders.length).fill('OnRoll Present'), ...Array(subHeaders.length).fill('Variation')],  // Main headers
            ['', ...subHeaders, ...subHeaders, ...subHeaders]  // Sub-headers
        ];

        // Add the headers and sub-headers to the worksheet
        dataToExport.forEach((row, index) => {
            worksheet.addRow(row);
        });

        // Group the data by department and sub-departments
        const departmentMap = new Map();

        attendanceDtlTblData.forEach(record => {
            
            const departmentName = record.department_name;
            const subDepartmentName = record.sub_department_name || '';  // Handle missing sub-department names

            if (!departmentMap.has(departmentName)) {
                departmentMap.set(departmentName, []);
            }

            departmentMap.get(departmentName).push({
                ...record,
                combinedName: subDepartmentName ? `  ${subDepartmentName}` : departmentName,  // Indent sub-department names for readability
            });
        });
        const GrandTotalbackgroundColor = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD3D3D3' } // Light gray color in ARGB format
        };

        // Populate the data rows with department and sub-department data
        departmentMap.forEach((records, departmentName) => {
            // Main department row
            const mainDepartmentRow = [
                departmentName,
                ...subHeaders.map(() => ''),
                ...subHeaders.map(() => ''),
                ...subHeaders.map(() => '')
            ];
            worksheet.addRow(mainDepartmentRow);

            // Subtotals for each main department
            const departmentTotals = {
                
                onRoll: Array(subHeaders.length).fill(0),
                onRollPresent: Array(subHeaders.length).fill(0),
                variation: Array(subHeaders.length).fill(0),
            };
           
            //let grandTotals;
            // Add rows for sub-departments under the main department
            records.forEach(record => {
                // debugger
                if (record.combinedName.trim() === "Subtotal") {
                    grandTotals.onRoll = grandTotals.onRoll.map((total, i) => total + departmentTotals.onRoll[i]);
                    grandTotals.onRollPresent = grandTotals.onRollPresent.map((total, i) => total + departmentTotals.onRollPresent[i]);
                    grandTotals.variation = grandTotals.onRoll.map((onRoll, i) => onRoll - grandTotals.onRollPresent[i]);
                } 
                
                
                const rowData = [
                    record.combinedName,
                    ...subHeaders.map((skill, index) => {
                        const onRoll = record[`${skill.toLowerCase().replace(/ /g, '_')}_worker`] || 0;
                        departmentTotals.onRoll[index] += onRoll;
                        return onRoll;
                    }),
                    ...subHeaders.map((skill, index) => {
                        const onRollPresent = record[skill] || 0;
                        departmentTotals.onRollPresent[index] += onRollPresent;
                        return onRollPresent;
                    }),
                    ...subHeaders.map((skill, index) => {
                        const variation = (record[`${skill.toLowerCase().replace(/ /g, '_')}_worker`] || 0) - (record[skill] || 0);
                        departmentTotals.variation[index] += variation;
                        return variation;
                    })
                ];
                worksheet.addRow(rowData);
            });
        });

        // Add Grand Total row at the end
        const grandTotalRow = [
            'Grand Total',
            ...grandTotals.onRoll,
            ...grandTotals.onRollPresent,
            ...grandTotals.variation
        ];
        worksheet.addRow(grandTotalRow);
        const grandTotalRowSelected = worksheet.getRow(worksheet.rowCount); // Get the last added row

            // Apply bold and light background to the "Grand Total" row
            grandTotalRowSelected.eachCell((cell) => {
                cell.font = { bold: true }; // Apply bold font
                cell.alignment = { horizontal: 'center', vertical: 'right' }; 
                cell.fill = GrandTotalbackgroundColor; // Apply light background color
            });
                    

       
        // Merge cells for the company name and address rows
        worksheet.mergeCells('A1', `S1`);  // Merge company name row
        worksheet.mergeCells('A2', `S2`);  // Merge company address row

        // Merge the headers
        const headerRow = 5;  // Row index of main headers
        worksheet.mergeCells(headerRow, 2, headerRow, subHeaders.length + 1);  // Merge OnRoll columns
        worksheet.mergeCells(headerRow, subHeaders.length + 2, headerRow, subHeaders.length * 2 + 1);  // Merge OnRoll Present columns
        worksheet.mergeCells(headerRow, subHeaders.length * 2 + 2, headerRow, subHeaders.length * 3 + 1);  // Merge Variation columns

        // Apply styles to the headers
        const headerStyle = { font: { bold: true, size: 14 }, alignment: { horizontal: 'center', vertical: 'middle' } };
        const companyStyle = { font: { bold: true, size: 16 }, alignment: { horizontal: 'center', vertical: 'middle' } };

        // Apply styles to company name and address
        ['A1', 'A2'].forEach(cellRef => {
            const cell = worksheet.getCell(cellRef);
            cell.style = companyStyle;
        });

        // Apply styles to headers
        worksheet.getRow(5).eachCell((cell) => {
            cell.style = headerStyle;
        });
        for (let col = 1; col <= worksheet.columnCount; col++) {
            if(col === 1){
                worksheet.getColumn(1).width = 20;
            }else{
            worksheet.getColumn(col).width = 10;
            }
        }


        // Apply styles to sub-headers
        worksheet.getRow(6).eachCell((cell) => {
            cell.style = { font: { bold: true, size: 12 }, alignment: { horizontal: 'center', vertical: 'middle' } };
        });

      

        // Save and download the file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Daily_Production_Report_${new Date().toISOString().split('T')[0]}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsLoading(false);
    } catch (error) {
        console.error('Error exporting Excel file:', error);
        navigate('/Error');
    }
};





 
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Daily ManPower Register</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> From Date </Form.Label> </div>
                                    <div className='col-sm-6'>
                                        <DatePicker
                                            selected={dt_att_from}
                                            id="dt_att_from"
                                            onChange={(date) => { FnCombosOnChange('AttDateFrom', date); FnValidateFields(); }}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional"
                                            selectsStart
                                            startDate={dt_att_from ? new Date(dt_att_from) : null}
                                            endDate={dt_att_to ? new Date(dt_att_to) : null}
                                            maxDate={new Date()}
                                        />
                                        <MDTypography variant="button" id="error_dt_att_from" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-2"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col-sm-3'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col-sm-2"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col-sm-3'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("SubDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnCombosOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center me-1`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                <MDButton type="button" id="show-data-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnShowAttendanceData(); }}
                                >Show Data</MDButton>
                            </div>
                        </div>

                        {/* Attendance Details Table */}
                        {
                            attendanceDtlTblData.length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <> <hr />

                                    <div className="row py-1">
                                        <div className="text-end">
                                            {/* <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }} >Export Excel</MDButton> */}
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { exportToExcelWithMergedHeaders(); }} >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { FnPrintAttendanceSheet(); }} >Print Attendance</MDButton>
                                        </div>
                                    </div>

                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            <div className="card-header py-0 main_heding mb-0">
                                                <label className="erp-form-label-md-lg">Employee's ManPower Details</label>
                                            </div>
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                {FnRenderAttendanceTbl}
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                {
                                    attendanceDtlTblData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintAttendanceSheet(); }} >Print Attendance Report</MDButton>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}

                </div>
                {/* Card Ends*/}

            </DashboardLayout>
        </>
    )
}
export default DailyManPowerRegister;




