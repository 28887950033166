import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';
import ExcelJS from 'exceljs';
// import fs from 'fs';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import { red } from "@material-ui/core/colors";

function SalaryBankListRegister() {

  // Some Static Hardcoded Values for the Banks; Show the only particular banks. (Told by prashant sir on whats'app on 24/07/2024)
  // For Pashupati Cott Spinn Ltd Show only Punjab banks and Other Transfer                   
  // const pnbBankIdSpinnig = 10;  // * company_BasedID id -> 10
  // const cashBankIdSpinning = 1;

  // For Pashupati Text Spinn Export LLP Show only  Axis Banks And Other  and Other Transfer  
  // const axisBankIDTexSpin = 49; // * company_BasedID id -> 49 
  // const cashBankIdTexSpin = 37;

  // For Pashupati Ginning Show only only Punjab banks and Other Transfer                     
  // const pnbBankIdGinning = 68;  // * company_BasedID id -> 68
  // const cashBankIdGinning = 59;

  // This companyIds Are company 1 based only; because Prashant Sir updated the employee's data.
  const pnbBankId = 10;
  const axisBankId = 13;
  const cashBankId = 1;

  // Global Constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

  // UseRefs Hooks.
  const comboDataFunc = useRef();
  const exlsExp = useRef();

  // Ref Hooks For Print-Out.    
  const printComponentRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);

  // ****** navigate
  const navigate = useNavigate();

  const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
  const [monthOpts, setMonthOpts] = useState([
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ]);
  const [yearOpts, setYearOpts] = useState(
    Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
      .map((year) => ({ label: year, value: year }))
  );
  const [employeeOpts, setEmployeeOpts] = useState([]);
  const [salaryBankOpts, setSalaryBankOpts] = useState([]);
  const [companyBankDtl, setCompanyBankDtl] = useState([])
  // const [companyBankDtl, setCompanyBankDtl] = useState({
  //   company_branch_bank_name: '',
  //   company_branch_bank_branch_name: '',
  //   company_branch_bank_account_no: '',
  //   company_branch_bank_ifsc_code: '',
  //   bank_id: '',
  // });

  // Input field's Hooks;
  const [cmb_salary_month, setSalaryMonth] = useState('');
  const [cmb_salary_year, setSalaryYear] = useState('');
  const [cmb_salary_bank_id, setSalaryBankId] = useState(null);
  const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
  const [cmb_employee_id, setEmployeeId] = useState('');
  const [cmb_company_bank_id, setCompanybankId] = useState(null);

  const [employeeSalaryDetails, setEmployeeSalaryDetails] = useState([]);
  const [departmentOpts, setDepartmentOpts] = useState([]);
  const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
  const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.

  const [cmb_department_id, setDepartmentId] = useState('');
  const [cmb_sub_department_id, setSubDepartmentId] = useState('');
  // UseRefs Hooks For Searchable combos.
  let salaryMonthComboRef = useRef(null);
  let salaryYearComboRef = useRef(null);
  let salaryBankComboRef = useRef(null);
  let employeeTypesComboRef = useRef(null);
  let employeeComboRef = useRef(null);
  let salaryCompanyBankComboRef = useRef(null);
  let departmentComboRef = useRef(null);
  let subDepartmentComboRef = useRef(null);

  // Helping hooks;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadDataOnload = async () => {
      setIsLoading(true);
      await FnFillCombos();
      setIsLoading(false);
    }
    loadDataOnload();
  }, []);

  const FnFillCombos = async () => {
    debugger
    try {
      // Set Default Year.
      let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
      setSalaryYear(defaultYear.value);
      salaryYearComboRef.current = defaultYear;
      // Set Default Month.
      let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
      setSalaryMonth(defaultMonth.value);
      salaryMonthComboRef.current = defaultMonth;


      // Load employee types
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
      globalQuery.table = "amv_properties"
      globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdEmplTypes => {
          // setEmployeeProps(rcvdEmplTypes);
          let emplTypes = rcvdEmplTypes
            .filter(prop => prop.properties_master_name === 'EmployeeType')
            .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
          emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
          setEmployeeTypesOpts(emplTypes);
        });

      // Load Banks
      resetGlobalQuery();
      globalQuery.columns = ["bank_id", "bank_name"];
      globalQuery.table = "cm_banks_List"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // const rcvdBanks = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      // setSalaryBankOpts(rcvdBanks);
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdBanks => {
          rcvdBanks = rcvdBanks
            ?.map(prop => ({ ...prop, value: prop.bank_id, label: prop.bank_name }));

          // Show the only some banks in bank combo;
          // if (parseInt(COMPANY_ID) === 1 || parseInt(COMPANY_ID) === 3) {
          //   rcvdBanks = rcvdBanks.filter(prop => [pnbBankId, cashBankId].includes(prop.bank_id))
          // } else if (parseInt(COMPANY_ID) === 2) {
          //   rcvdBanks = rcvdBanks.filter(prop => [axisBankId, cashBankId].includes(prop.bank_id))
          // }
          rcvdBanks.push({ bank_id: 0, value: 0, label: "Others", bank_name: "Others" })
          setSalaryBankOpts(rcvdBanks);
        });

      // Load Current Company Bank Details. from cmv_company_banks
      resetGlobalQuery();
      globalQuery.columns = ["company_branch_bank_name", "company_branch_bank_branch_name", "company_branch_bank_account_no", "company_branch_bank_ifsc_code", "bank_id"];
      globalQuery.table = "cmv_company_banks"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdBankDtls => {
          rcvdBankDtls = rcvdBankDtls
            ?.map(prop => ({ ...prop, value: prop.bank_id, label: prop.company_branch_bank_name }));

          rcvdBankDtls.push({ bank_id: 0, value: 0, label: "Others", company_branch_bank_name: "Others" })
          setCompanyBankDtl(rcvdBankDtls);
        });
      // comboDataFunc.current.fillFiltersCombo(globalQuery)
      //   .then(rcvdBankDtls => {
      //     // console.log('rcvdBankDtls: ', rcvdBankDtls)
      //     let debitACDtl = null;
      //     if (parseInt(COMPANY_ID) === 1 || parseInt(COMPANY_ID) === 3) {
      //       debitACDtl = rcvdBankDtls.find(prop => pnbBankId === prop.bank_id) || null;
      //     } else if (parseInt(COMPANY_ID) === 2) {
      //       debitACDtl = rcvdBankDtls.find(  prop => axisBankId === prop.bank_id) || null;
      //     }
      //     console.log('currentBankDtl: ', debitACDtl)

      //     setCompanyBankDtl(
      //       debitACDtl !== null
      //         ? debitACDtl
      //         : {
      //           company_branch_bank_name: '',
      //           company_branch_bank_branch_name: '',
      //           company_branch_bank_account_no: '',
      //           company_branch_bank_ifsc_code: '',
      //           bank_id: '',
      //         }
      //     );

      //   });

      // Load Department & Sub-Department
      resetGlobalQuery();
      globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
      globalQuery.table = "cm_department"
      globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdDepts => {
          const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
            if (department.department_type === "M") {
              acc.mainDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            } else if (department.department_type === "S") {
              acc.subDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            }
            return acc;
          }, { mainDepartments: [], subDepartments: [] });
          setAllDepartmentsList([...mainDepartments, ...subDepartments]);

          mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
          setDepartmentOpts(mainDepartments);
        });

    } catch (error) {
      console.log("error on filling combos: ", error)
      navigate('/Error')
    }
  }

  const FnComboOnChange = async (comboName) => {
    try {
      switch (comboName) {
        case "EmployeeType":
          await FnSetDefaultData();
          break;
        case "Bank":
          await FnSetDefaultData();
          break;
        case "Department":

          let selectedDepartment = departmentComboRef.current.department_id;
          const subDepartmentList = allDepartmentsList.filter(department =>
            (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
          );
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          // remove the already selected sub-department
          subDepartmentComboRef.current = null;
          setSubDepartmentId('');
          // await FnLoadEmployees();
          await FnSetDefaultData();
          break;

        case "SubDepartment":
          await FnSetDefaultData();
          break;

        default:
          break;
      }

    } catch (error) {
      console.log("error on combos change: ", error)
      navigate('/Error')
    }
  }

  const FnSetDefaultData = async () => {
    try {
      // Filters for load employee query.
      let filterConditions = [
        { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
        { field: "is_delete", operator: "=", value: 0 },
      ];

      let selectedSalaryBank = salaryBankComboRef.current?.bank_id;

      // First Set Default Department.
      let selectedDepartment = departmentComboRef.current?.department_id;
      if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
        filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
      } else if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
        let defaultDept = departmentOpts.find(department => department.value == 'All');
        setDepartmentId(defaultDept.value);
        departmentComboRef.current = defaultDept;

        // Also Load All Sub-Department
        const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
        subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
        setSubDepartmentOpts(subDepartmentList);
        subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
        setSubDepartmentId('All');
        selectedDepartment = '';
      }

      // Second Set Default Sub-Department.
      let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
      if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
        filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
      } else {
        const subDepartmentList = allDepartmentsList.filter(department =>
          (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
        );
        subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
        setSubDepartmentOpts(subDepartmentList);
        subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
        setSubDepartmentId('All');
      }
      // Set Default Employee Type.
      let selectedEmplType = employeeTypesComboRef.current.field_name;
      if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
        filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
      } else {
        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All' || employee.field_name == 'All');
        setEmployeeTypeId(defaultEmplType.value);
        employeeTypesComboRef.current = defaultEmplType;
        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
          ?.map(item => item?.field_name)
          ?.filter(id => id !== undefined && id !== null && id !== '');;
        filterConditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
      }
      if (selectedSalaryBank !== 0) { // Means selected the other bank;
        filterConditions.push({ field: "bank_id1", operator: "=", value: selectedSalaryBank, })
      }

      resetGlobalQuery();
      globalQuery.columns = ["employee_id", "employee_code", "employee_name"];
      globalQuery.table = "cmv_employee"
      globalQuery.conditions = filterConditions;
      let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      if (getEmployeeList.length > 0) {
        getEmployeeList = getEmployeeList?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
        getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
        setEmployeeOpts(getEmployeeList);
        setEmployeeId('All');
        employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
      } else {
        setEmployeeOpts([]);
        setEmployeeId('');
        employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  // ***----------- Helping Functions.
  const goBack = () => { navigate("/Dashboard") }

  // Get Current DateTime
  const FnGetCurrentDateTime = () => {
    let currentDate = new Date(); // Get the current date and time
    // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
    let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
    return formattedDateTime;
  }
  // ***----------- Helping Functions Ends.


  // ****-------Get Employee Salary Details Functions Starts--------------***
  const FnShowSalaryData = async () => {
    debugger
    try {
      let isValidFilters = await FnChkIsValidFilters();
      if (!isValidFilters) { return false; }

      // *** Means Filters are valid then filter data as per the filters.
      setIsLoading(true);


      // *** Apply Filters Conditions;
      let selectedEmplType = employeeTypesComboRef.current.field_name;
      let selectedEmployee = employeeComboRef.current.employee_id;
      let selectedSalaryBank = salaryBankComboRef.current?.bank_id;


      let filterConditions = [
        { field: "htsal.salary_year", operator: "=", value: cmb_salary_year },
        { field: "htsal.salary_month", operator: "=", value: cmb_salary_month },
        { field: "htsal.net_salary", operator: ">", value: 0 },
        { field: "empl.is_active", operator: "=", value: 1 },
        { field: "htsal.company_id", operator: "=", value: parseInt(COMPANY_ID) },
        { field: "htsal.is_delete", operator: "=", value: 0 },
        { field: "empl.bank_id1", operator: "!=", value: 1 },
      ];


      // Check it selected one employee or not? 
      if (selectedEmployee !== undefined && selectedEmployee !== null && selectedEmployee !== '') {
        filterConditions.push({ field: "htsal.employee_id", operator: "=", value: selectedEmployee });

      } else {
        // Means load data without employee filter.
        // If Valid Then set the default data if not selected any filter.
        await FnSetDefaultData();
        if (selectedEmployee === '') {
          const employeeIds = employeeOpts
            .filter(emp => emp.employee_id !== "") // Remove entries where employee_id is an empty string
            .map(emp => emp.employee_id);

          if (employeeIds.length === 1) {
            // If only one employee_id, use "=" operator with a single value
            filterConditions.push({
              field: "empl.employee_id",
              operator: "=",
              value: employeeIds[0] // Single value, no need for join
            });
          } else if (employeeIds.length > 1) {
            // If multiple employee_ids, use "IN" with a comma-separated string
            filterConditions.push({
              field: "empl.employee_id",
              operator: "IN",
              values: employeeIds.map(id => (isNaN(id) ? `"${id}"` : id)) // Enclose non-numeric IDs in quotes
            });
          }
        }

        if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
          filterConditions.push({ field: "empl.employee_type", operator: "=", value: selectedEmplType });
        }
      }

      resetGlobalQuery();
      globalQuery.columns = [
        "empl.employee_code", "empl.employee_name",
        "empl.bank_name1", "empl.account_name1", "empl.account_no1", "empl.ifsc_code1",
        "htsal.net_salary"
      ];
      globalQuery.table = "ht_salary_summary htsal";
      globalQuery.conditions = [...filterConditions];

      if (selectedSalaryBank !== 0) { // Means selected the other bank;
        globalQuery.conditions.push({ field: "empl.bank_id1", operator: "=", value: selectedSalaryBank, })
      } else {    // Means selected any valid bank or cash bank;
        let bankIdsNotIn = companyBankDtl.filter(bank => bank?.bank_id !== 0).map(Fbank => Fbank?.bank_id)
        globalQuery.conditions.push({ field: "empl.bank_id1", operator: "NOT IN", values: bankIdsNotIn, })
      }

      // Managed the company_based bank details into the employee_master;
      // if([cashBankIdSpinning, cashBankIdTexSpin, cashBankIdGinning].includes((selectedSalaryBank))){ // Means Selected the cash bank;
      //   globalQuery.conditions.push({ field: "empl.bank_id1", operator: "IN", values: [cashBankIdSpinning, cashBankIdTexSpin, cashBankIdGinning], })

      // } else if([pnbBankIdSpinnig, axisBankIDTexSpin, pnbBankIdGinning].includes((selectedSalaryBank))){ // Means Selected the Not a cash and other;
      //   globalQuery.conditions.push({ field: "empl.bank_id1", operator: "=", value: selectedSalaryBank, })

      // } else {  // means selected others
      //   let bankIdsNotIn = salaryBankOpts.filter(bank => bank?.bank_id !== 0).map(Fbank => Fbank?.bank_id)
      //   globalQuery.conditions.push({ field: "empl.bank_id1", operator: "NOT IN", values: [...bankIdsNotIn, cashBankIdSpinning, cashBankIdTexSpin, cashBankIdGinning] })

      // }

      globalQuery.joins = [
        {
          "table": `cmv_employee empl`,
          "type": "LEFT",
          "on": [
            { "left": "empl.employee_id", "right": "htsal.employee_id", },
            { "left": "empl.company_id", "right": "htsal.company_id", },
          ]
        },
      ]
      globalQuery.orderBy = ['empl.department_id Asc', 'empl.sub_department_id Asc', 'empl.employee_code Asc',];
      let getSalaryDetails = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      if (getSalaryDetails.length > 0) {
        getSalaryDetails = getSalaryDetails.map((record, index) => ({
          sr_no: index + 1,
          ...record,
        }));

        setEmployeeSalaryDetails(getSalaryDetails);
        // console.table(getSalaryDetails);
      } else {
        setEmployeeSalaryDetails([]);
      }

    } catch (error) {
      console.log("error on combos change: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false);
    }
  }


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const exportToExcelTransfer = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Instructions');
    // Apply background color #FCD5B4 for the entire sheet initially (100 columns x 1000 rows)
    for (let row = 1; row <= 1000; row++) {
      for (let col = 1; col <= 100; col++) {
        worksheet.getCell(row, col).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FCD5B4' }, // Background color for all cells
        };
      }
    }
    // Instructions data starting at C4
    const instructionsData = [
      ["UPLOAD FILE GENERATION TTUM"],
      [""],
      ["THERE ARE TWO SHEETS IN THE FILE"],
      ["  1. DATA INPUT FORM"],
      ["  2. TTUM FORMAT"],
      ["NO FIELD IS MODIFIABLE IN SHEET 2 WHEREAS IN SHEET1 ONLY THE DATA ENTRY FIELDS (UNCOLOURED FIELDS) ARE MODIFIABLE"],
      ["FOR RTGS/NEFT:"],
      ["  1. USER SHOULD ENTER THE REQUIRED DATA IN ALL FIELDS"],
      ["  2. MAXIMUM 7000 ROWS CAN BE ENTERED IN SINGLE FILE."],
      // ["  • AFTER ENTRY INTO SHEET 1 (DATA INPUT FORM), USER SHOULD CLICK ON 'Generate upload file' BUTTON. AS A RESULT, UPLOADABLE FILE WILL BE SAVED"],
      [{
        richText: [
          { text: "  3. AFTER ENTRY INTO SHEET 1 (DATA INPUT FORM), USER SHOULD CLICK ON '", font: { bold: true, size: 10, color: { argb: 'FF000000' } } },
          { text: "Generate upload file", font: { bold: true, size: 10, color: { argb: 'FFFF0000' } } }, // Red text
          { text: "' BUTTON. AS A RESULT, UPLOADABLE FILE WILL BE SAVED", font: { bold: true, size: 10, color: { argb: 'FF000000' } } }
        ]
      }],
      ["  4. FROM C:\UPLOAD THE FILE CAN BE UPLOADED USING PCUNIX & TTUM."],
      [""],
    ];
    // Start instructions from C4
    let instructionStartRow = 4;
    instructionsData.forEach((row, rowIndex) => {
      const rowObj = worksheet.getRow(instructionStartRow + rowIndex);
      row.forEach((text, cellIndex) => {
        const cellObj = rowObj.getCell(cellIndex + 3); // Start from column C (index 3)
        cellObj.value = text;
        // Title styling for the first row
        if (rowIndex === 0) {
          cellObj.font = { bold: true, size: 11, color: { argb: 'FFFF0000' } }; // Red text color
          cellObj.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D9D9D9' } }; // Gray background for title
          cellObj.alignment = { horizontal: 'center', vertical: 'middle' };
          cellObj.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          };
        } else {
          // Default styling for other instruction rows
          cellObj.font = { bold: true, size: 10, color: { argb: 'FF000000' } }; // Teal color for other instructions
          cellObj.alignment = { horizontal: 'left', vertical: 'middle' };
        }
        cellObj.protection = { locked: true };
        // Note: While instructions are visually styled, Excel doesn't have direct read-only cell properties.
        // The user should be informed that these cells are not meant to be edited.
      });
    });
    // Add the note in a box (for example, in cell C14)
    const noteCell = worksheet.getCell(' B16'); // Adjust the cell reference as needed
    noteCell.value = "NOTE: THIS TOOL GENERATES UPLOAD FILES IN THE SHORT FORMAT (RECORD LENGTH 185) OF TTUM FORMAT,IF BUSINESS REQUIREMENT IS OF ADDITIONAL FIELDS WHICH ARE NOT COVERED.\nTHEN USER SHOULD USE THE UPLOADABLE FILE WITH ALL THE FIELDS";
    // noteCell.value = "THEN USER SHOULD USE THE UPLOADABLE FILE WITH ALL THE FILEDS";
    // Set the font for the note
    noteCell.font = { bold: true, size: 10, color: { argb: 'FF000000' } }; // Black color
    // Set the fill color for the note box (optional)
    noteCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FCD5B4' } // White background
    };
    // Apply borders to create a box effect
    noteCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    };
    // Optionally, set the alignment to center
    // Set text wrapping and alignment
    noteCell.alignment = {
      wrapText: true,  // This enables text wrapping to ensure all text is visible
      horizontal: 'center',
      vertical: 'middle'
    };
    // You might want to merge cells for the note to make it look better
    worksheet.mergeCells('B16:I17'); // Adjust the range as needed to fit your note
    // Merge title row (C4:H4 for "UPLOAD FILE GENERATION TTUM")
    worksheet.mergeCells(`C4:H4`);
    // Leave a gap of one row between instructions and table headers
    // Create a new input box after the note (for example, starting at C15)
    // Create three input boxes with labels in one row (for example, starting at C15)
    const inputRow = 19; // Row number for the input boxes
    const inputBoxWidth = 10; // Width for each input box
    const spaceColumnWidth = 6; // Width for the space column
    // Define input labels (only for the first two input boxes)
    const inputLabels = ["ENTER NO OF RECORDS", "ENTER FILE NAME"];
    // Add labels and input boxes to the row
    inputLabels.forEach((label, index) => {
      // Create label cell
      const labelCell = worksheet.getCell(inputRow, index * 2 + 3); // Start from column C (index 3, 5 for labels)
      labelCell.value = label; // Set the label
      labelCell.font = { wrapText: true, bold: true, size: 10, color: { argb: 'FF000000' } }; // Black color
      labelCell.alignment = { horizontal: 'left', vertical: 'middle' };

      // Create input box cell
      const inputCell = worksheet.getCell(inputRow, index * 2 + 4); // Adjacent column for input box (D, E)
      // inputCell.value = ""; // Initialize with an empty value
      if (index === 0) {
        inputCell.value = employeeSalaryDetails.length
      } else if (index === 1) {
        inputCell.value = "SS";
      }
      inputCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' } // White background
      };
      inputCell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      };
      inputCell.alignment = { horizontal: 'left', vertical: 'middle' };
      inputCell.protection = { locked: false };
      // Set width for the input box column
      worksheet.getColumn(index * 2 + 4).width = inputBoxWidth; // Adjusting column width for input box
    });
    // Create an empty cell for spacing
    const spaceCell = worksheet.getCell(inputRow, 6); // Column F for the space
    spaceCell.value = ""; // Set empty value
    spaceCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFFFF' } // White background
    };
    // Set width for the space column
    worksheet.getColumn(6).width = spaceColumnWidth; // Adjusting column width for spacing
    worksheet.protect();
    // Create the button in the next cell
    const buttonCell = worksheet.getCell(inputRow, 8); // Column G for the button
    buttonCell.value = "Generate upload file"; // Set button text
    buttonCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'f0f0f0' } // Background color for the button
    };
    buttonCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    };
    buttonCell.alignment = { horizontal: 'center', vertical: 'middle' };
    // Set width for the button column
    worksheet.getColumn(7).width = inputBoxWidth; // Adjusting column width for the button
    // Optional: Set the row height for better visibility
    worksheet.getRow(inputRow).height = 20; // Adjust the height as needed
    // Leave a gap of one row between the input boxes and table headers
    let tableStartRow = inputRow + 2; // Incrementing for the new input box row
    // Add table headers starting right after the instructions (excluding the index column header)
    const headers = [
      "ACCOUNT NUMBER",
      "CURRENCY CODE",
      "SOL ID",
      "AMOUNT",
      "PART TRAN TYPE",
      "DATE (DD/MM/YYYY)",
      "TRAN PARTICULAR",
      "ACCOUNT REPORT CODE"
    ];
    const headerRow = worksheet.getRow(tableStartRow);
    headers.forEach((header, colIndex) => {
      const cell = headerRow.getCell(colIndex + 2); // Start from column C (index 2, skipping B for index)
      cell.value = header;
      cell.font = { bold: true, size: 10, color: { argb: 'c00000' } }; // Red color (hex code for red)
      // cell.font = { bold: true, size: 10 };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD9D9D9' } }; // Background color #D9D9D9
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      // Lock the header cell (set protection flag for the cell)
      cell.protection = { locked: true };
    });
    const currentDate = new Date();
    const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;

    // Add editable table content
    let employeeSalaryDetailsData = employeeSalaryDetails.map((item, index) => ({
      index: index + 1, // Adding index for each row starting from 1  
      account_number: item.account_no1,
      currency: "INR",
      sol_id: "224510",
      amount: item.net_salary,
      part_tran_type: "C",
      date: formattedDate,
      tran_particular: "SALARY",
      account_report_code: "",
    }));
    // Add existing salary details to the worksheet
    employeeSalaryDetailsData.forEach((salary, rowIndex) => {
      const row = worksheet.getRow(tableStartRow + rowIndex + 1); // Row right after headers
      // Column B for index (no background color for index column, and no borders)
      row.protection = { locked: false };
      const indexCell = row.getCell(1);
      indexCell.value = salary.index;
      indexCell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center alignment for index column
      // Add the rest of the table data starting from column C
      row.getCell(2).value = salary.account_number || ''; // ACCOUNT NUMBER
      row.getCell(2).numFmt = '@'; // Set the format to text to prevent scientific notation
      row.getCell(3).value = salary.currency || '';       // CURRENCY
      row.getCell(3).numFmt = '@'; // Set format to text
      row.getCell(4).value = salary.sol_id || '';         // SOL ID
      row.getCell(4).numFmt = '@'; // Set format to text
      row.getCell(5).value = salary.amount || '';         // AMOUNT
      row.getCell(5).numFmt = '@'; // Set format to text
      row.getCell(6).value = salary.part_tran_type || ''; // PART TRAN TYPE
      row.getCell(6).numFmt = '@'; // Set format to text
      row.getCell(7).value = salary.date || '';           // DATE (DD/MM/YYYY)
      row.getCell(7).numFmt = '@'; // Set format to text
      row.getCell(8).value = salary.tran_particular || ''; // TRAN PARTICULAR
      row.getCell(8).numFmt = '@'; // Set format to text
      row.getCell(9).value = salary.account_report_code || ''; // ACCOUNT REPORT CODE
      row.getCell(9).numFmt = '@'; // Set format to text
      // Set background color to white and apply borders to table cells (except index column)
      row.eachCell((cell, colIndex) => {
        if (colIndex !== 1) { // Skip index column (B)
          // Set white background for table cells
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }
      });
    });
    // Add 150 additional rows with white background and incremental numbering
    const additionalRowsStart = tableStartRow + employeeSalaryDetailsData.length + 1;
    const startingIndex = employeeSalaryDetailsData.length + 1; // Starting index for additional rows
    for (let i = 0; i < 150; i++) {
      const row = worksheet.getRow(additionalRowsStart + i);
      for (let colIndex = 1; colIndex <= headers.length + 1; colIndex++) { // Columns B to J
        const cell = row.getCell(colIndex);
        if (colIndex === 1) {
          // Set index column (B) with incremental numbering and center alignment
          cell.value = startingIndex + i; // Incremental numbering for additional rows
          cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center alignment for index column
        } else {
          // Set white background for all other columns
          cell.value = ''; // No data
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }; // White background
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }
        cell.protection = { locked: false };
      }
    }
    // Set width of the columns
    worksheet.getColumn(1).width = 10; // Width for index column
    for (let i = 2; i <= headers.length + 1; i++) {
      worksheet.getColumn(i).width = 20; // Set width for other columns
    }
    // Write to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const totalSalary = employeeSalaryDetails.reduce((sum, salary) => sum + salary.net_salary, 0).toFixed(2);
    // Set the download filename
    a.download = `BANK-TRANSFER-SHEET-${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}-RS-${totalSalary}.xlsx`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




  const exportToExcelBankRTGS = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Instructions');
    // Apply background color #FCD5B4 for the entire sheet initially (100 columns x 1000 rows)
    for (let row = 1; row <= 1000; row++) {
      for (let col = 1; col <= 100; col++) {
        worksheet.getCell(row, col).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FCD5B4' }, // Background color for all cells
        };
      }
    }

    // Instructions data starting at C4
    const instructionsData = [
      ["UPLOAD FILE GENERATION TOOL FOR NEFT(N06) /RTGS (R41)"],
      [""],
      ["THERE ARE TWO SHEETS IN THE FILE"],
      ["  1. DATA INPUT FORM"],
      ["  2. TTUM FORMAT"],
      ["NO FIELD IS MODIFIABLE IN SHEET 2 WHEREAS IN SHEET1 ONLY THE DATA ENTRY FIELDS (UNCOLOURED FIELDS) ARE MODIFIABLE"],
      ["FOR RTGS/NEFT:"],
      ["  1. USER SHOULD ENTER THE REQUIRED DATA IN ALL MANDATORY FIELDS MARKED WITH *"],
      ["  2. MAXIMUM 225 ROWS CAN BE ENTERED IN SINGLE FILE."],
      // ["  • AFTER ENTRY INTO SHEET 1 (DATA INPUT FORM), USER SHOULD CLICK ON 'Generate upload file' BUTTON. AS A RESULT, UPLOADABLE FILE WILL BE SAVED"],
      [{
        richText: [
          { text: "  3. AFTER ENTRY INTO SHEET 1 (DATA INPUT FORM), USER SHOULD CLICK ON '", font: { bold: true, size: 10, color: { argb: '496692' } } },
          { text: "Generate upload file", font: { bold: true, size: 10, color: { argb: 'FFFF0000' } } }, // Red text
          { text: "' BUTTON. AS A RESULT, UPLOADABLE FILE WILL BE SAVED IN FORMATED TEXT", font: { bold: true, size: 10, color: { argb: '0E8888' } } }
        ]
      }],
      [{
        richText: [
          {
            text: '(<NAME>.txt) FORMAT IN C/UPLOAD DIRECTORY.',
            font: { bold: true, size: 10, color: { argb: '0E8888' } }
          }
        ]
      }],
      ["  4. FROM C/UPLOAD THIS FILE CAN BE UPLOADED USING Menu HNFTBLK/HNRTBLK."],
      [""],
    ];

    // Start instructions from C4
    let instructionStartRow = 4;
    instructionsData.forEach((row, rowIndex) => {
      const rowObj = worksheet.getRow(instructionStartRow + rowIndex);
      row.forEach((text, cellIndex) => {
        const cellObj = rowObj.getCell(cellIndex + 3); // Start from column C (index 3)
        cellObj.value = text;
        // Title styling for the first row
        if (rowIndex === 0) {
          cellObj.font = { bold: true, size: 11, color: { argb: 'c00000' } }; // Red text color
          cellObj.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D9D9D9' } }; // Gray background for title
          cellObj.alignment = { horizontal: 'center', vertical: 'middle' };
          cellObj.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          };
        } else {
          // Default styling for other instruction rows
          cellObj.font = { bold: true, size: 10, color: { argb: '496692' } }; // Teal color for other instructions
          cellObj.alignment = { horizontal: 'left', vertical: 'middle' };
        }
        cellObj.protection = { locked: true };
        // Note: While instructions are visually styled, Excel doesn't have direct read-only cell properties.
        // The user should be informed that these cells are not meant to be edited.
      });
    });
    // Add the note in a box (for example, in cell C14)
    const noteCell = worksheet.getCell(' B17'); // Adjust the cell reference as needed
    noteCell.value = "NOTE : THIS TOOL GENERATE UPLOAD FILES IN THE SHORT FORMAT (RECORD LENGTH 240) OF NEFT UPLOADS, IF BUSINESS RQUIREMENT IS OF ADDITIONAL FIELDS WHICH ARE NOT COVERED IN THIS FORMAT    THAN USER SHOULD ENTER THAT FIELDS AFTER UPLOAD AND BEFORE POSTING/VERIFICATION  OR USE THE UPLOADABLE FILE WITH ALL THE FIELDS (RECORDS LENGTH 761)";
    // noteCell.value = "THEN USER SHOULD USE THE UPLOADABLE FILE WITH ALL THE FILEDS";
    // Set the font for the note
    noteCell.font = { color: { argb: 'FF000000' } }; // Black color
    // Set the fill color for the note box (optional)
    noteCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FCD5B4' } // White background
    };
    // Apply borders to create a box effect
    // noteCell.border = {
    //   top: { style: 'thin', color: { argb: 'FF000000' } },
    //   left: { style: 'thin', color: { argb: 'FF000000' } },
    //   bottom: { style: 'thin', color: { argb: 'FF000000' } },
    //   right: { style: 'thin', color: { argb: 'FF000000' } }
    // };
    // Set text wrapping and alignment
    noteCell.alignment = {
      wrapText: true,  // This enables text wrapping to ensure all text is visible
      horizontal: 'center',
      vertical: 'middle'
    };
    // You might want to merge cells for the note to make it look better
    worksheet.mergeCells('B17:I18'); // Adjust the range as needed to fit your note
    // Merge title row (C4:H4 for "UPLOAD FILE GENERATION TTUM")
    worksheet.mergeCells(`C4:H4`);
    // Leave a gap of one row between instructions and table headers
    
    // Create three input boxes with labels in one row (for example, starting at C15)
    const inputRow = 20; // Row number for the input boxes
    const inputBoxWidth = 10; // Width for each input box
    const spaceColumnWidth = 6; // Width for the space column
    // Define input labels (only for the first two input boxes)
    const inputLabels = ["ENTER FILE NAME (WITHOUT EXTENSION): "];
    // Add labels and input boxes to the row
    inputLabels.forEach((label, index) => {
      if (index === 0) {
        // Create label cell
        const labelCell = worksheet.getCell(inputRow, index * 2 + 3); // Start from column C (index 3, 5 for labels)
        labelCell.value = label; // Set the label
        labelCell.font = { wrapText: false, bold: true, size: 10, color: { argb: 'FF000000' } }; // Black color
        labelCell.alignment = { horizontal: 'left', vertical: 'middle' };

        // Create input box cell
        const inputCell = worksheet.getCell(inputRow, index * 2 + 6); // Adjacent column for input box (D, E)
        inputCell.value = "RTGS"; // Initialize with an empty value
        inputCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' } // White background
        };
        inputCell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        inputCell.alignment = { horizontal: 'left', vertical: 'middle' };
        inputCell.protection = { locked: false };
        // Set width for the input box column
        worksheet.getColumn(index * 2 + 4).width = inputBoxWidth; // Adjusting column width for input box
      }
    });
    // // Create an empty cell for spacing
    // const spaceCell = worksheet.getCell(inputRow, 6); // Column F for the space
    // spaceCell.value = ""; // Set empty value
    // spaceCell.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFFFFFFF' } // White background
    // };
    // Set width for the space column
    worksheet.getColumn(6).width = spaceColumnWidth; // Adjusting column width for spacing
    worksheet.protect();
    // Create the button in the next cell
    const buttonCell = worksheet.getCell(inputRow, 8); // Column G for the button
    buttonCell.value = "Generate upload file"; // Set button text
    buttonCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'f0f0f0' } // Background color for the button
    };
    buttonCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    };
    buttonCell.alignment = { horizontal: 'center', vertical: 'middle' };
    // Set width for the button column
    worksheet.getColumn(7).width = inputBoxWidth; // Adjusting column width for the button
    // Optional: Set the row height for better visibility
    worksheet.getRow(inputRow).height = 20; // Adjust the height as needed
    // Leave a gap of one row between the input boxes and table headers
    let tableStartRow = inputRow + 2; // Incrementing for the new input box row
    // Add table headers starting right after the instructions (excluding the index column header)
    const headers = [
      "ACCOUNT NUMBER*",             // Column 1
      "AMOUNT*",                     // Column 2
      "TRAN PARTICULARS",             // Column 3
      "IFSC CODE*",                  // Column 4
      "BENEFICIARY ACCOUNT*",         // Column 5
      "BENEFICIARY NAME*",            // Column 6
      "ADDRESS*",                    // Column 7
      "SENDER TO RECEIVER INFO1",     // Column 8
      "SENDER TO RECEIVER INFO2",     // Column 9
      "CHARGE ACCOUNT",               // Column 10
      "CHEQUE ALPHA",                 // Column 11
      "CHEQUE NUMBER",                // Column 12
      "CHEQUE DATE (DDMMYYYY)"        // Column 13
    ];
    const headerRow = worksheet.getRow(tableStartRow);
    headers.forEach((header, colIndex) => {
      const cell = headerRow.getCell(colIndex + 2); // Start from column C (index 2, skipping B for index)
      cell.value = header;
      cell.font = { bold: true, size: 10, color: { argb: 'c00000' } }; // Red color (hex code for red)
      // cell.font = { bold: true, size: 10 };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD9D9D9' } }; // Background color #D9D9D9
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      // Lock the header cell (set protection flag for the cell)
      cell.protection = { locked: true };
    });

    const currentDate = new Date().toLocaleDateString('en-GB').split('/').join('-');
         debugger
    let employeeSalaryDetailsData = employeeSalaryDetails.map((item, index) => ({
      index: index + 1,                 // Adding index for each row starting from 1
      account_number: salaryCompanyBankComboRef.current?.company_branch_bank_account_no,  // ACCOUNT NUMBER*
      amount: item.net_salary,                        // AMOUNT*
      tran_particular: "SALARY",               // TRAN PARTICULARS
      ifsc_code: item.ifsc_code1,                    // IFSC CODE*
      beneficiary_account: item.account_no1,           // BENEFICIARY ACCOUNT*
      beneficiary_name: item.employee_name.replace(/^(Mr\.|Mrs\.)\s*/i, "").trim(),            // BENEFICIARY NAME without prefix*
      address: "KADI",                       // ADDRESS*
      sender_to_receiver_info1: "FAST",      // SENDER TO RECEIVER INFO1
      sender_to_receiver_info2: "SALARY",      // SENDER TO RECEIVER INFO2
      charge_account: salaryCompanyBankComboRef.current?.company_branch_bank_account_no,               // CHARGE ACCOUNT
      cheque_alpha: "YYY",                  // CHEQUE ALPHA
      cheque_number: "",                 // CHEQUE NUMBER
      cheque_date: currentDate,                   // CHEQUE DATE (DDMMYYYY)
    }));
    // Add existing salary details to the worksheet
    employeeSalaryDetailsData.forEach((salary, rowIndex) => {
      const row = worksheet.getRow(tableStartRow + rowIndex + 1); // Row right after headers
      // Column B for index (no background color for index column, and no borders)
      const indexCell = row.getCell(1);
      indexCell.value = salary.index;
      indexCell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center alignment for index column
      // Add the rest of the table data starting from column C
      row.getCell(2).value = salary.account_number || ''; // ACCOUNT NUMBER*
      row.getCell(2).numFmt = '@'; // Set the format to text to prevent scientific notation
      row.getCell(3).value = salary.amount || ''; // AMOUNT*
      row.getCell(3).numFmt = '@'; // Set format to text
      row.getCell(4).value = salary.tran_particular || ''; // TRAN PARTICULARS
      row.getCell(4).numFmt = '@'; // Set format to text
      row.getCell(5).value = salary.ifsc_code || ''; // IFSC CODE*
      row.getCell(5).numFmt = '@'; // Set format to text
      row.getCell(6).value = salary.beneficiary_account || ''; // BENEFICIARY ACCOUNT*
      row.getCell(6).numFmt = '@'; // Set format to text
      row.getCell(7).value = salary.beneficiary_name || ''; // BENEFICIARY NAME*
      row.getCell(7).numFmt = '@'; // Set format to text
      row.getCell(8).value = salary.address || ''; // ADDRESS*
      row.getCell(8).numFmt = '@'; // Set format to text
      row.getCell(9).value = salary.sender_to_receiver_info1 || ''; // SENDER TO RECEIVER INFO1
      row.getCell(9).numFmt = '@'; // Set format to text
      row.getCell(10).value = salary.sender_to_receiver_info2 || ''; // SENDER TO RECEIVER INFO2
      row.getCell(10).numFmt = '@'; // Set format to text
      row.getCell(11).value = salary.charge_account || ''; // CHARGE ACCOUNT
      row.getCell(11).numFmt = '@'; // Set format to text
      row.getCell(12).value = salary.cheque_alpha || ''; // CHEQUE ALPHA
      row.getCell(12).numFmt = '@'; // Set format to text
      row.getCell(13).value = salary.cheque_number || ''; // CHEQUE NUMBER
      row.getCell(13).numFmt = '@'; // Set format to text
      row.getCell(14).value = salary.cheque_date || ''; // CHEQUE DATE (DDMMYYYY)
      row.getCell(14).numFmt = '@'; // Set format to text
      // Set background color to white and apply borders to table cells (except index column)
      row.eachCell((cell, colIndex) => {
        if (colIndex !== 1) { // Skip index column (B)
          // Set white background for table cells
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.protection = { locked: false };
        }
      });
    });
    // Add 150 additional rows with white background and incremental numbering
    const additionalRowsStart = tableStartRow + employeeSalaryDetailsData.length + 1;
    const startingIndex = employeeSalaryDetailsData.length + 1; // Starting index for additional rows
    for (let i = 0; i < 150; i++) {
      const row = worksheet.getRow(additionalRowsStart + i);
      for (let colIndex = 1; colIndex <= headers.length + 1; colIndex++) { // Columns B to J
        const cell = row.getCell(colIndex);
        if (colIndex === 1) {
          // Set index column (B) with incremental numbering and center alignment
          cell.value = startingIndex + i; // Incremental numbering for additional rows
          cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center alignment for index column
        } else {
          // Set white background for all other columns
          cell.value = ''; // No data
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }; // White background
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }
        cell.protection = { locked: false };
      }
    }
    // // Set width of the columns
    const arr = [20, 15, 10, 15, 25, 40, 20, 20, 25, 20, 15, 15, 20];
    worksheet.getColumn(1).width = 10; // Width for index column
    for (let i = 2; i <= headers.length + 1; i++) {
      worksheet.getColumn(i).width = arr[i - 2]; // Set width for other columns
    }
    // Write to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const totalSalary = employeeSalaryDetails.reduce((sum, salary) => sum + salary.net_salary, 0).toFixed(2);
    a.download = `BANK-RTGS-SHEET-${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}-RS-${totalSalary}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };





  const FnRenderSalaryDetailsTbl = useMemo(() => {
    if (employeeSalaryDetails.length === 0) { return null; }

    return (
      <>
        <style>
          {`
                @media print {
                    .print-content {
                        padding: 0;
                        box-sizing: border-box;
                    }
                    @page {
                        margin: 0;
                        size: A4;
                        margin: 2%;
                        border: 2px solid black;
                    }
                    html, body {
                        width: 100%;
                        height: 100%;
                    }
                    body {
                        margin: 0;
                        padding: 0;
                    }
                }
                .erp_table {
                    font-size: 3px !important; /* Default font size */
                }
                .erp_table_th, .erp_table_td {
                    border: 1px solid black;
                    padding-top: 2px !important;
                    padding-bottom: 2px !important;
                }
            `}
        </style>

        <Table className="erp_table" id='MonthlySalaryDtlsTbl' style={{ whiteSpace: 'normal' }} ref={printComponentRef}>
          <thead className="erp_table_head thead-light text-center" style={{ whiteSpace: 'normal' }} >
            <tr style={{ whiteSpace: 'normal' }}>
              <th className="erp_table_th">Sr. No.</th>
              {/* <th className="erp_table_th">Employee Code</th>
              <th className="erp_table_th">Employee Name</th> */}
              <th className="erp_table_th">Name As per Bank</th>
              <th className="erp_table_th">Bank Name</th>

              <th className="erp_table_th">Account No.</th>
              <th className="erp_table_th">IFSC Code</th>
              <th className="erp_table_th">Salary Amount</th>
            </tr>
          </thead>

          <tbody>
            {
              employeeSalaryDetails?.map((salaryRecord, index) => {
                return (
                  <tr key={index} rowIndex={index}>
                    <td className="erp_table_td text-end">{index + 1}</td>
                    {/* <td className="erp_table_td">{salaryRecord.employee_code}</td>
                    <td className="erp_table_td">{salaryRecord.employee_name}</td> */}
                    <td className="erp_table_td">{salaryRecord.account_name1}</td>
                    <td className="erp_table_td">{salaryRecord.bank_name1}</td>

                    <td className="erp_table_td">{salaryRecord.account_no1}</td>
                    <td className="erp_table_td">{salaryRecord.ifsc_code1}</td>
                    <td className="erp_table_td text-end">{salaryRecord.net_salary}</td>
                  </tr>
                )
              })
            }

            {
              // Footer Summary Row.
              <tr className='table-light' key={employeeSalaryDetails.length + 1} rowIndex={employeeSalaryDetails.length + 1}>
                <th className="erp_table_th" colSpan={5}>Summary Totals</th>
                <th className="erp_table_th text-end" >
                  {employeeSalaryDetails.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)}
                </th>
              </tr>


            }
            <tr>
              <td colSpan={6} className="erp_table_td align-bottom" style={{ fontSize: "13px", marginTop: "10px" }}>
                <div className='row  text-center' style={{ marginTop: "25px" }}>
                  <div className='col-4 text-center'>HR</div>
                  <div className='col-4 text-center'>HOD</div>
                  <div className='col-4 text-center'>MD</div>
                </div>
              </td>
            </tr>

          </tbody>
        </Table>
      </>
    )
  }, [employeeSalaryDetails])

  // ****-------Get Employee Salary Details Functions Ends--------------***



  // ****-------Validation Functions Starts--------------***
  // Function for validate the fields at the time of button click;
  const FnChkIsValidFilters = async () => {
    if (cmb_salary_year === '' || cmb_salary_year === null) {
      $('#error_cmb_salary_year').text('Please select the atleast one...!')
      $('#error_cmb_salary_year').show();
      return false;
    } else {
      $('#error_cmb_salary_year').hide();
    }

    if (cmb_salary_month === '' || cmb_salary_month === null) {
      $('#error_cmb_salary_month').text('Please select the atleast one...!')
      $('#error_cmb_salary_month').show();
      return false;
    } else {
      $('#error_cmb_salary_month').hide();
    }

    if (cmb_salary_bank_id === '' || cmb_salary_bank_id === null) {
      $('#error_cmb_salary_bank_id').text('Please select the atleast one...!')
      $('#error_cmb_salary_bank_id').show();
      return false;
    } else {
      $('#error_cmb_salary_bank_id').hide();
    }
    return true;
  }

  // Function for validate the fields on change
  const isEmpty = str => {
    if (typeof str === 'number' && Number.isInteger(str)) { str = str.toString(); }
    return str === undefined || str === null || str.trim() !== '';
  };

  const FnValidateFields = () => {
    setEmployeeSalaryDetails([]);
    if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
    if (isEmpty(salaryMonthComboRef.current?.value || null)) { $('#error_cmb_salary_month').hide(); }
    if (isEmpty(salaryBankComboRef.current?.value || '')) { $('#error_cmb_salary_bank_id').hide(); }
    if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
    if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    if (isEmpty(salaryCompanyBankComboRef.current?.value || '')) { $('#error_cmb_salary_company_bank_id').hide(); }

  }

  // ****-------Validation Functions Ends--------------***

  // ********* PrintOut Functionalities Starts. ****************************************
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const FnPrintReport = useReactToPrint({
    content: () => {
      const headerHtml = printOutHeader(); // Call function to get header HTML

      const contentWrapper = document.createElement('div');
      contentWrapper.className = 'printable-content';
      contentWrapper.innerHTML = headerHtml; // Add generated header HTML

      // Append the main content to the wrapper
      const mainContentClone = printComponentRef.current.cloneNode(true);
      contentWrapper.appendChild(mainContentClone);

      return contentWrapper;
    },
    onBeforePrint: () => {
      // Apply the desired font size before printing
      if (printComponentRef.current) {
        const table = printComponentRef.current.querySelector('.erp_table');
        if (table) {
          table.style.fontSize = '7px'; // Set your desired font size
        }
      }
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    documentTitle: `${COMPANY_NAME} Salary Bank List For ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`
  });

  const printOutHeader = () => {
    debugger
    return ReactDOMServer.renderToString(
      <>
        <div className='px-0 border border-dark'> {/* border border-dark */}
          <div className='row mb-0 p-1 border-bottom border-dark'>
            <div className="col-sm-1">
              <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
            </div>
            <div className="col-sm-11">
              <div className='erp-invoice-print-label text-center'>
                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
              </div>
              <div className='erp-invoice-print-label-lg text-center'> Salary Bank List </div>
              <div className='erp-invoice-print-label-lg text-center'>
                For Month: {salaryMonthComboRef.current?.label}   Year: {salaryYearComboRef.current?.value}
              </div>
            </div>
          </div>
          <dl className="row pb-0 mb-0 px-1">
            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>Bank Name:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_name || ''}</dd> */}
                <dd className='col-sm-8 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_name ?? ''}</dd>

                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>Branch:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_branch_name || ''}</dd> */}
                <dd className='col-sm-8 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_branch_name ?? ''}</dd>

              </dl>
            </div>

            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>IFSC:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_ifsc_code || ''}</dd> */}
                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_ifsc_code ?? ''}</dd>

                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ fontSize: "12px" }}>A/C. No.:</dt>
                {/* <dd className='col-sm-9 erp-invoice-print-label'> {companyBankDtl?.company_branch_bank_account_no || ''}</dd> */}
                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: "12px" }}> {salaryCompanyBankComboRef.current?.company_branch_bank_account_no ?? ''}</dd>

              </dl>
            </div>

          </dl>
        </div>
      </>
    );
  };
  // ********* PrintOut Functionalities Ends. ****************************************


  // ********* ExportToExcel Functionalities Starts. ****************************************
  const addExcelFilter = (label, key, value, existingFilters) => {
    if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
  };

  const FnExportToExcel = async () => {
    debugger
    // Means No Data For Export. 
    if (employeeSalaryDetails.length === 0) { return null; }
    setIsLoading(true);
    // Preparing Data For ExportFile.
    let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

    // Excel Headers;
    jsonToExportExcel['headings'] = {
      'ReportName': `Salary Bank List ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`,
      'CompanyName': COMPANY_NAME,
      'CompanyAddress': sessionStorage.getItem('companyAddress'),
    }

    // Applied Filters.
    let appliedFilters = {
      '0': `To, `,
      '1': salaryCompanyBankComboRef.current?.company_branch_bank_name ?? '',
      '2': salaryCompanyBankComboRef.current?.company_branch_bank_branch_name ?? '',
      // '3': `IFSC Code: ` + salaryCompanyBankComboRef.current?.company_branch_bank_ifsc_code || '',
      // '4': `A/c. No.: ` + salaryCompanyBankComboRef.current?.company_branch_bank_account_no || '',
      '3': `IFSC Code: ` + (salaryCompanyBankComboRef.current?.company_branch_bank_ifsc_code ?? ''),
      '4': `A/c. No.: ` + (salaryCompanyBankComboRef.current?.company_branch_bank_account_no ?? ''),
      // '5': `Year: ${salaryYearComboRef.current?.label || ""}`,
      // '6': `Month: ${salaryMonthComboRef.current?.label}(${salaryMonthComboRef.current?.value || ""})`,
    };
    appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
    jsonToExportExcel['filtrKeyValue'] = appliedFilters;

    // Details Table Columns and Accessors.
    jsonToExportExcel['columns'] = [
      { "Headers": 'Sr. No.', "accessor": 'sr_no' },
      // { "Headers": 'Employee Code', "accessor": 'employee_code' },
      // { "Headers": 'Employee Name', "accessor": 'employee_name' },

      { "Headers": 'Name As Per Bank', "accessor": 'account_name1' },
      { "Headers": 'Bank Name', "accessor": 'bank_name1' },
      { "Headers": 'Account No.', "accessor": 'account_no1' },
      { "Headers": 'IFSC Code', "accessor": 'ifsc_code1' },
      { "Headers": 'Salary Amount', "accessor": 'net_salary' },
    ]
    // Data For Table.
    employeeSalaryDetails.map((salaryRecord, Index) => {
      salaryRecord.sr_no = Index + 1;
      jsonToExportExcel['allData'][Index] = salaryRecord;

      // Check if it is the last item then push the last sum-of-totals object.
      if (Index === employeeSalaryDetails.length - 1) {
        jsonToExportExcel['allData'][employeeSalaryDetails.length] = {
          sr_no: 'Summary Totals',
          // employee_code: '',
          // employee_name: '',
          account_name1: '',
          account_no1: '',
          ifsc_code1: '',
          net_salary: parseFloat(employeeSalaryDetails.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2))
        }
      }
    });
    await exlsExp.current.excel(jsonToExportExcel, `${COMPANY_NAME.replace(/ /g, '_')}_Salary_Bank_List_${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`);

    setIsLoading(false);
  }
  // ********* ExportToExcel Functionalities Ends. ****************************************


  return (
    <>

      <ComboBox ref={comboDataFunc} />
      <ExcelExport ref={exlsExp} />



      <DashboardLayout>
        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        {/* Card Starts*/}
        <div className="card mt-4">
          <div className="card-header py-0 main_heding">
            <label className="erp-form-label-lg">Salary Bank List Register</label>
          </div>

          {/* Card's Body Starts*/}
          <div className="card-body">

            <div className="row">
              {/* First Column Starts*/}
              <div className="col-sm-2 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year<span className="required">*</span> </Form.Label> </div>
                  <div className='col'>
                    <Select ref={salaryYearComboRef}
                      options={yearOpts}
                      inputId="cmb_salary_year"
                      value={yearOpts.find(option => option.value == cmb_salary_year) || null}
                      onChange={(selectedOpt) => {
                        setSalaryYear(selectedOpt.value);
                        salaryYearComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a year..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month<span className="required">*</span> </Form.Label> </div>
                  <div className='col'>
                    <Select ref={salaryMonthComboRef}
                      options={monthOpts}
                      inputId="cmb_salary_month"
                      value={monthOpts.find(option => option.value == cmb_salary_month) || null}
                      onChange={(selectedOpt) => {
                        setSalaryMonth(selectedOpt.value);
                        salaryMonthComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a month..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* First Column Ends*/}


              {/* Second Column Starts*/}
              <div className="col-sm-3 erp_form_col_div">

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Bank<span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={salaryBankComboRef}
                      options={salaryBankOpts}
                      inputId="cmb_salary_bank_id"
                      value={salaryBankOpts.find(option => option.value == cmb_salary_bank_id) || null}
                      onChange={(selectedOpt) => {
                        setSalaryBankId(selectedOpt.value);
                        salaryBankComboRef.current = selectedOpt;
                        FnComboOnChange('Bank');
                        FnValidateFields();
                      }}
                      placeholder="Search for a bank..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Company Bank </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={salaryCompanyBankComboRef}
                      options={companyBankDtl}
                      inputId="cmb_company_bank_id"
                      value={companyBankDtl.find(option => option.value == cmb_company_bank_id) || null}
                      onChange={(selectedOpt) => {
                        setCompanybankId(selectedOpt.value);
                        salaryCompanyBankComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a Company bank..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }} optional="optional"
                    />
                    <MDTypography variant="button" id="error_cmb_salary_company_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              <div className="col-sm-3 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                  <div className='col'>
                    <Select ref={departmentComboRef}
                      options={departmentOpts}
                      inputId="cmb_department_id"
                      value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                      onChange={(selectedOpt) => {
                        setDepartmentId(selectedOpt.value);
                        departmentComboRef.current = selectedOpt;
                        FnComboOnChange("Department");
                        FnValidateFields();
                      }}
                      placeholder="Search for a department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                  <div className='col'>
                    <Select ref={subDepartmentComboRef}
                      options={subDepartmentOpts}
                      inputId="cmb_sub_department_id"
                      value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                      onChange={(selectedOpt) => {
                        setSubDepartmentId(selectedOpt.value);
                        subDepartmentComboRef.current = selectedOpt;
                        FnComboOnChange("SubDepartment");
                        FnValidateFields();
                      }}
                      placeholder="Search for a sub-department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* Second Column Ends*/}

              {/* First Third Column Starts*/}
              <div className="col-sm-4 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={employeeTypesComboRef}
                      options={employeeTypesOpts}
                      inputId="cmb_employee_type_id"
                      value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                      onChange={(selectedOpt) => {
                        setEmployeeTypeId(selectedOpt.value);
                        employeeTypesComboRef.current = selectedOpt;
                        FnComboOnChange('EmployeeType');
                        FnValidateFields();
                      }}
                      placeholder="Search for a employee type..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Employee </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={employeeComboRef}
                      options={employeeOpts}
                      inputId="cmb_employee_id"
                      value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                      onChange={(selectedOpt) => {
                        setEmployeeId(selectedOpt.value);
                        employeeComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a employee..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* First Third Column Ends*/}
            </div>

            <div className="row justify-content-center mt-2">
              <div className="col text-center">
                <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowSalaryData(); }} variant="button" fontWeight="regular">show Data</MDButton>
              </div>
            </div>

            {
              employeeSalaryDetails.length === 0
                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                : <> <hr />

                  <div className="row py-1">
                    <div className="text-end">
                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                        onClick={(e) => { exportToExcelTransfer(); }} >Export Trans.</MDButton>
                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                        onClick={(e) => { exportToExcelBankRTGS(); }} >Export RTGS</MDButton>
                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                        onClick={(e) => { FnExportToExcel(); }}  >Export Excel</MDButton>


                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                        onClick={(e) => { FnPrintReport(); }}  >Print</MDButton>
                    </div>
                  </div>

                  <div className="row px-lg-2 d-block">
                    <div className="card p-0">
                      <div className="card-header py-0 main_heding mb-0">
                        <label className="erp-form-label-md-lg">Employee's Salary Details</label>
                      </div>
                      {/* Card's Body Starts*/}
                      <div className="card-body p-0 print-content" ref={printComponentRef}>
                        {FnRenderSalaryDetailsTbl}
                      </div>
                    </div>
                  </div>

                </>
            }

            <hr />
            <div className="row text-center mt-3">
              <div className=''>
                {/* <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                  className={`erp-gb-button float-center`}
                  onClick={(e) => { goBack(); }}  > Back </MDButton> */}

                {
                  employeeSalaryDetails.length === 0
                    ? null
                    : <>
                      {
                        employeeSalaryDetails.length > 30
                          ? <>
                            <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                              className={`erp-gb-button float-center`} onClick={(e) => { goBack(); }}  > Back </MDButton>
                          </>
                          : null
                      }

                      <MDButton type="button" variant="button" fontWeight="regular"
                        className="erp-gb-button ms-2" onClick={(e) => { FnPrintReport(); }} >Print</MDButton>
                    </>
                }
              </div>
            </div>

          </div>
          {/* Card's Body Ends*/}

        </div>
        {/* Card Ends*/}

      </DashboardLayout>




    </>
  )

}

export default SalaryBankListRegister
