import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import $ from 'jquery';

import { Form } from 'react-bootstrap';
import MDTypography from 'components/MDTypography';
import MDButton from "components/MDButton";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import FrmValidations from 'FrmGeneric/FrmValidations';


import { CircularProgress } from "@mui/material"; //Loader
import Select from 'react-select'; //Searchable Select Box
function FrmStockAgingReport() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;


    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);

    const [isLoading, setIsLoading] = useState(false);


    //Master Hooks
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [subDepartmentGroupOptions, setSubDepartmentGroupOptions] = useState([]);
    const [cmb_department_id, setDepartmentId] = useState('');
    const cmb_department_id_ref = useRef()
    const [cmb_subdepartment_group_id, setSubDepartmentGroupId] = useState('');
    const cmb_subdepartment_group_id_ref = useRef()

    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();

    useEffect(() => {
        const loadDataOnload = async () => {
            try {
                setIsLoading(true);
                await FnFillComboBoxes();
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        loadDataOnload()

    }, [])


    const FnFillComboBoxes = async () => {
        debugger
        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name',]
            globalQuery.conditions = [
                { field: "department_type", operator: "=", value: "M" },
                { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] },
                { field: "is_delete", operator: "=", value: 0 },
            ]
            globalQuery.table = "cmv_department";
            const deptOptions = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const departmentOptions = [
                { value: '', label: 'Select' },
                // { value: '0', label: 'Add New Record+' },
                ...deptOptions.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
            ];
            setDepartmentOptions(departmentOptions);

        } catch {

        }
    };

    const comboBoxesOnChange = async (key) => {
        try {
            switch (key) {
                case 'Department':
                    let department_val = cmb_department_id_ref.current.value;
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'parent_department_id', 'parent_department']
                    globalQuery.conditions = [
                        { field: "department_type", operator: "=", value: "S" },
                        { field: "parent_department_id", operator: "=", value: parseInt(department_val) },
                        { field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] },
                        { field: "is_delete", operator: "=", value: 0 },
                    ]
                    globalQuery.table = "cmv_department";
                    let subdeptOptions = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    const subdepartmentOptions = [
                        { value: '', label: 'Select' },
                        // { value: '0', label: 'Add New Record+' },
                        ...subdeptOptions.map((department) => ({
                            ...department, value: department.field_id, label: department.field_name,
                            parent_department_id: department.parent_department_id, parent_department: department.parent_department
                        })),
                    ];
                    setSubDepartmentGroupOptions(subdepartmentOptions);

                    break;
            }
        } catch {

        }
    }

    const FnChkIsValidFilters = () => {
        const fromDateObj = new Date($("#dt_from_date").val());
        const toDateObj = new Date($("#dt_to_date").val());

        let isValid = true;
        if (!fromDateObj) {
            $('#error_dt_from_date').text('From Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }
        if (!toDateObj) {
            $('#error_dt_to_date').text('To Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }
        if (fromDateObj && toDateObj && fromDateObj > toDateObj) {
            $('#error_dt_from_date').text('From Date cannot be greater than To Date.').show();
            isValid = false;
        } else if (fromDateObj && toDateObj) {
            $('#error_dt_from_date').hide();
        }
        return isValid;
    };

    const FnShowData = (() => {
        try {
            let department_val = cmb_department_id_ref.current.value;
            const fromDateVal = $("#dt_from_date").val();
            const toDateval = $("#dt_to_date").val();

            if (department_val !== undefined && department_val !== '') {
                $("#error_cmb_department_id").hide();
            } else {
                $("#error_cmb_department_id").text("Select Department").show();
            }
        } catch {

        }
    })

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />


                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Stock Aging Report</label>
                    </div>

                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <form id='stockAgingReportId'>
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); FnChkIsValidFilters() }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters() }} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            <div className='col-sm-4 erp_form_col_div'>


                                <div className='row  mb-1'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-6'>
                                        <Select ref={cmb_department_id_ref}
                                            options={departmentOptions}
                                            inputId="cmb_department_id" // Provide the ID for the input box
                                            value={departmentOptions.length > 1 ? departmentOptions.find(option => option.value === cmb_department_id) : null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                cmb_department_id_ref.current = selectedOpt;
                                                comboBoxesOnChange("Department");
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row  mb-1'>

                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Sub-Department
                                            {/* <span className="required">*</span> */}
                                        </Form.Label>

                                    </div>
                                    <div className='col-sm-6'>
                                        <Select ref={cmb_subdepartment_group_id_ref}
                                            options={subDepartmentGroupOptions}
                                            inputId="cmb_subdepartment_group_id" // Provide the ID for the input box
                                            value={subDepartmentGroupOptions.length > 1 ? subDepartmentGroupOptions.find(option => option.value === cmb_subdepartment_group_id) : null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentGroupId(selectedOpt.value)
                                                cmb_subdepartment_group_id_ref.current = selectedOpt;
                                                // comboBoxesOnChange("SubDepartment");
                                            }}
                                            placeholder="Search for a Sub-Department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_subdepartment_group_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>

                    <div className="row text-center mt-3">
                        <div className=''>
                            <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" variant="button" onClick={() => FnShowData()} fontWeight="regular">Show Data</MDButton>
                        </div>
                    </div>


                    <hr />
                    <div className='card p-1'>
                        <div className='card-header text-center'>
                            <label className='erp-form-label-lg text-center'>Report</label>
                        </div>
                    </div>

                </div>

            </DashboardLayout>
        </>
    )
}

export default FrmStockAgingReport