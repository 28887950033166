import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import GenericWorkerSalaryTable from '../MonthlySalaryRegister/GenericMonthlySalaryTable'

function WorkerMonthlySalaryRegisterNew() {

    // Global Constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const exlsExp = useRef();

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // ****** navigate
    const navigate = useNavigate();

    // combo's options hooks;
    const [employeeProps, setEmployeeProps] = new useState([]);
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);


    // Input field's Hooks;
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
    const [cmb_salary_month, setSalaryMonth] = useState('');
    const [cmb_salary_year, setSalaryYear] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');

    // Hooks For table;
    const [earningHeads, setEarningHeads] = useState([]);
    const [deductionHeads, setDeductionHeads] = useState([]);
    const [monthlySalarySummaryDtlTblData, setMonthlySalarySummaryDtlTblData] = useState([]);
    const [monthlySalaryEarningDtlTblData, setMonthlySalaryEarningDtlTblData] = useState([]);
    const [monthlySalaryDeductionDtlTblData, setMonthlySalaryDeductionDtlTblData] = useState([]);

    const [contractorOptions, setContractorOption] = useState([]);
    const [cmb_contractor_id, setContractorId] = useState('');

    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let salaryMonthComboRef = useRef(null);
    let salaryYearComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);
    const cmb_contractor_id_ref = useRef()

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnFillComobos = async () => {
        try {
            // Set Default Year;
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setSalaryYear(defaultYear.value);
            salaryYearComboRef.current = defaultYear;
            // Set Default Month;
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setSalaryMonth(defaultMonth.value);
            salaryMonthComboRef.current = defaultMonth;

            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "property_group", operator: "=", value: "W" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeType')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    // subDepartments.unshift({ value: 'All', label: 'All' });
                    // setSubDepartmentOpts(subDepartments);
                });

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"]
            globalQuery.table = "cmv_contractor"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: "0" });
            comboDataFunc.current.fillFiltersCombo(globalQuery).then((contractorList) => {
                // combobox.current.fillMasterData("cmv_contractor", "", "").then((contractorList) => {
                const contractors = [
                    { value: '', label: 'Select' },
                    ...contractorList.map((contractor) => ({ ...contractor, value: contractor.field_id, label: contractor.field_name, })),
                ];
                setContractorOption(contractors);
            })

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnComboOnChange = async (comboName) => {
        try {
            switch (comboName) {
                case "Department":
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                    setSubDepartmentId('All');
                    break;

                case "EmployeeType":
                    let selectedEmployeeType = employeeTypesComboRef.current.field_name;
                    let contractorId = cmb_contractor_id_ref.current.value;
                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name","old_employee_code","job_type_rate"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmployeeType !== null && selectedEmployeeType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type", operator: "=", value: selectedEmployeeType });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                        globalQuery.conditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
                    }
                    if (contractorId !== undefined && contractorId !== null && contractorId !== '') {
                        globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractorId });
                    }

                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, label: `[${prop.old_employee_code}] [${prop.employee_code}] ${prop.employee_name}` }));
                            rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
                    setEmployeeId('All');
                    break;
                case "Contractor":
                    let selectedEmployeeTypes = employeeTypesComboRef.current.field_name;
                    let contractorIds = cmb_contractor_id_ref.current.value;
                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name","old_employee_code","job_type_rate"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmployeeTypes !== null && selectedEmployeeTypes !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type", operator: "=", value: selectedEmployeeTypes });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                        globalQuery.conditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
                    }
                    if (contractorIds !== undefined && contractorIds !== null && contractorIds !== '') {
                        globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractorIds });
                    }

                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, label: `[${prop.old_employee_code}] [${prop.employee_code}] ${prop.employee_name}` }));
                            rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
                    setEmployeeId('All');
                    break;
                default:
                    break;
            }

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    // ****-------Get Salary Data Functions Starts--------------***
    const FnShowSalaryData = async () => {

        try {

            // Earning Heads To not display;
            // "Arrear Paid","Travelling Allowance",
            const excludedEarningHeadsCols = ["Salary", "Dearness Allowance", "HRA", "Conveyance Allowance", "Special Allowance",
                "Incentive Allow", "Medical Allowance", "Communication Allowance",
                "Education Allowance", "Performance Bonus", "Referral Allowance", "LTA", "Gratuity", "Bonus", "Over Time",
                "C-OFF Allowance"];
            // Deduction Heads To not display;
            const excludedDeductionHeadsCols = ['E-PF1', 'E-PF2', 'TDS', 'Income Tax'];

            let isValidFilters = await FnChkIsValidFilters();
            if (!isValidFilters) { return false; }

            // *** Means Filters are valid then filter data as per the filters.
            setIsLoading(true);
            // let selectedEmplGrp = employeeProps.find(prop => prop.properties_master_name === 'EmployeeTypeGroup' && prop.property_value === employeeTypesComboRef.current.property_group);
            // Load Earning Heads
            resetGlobalQuery();
            globalQuery.columns = ["earning_heads_id", "earning_head_name", "head_position", "earning_head_short_name"];
            globalQuery.table = "hm_earning_heads";
            globalQuery.conditions.push({ field: "earning_group", operator: "IN", values: ['All', 'Workers'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["head_position Asc"];
            const rcvdEarningHeads = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            let filteredEarningHeads = rcvdEarningHeads.filter(earning => !excludedEarningHeadsCols.includes(earning.earning_head_name));
            setEarningHeads(filteredEarningHeads);

            // Load Deduction Heads
            resetGlobalQuery();
            globalQuery.columns = ["deduction_heads_id", "deduction_head_name", "head_position", "deduction_head_short_name"];
            globalQuery.table = "hm_deduction_heads";
            globalQuery.conditions.push({ field: "deduction_group", operator: "IN", values: ['All', 'Workers'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["head_position Asc"];
            const rcvdDeductionHeads = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            let filteredDeductionHeads = rcvdDeductionHeads.filter(deduction => !excludedDeductionHeadsCols.includes(deduction.deduction_head_name));
            setDeductionHeads(filteredDeductionHeads);

            let selectedYear = salaryYearComboRef.current?.value || null;
            let selectedMonth = salaryMonthComboRef.current?.value || null;
            let selectedDepartment = departmentComboRef.current?.department_id || null;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
            let selectedEmplType = employeeTypesComboRef.current?.field_name || null;
            let selectedEmployee = employeeComboRef.current?.employee_id || null;
            let contractorId = cmb_contractor_id_ref.current.value;

            let filterConditions = [
                { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "is_delete", operator: "=", value: 0 },
            ];

            if (selectedYear !== null) { filterConditions.push({ field: "salary_year", operator: "=", value: selectedYear }); }
            if (selectedMonth !== null) { filterConditions.push({ field: "salary_month", operator: "=", value: selectedMonth }); }

            if (selectedDepartment !== null && selectedDepartment !== '') {
                filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
            } else {
                let defaultDept = departmentOpts.find(department => department.value == 'All');
                setDepartmentId(defaultDept.value);
                departmentComboRef.current = defaultDept;
            }

            if (selectedSubDepartment !== null && selectedSubDepartment !== '') {
                filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
            } else {
                const subDepartmentList = allDepartmentsList.filter(department =>
                    (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                );
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
            }

            if (selectedEmplType !== null && selectedEmplType !== 'All') {
                filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
            } else {
                let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                setEmployeeTypeId(defaultEmplType.value);
                employeeTypesComboRef.current = defaultEmplType;
                let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                filterConditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
            }

            if (selectedEmployee !== null && selectedEmployee !== '') {
                filterConditions.push({ field: "employee_id", operator: "=", value: selectedEmployee });
            } else {
                let selectedEmployeeType = employeeTypesComboRef.current.label;
                resetGlobalQuery();
                globalQuery.columns = ["employee_id", "employee_code", "employee_name","old_employee_code","job_type_rate"];
                globalQuery.table = "cmv_employee"
                if (selectedEmployeeType !== null && selectedEmployeeType !== 'All') {
                    globalQuery.conditions.push({ field: "employee_type", operator: "=", value: selectedEmployeeType });
                } else {
                    let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                    setEmployeeTypeId(defaultEmplType.value);
                    employeeTypesComboRef.current = defaultEmplType;
                    let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                    globalQuery.conditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
                }
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                if (contractorId !== undefined && contractorId !== null && contractorId !== '') {
                    globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractorId });
                }
                comboDataFunc.current.fillFiltersCombo(globalQuery)
                    .then(rcvdEmpls => {
                        rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, label: `[${prop.old_employee_code}] [${prop.employee_code}] ${prop.employee_name}` }));
                        rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                        setEmployeeOpts(rcvdEmpls);
                    });
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
                setEmployeeId('All');


                if (selectedEmployee === null && contractorId !== undefined && contractorId !== null && contractorId !== '') {
                    const employeeIds = employeeOpts
                        .filter(emp => emp.employee_id !== "") // Remove entries where employee_id is an empty string
                        .map(emp => emp.employee_id);

                    if (employeeIds.length === 1) {
                        // If only one employee_id, use "=" operator with a single value
                        filterConditions.push({
                            field: "employee_id",
                            operator: "=",
                            value: employeeIds[0] // Single value, no need for join
                        });
                    } else if (employeeIds.length > 1) {
                        // If multiple employee_ids, use "IN" with a comma-separated string
                        filterConditions.push({
                            field: "employee_id",
                            operator: "IN",
                            values: employeeIds.map(id => (isNaN(id) ? `"${id}"` : id)) // Enclose non-numeric IDs in quotes
                        });
                    }
                }
            }


            // Get the Salary Summary Data;
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_year", "salary_month", "department_name", "sub_department_name", "employee_type",
                "employee_name", "employee_code","old_employee_code","skill_type","job_type_rate", "designation_name", "month_days", "salary_days", "monthly_salary",
                "worked_hours", "salary_perday", "salary_perhour",
                "gross_salary", "total_deduction", "net_salary", "salary_transaction_id", "employee_id",
                "department_id", "sub_department_id", "designation_id", "company_id", "company_branch_id",
            ];
            globalQuery.table = "htv_salary_summary_new";
            globalQuery.conditions = [...filterConditions];
            globalQuery.conditions.push({ field: "net_salary", operator: ">", value: 0 });
            // if (contractorId !== undefined && contractorId !== null && contractorId !== '') {
            //     globalQuery.conditions.push({ field: "contractor_id", operator: "=", value: contractorId });
            // }

            globalQuery.orderBy = ['department_id DESC', 'sub_department_id DESC', 'employee_code Asc'];
            const recvdSummaryData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setMonthlySalarySummaryDtlTblData(recvdSummaryData);

            // Get the Salary Earning Data;
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_earnings_id", "company_id", "company_branch_id", "salary_transaction_id",
                "salary_version", "salary_month", "salary_year", "department_id", "sub_department_id",
                "employee_type", "employee_id", "designation_id", "earning_heads_id", "earning_amount"
            ];
            globalQuery.table = "ht_salary_earnings_new";
            globalQuery.conditions = filterConditions;
            const recvdEarningData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setMonthlySalaryEarningDtlTblData(recvdEarningData);

            // Get the Salary Deduction Data;
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_deduction_id", "company_id", "company_branch_id", "salary_transaction_id",
                "salary_version", "salary_month", "salary_year", "department_id", "sub_department_id",
                "employee_type", "employee_id", "designation_id", "deduction_heads_id", "deduction_amount"
            ];
            globalQuery.table = "ht_salary_deduction_new";
            globalQuery.conditions = filterConditions;
            const recvdDeductionData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setMonthlySalaryDeductionDtlTblData(recvdDeductionData);

            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // ***----------- Helping Functions.
    const goBack = () => {
        navigate("/Dashboard")
    }

    // Get Current DateTime
    const FnGetCurrentDateTime = () => {
        let currentDate = new Date(); // Get the current date and time
        // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }

    // ****-------Validation Functions Starts--------------***
    // Function for validate the fields at the time of button click;
    const FnChkIsValidFilters = async () => {
        if (cmb_salary_year === '') {
            $('#error_cmb_salary_year').text('Please select the atleast one...!')
            $('#error_cmb_salary_year').show();
            return false;
        } else {
            $('#error_cmb_salary_year').hide();
        }

        if (cmb_salary_month === '') {
            $('#error_cmb_salary_month').text('Please select the atleast one...!')
            $('#error_cmb_salary_month').show();
            return false;
        } else {
            $('#error_cmb_salary_month').hide();
        }
        return true;
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {
        setMonthlySalarySummaryDtlTblData([]);
        // setSalaryProcessingDtlTblData([]);
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
        if (isEmpty(salaryMonthComboRef.current?.value)) { $('#error_cmb_salary_month').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    // ****-------Validation Functions Ends--------------***


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintMonthlySalarySheet = useReactToPrint({
        content: () => {
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // contentWrapper.style.boxSizing = 'border-box';
            // contentWrapper.style.position = 'relative';
            // // contentWrapper.style.width = 'calc(100% - 4%)';
            // contentWrapper.style.height = 'calc(100% - 4%)';
            // contentWrapper.style.border = '1px solid black';
            // contentWrapper.style.pageBreakAfter = 'always';

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Worker Salary Sheet For ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Worker Salary Sheet
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                For Month: {salaryMonthComboRef.current?.label}   Year: {salaryYearComboRef.current?.value}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    // ********* PrintOut Functionalities Ends. ****************************************

    // ********* ExportToExcel Functionalities Starts. ****************************************
    const addExcelFilter = (label, key, value, existingFilters) => {
        if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
    };

        // For Excel GRAND TOTAL
        const getTotal = () => {
            const totalObj = {
                gross_salary: 0,
                total_deduction: 0,
                net_salary: 0,
                total_basic: 0,
                prof_tax: 0,
                totalAttendAllow: 0,
                totalNightAllow: 0,
                totalOtherAllow: 0,
                totalArrearPaid: 0,
                totalPF: 0,
                totalCanteen: 0,
                totalLightBill:0,
                totalVenderCanteen: 0,
                totalHouseRent: 0,
                totalAdvance: 0,
                totalOtherDeduct: 0,
                totalArrearDeduct: 0,
                totalFine: 0
            };
        
            monthlySalarySummaryDtlTblData.forEach(summary => {
                totalObj.gross_salary += summary.gross_salary || 0;
                totalObj.total_deduction += summary.total_deduction || 0;
                totalObj.net_salary += summary.net_salary || 0;
                totalObj.total_basic += summary["Basic Salary"] || 0;
                totalObj.prof_tax += summary["Prof Tax"] || 0;
                totalObj.totalAttendAllow += summary["Attend Allow"] || 0;
                totalObj.totalNightAllow += summary["Night Allow"] || 0;
                totalObj.totalOtherAllow += summary["Other Allowance"] || 0;
                totalObj.totalArrearPaid += summary["Arrear Paid"] || 0;
                totalObj.totalPF += summary["PF"] || 0;
                totalObj.totalCanteen += summary["Canteen"] || 0;
                totalObj.totalVenderCanteen += summary["Vender Canteen"] || 0;
                totalObj.totalHouseRent += summary["House Rent"] || 0;
                totalObj.totalLightBill += summary["Light Bill"] || 0;
                totalObj.totalAdvance += summary["Advance"] || 0;
                totalObj.totalOtherDeduct += summary["Other Deduct"] || 0;
                totalObj.totalArrearDeduct += summary["Arrear Deduct"] || 0;
                totalObj.totalFine += summary["Fine"] || 0;
            });
        
            // Format totals to 2 decimal places
            for (const key in totalObj) {
                totalObj[key] = parseFloat(totalObj[key].toFixed(2));
            }
        
            return totalObj;
        };
        


    const FnExportToExcel = async () => {
        // Means No Data For Export. 
        if (monthlySalarySummaryDtlTblData.length === 0) { return null; }
        setIsLoading(true);

        // Preparing Data For ExportFile.
        let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

        // Excel Headers;
        jsonToExportExcel['headings'] = {
            'ReportName': `Worker Salary Sheet ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`,
            'CompanyName': COMPANY_NAME,
            'CompanyAddress': sessionStorage.getItem('companyAddress'),
        }

        let appliedFilters = {
            '0': `Year: ${salaryYearComboRef.current?.label || ""}`,
            '1': `Month: ${salaryMonthComboRef.current?.label}(${salaryMonthComboRef.current?.value || ""})`,
        };
        addExcelFilter(departmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Department', appliedFilters);
        addExcelFilter(subDepartmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Sub Department', appliedFilters);
        addExcelFilter(employeeTypesComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Employee Type', appliedFilters);
        addExcelFilter(employeeComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Employee Name', appliedFilters);
        appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
        jsonToExportExcel['filtrKeyValue'] = appliedFilters;

        // Details Table Columns and Accessors.
        const earningsCols = earningHeads.map(({ earning_head_name }) => ({ "Headers": earning_head_name, "accessor": earning_head_name })); // Earning Headers & Accessors
        const deductionsCols = deductionHeads.map(({ deduction_head_name }) => ({ "Headers": deduction_head_name, "accessor": deduction_head_name })); // Deductions Headers & Accessors
        jsonToExportExcel['columns'] = [
            { "Headers": 'Sr. No.', "accessor": 'sr_no' },
            { "Headers": 'Department', "accessor": 'department_name' },
            { "Headers": 'Sub Department', "accessor": 'sub_department_name' },
            // { "Headers": 'Employee Code', "accessor": 'employee_code' },
            { "Headers": 'Employee Code', "accessor": 'old_employee_code' },
            { "Headers": 'Employee Name', "accessor": 'employee_name' },
            { "Headers": 'Designation', "accessor": 'designation_name' },
            { "Headers": 'Working Days', "accessor": 'salary_days' },
            { "Headers": 'Working Hours', "accessor": 'worked_hours' },
            { "Headers": 'PerDay', "accessor": 'salary_perday' },
            { "Headers": 'PerHour', "accessor": 'salary_perhour' },
            ...earningsCols,
            { "Headers": 'Gross Salary', "accessor": 'gross_salary' },
            ...deductionsCols,
            { "Headers": 'Total Deduction', "accessor": 'total_deduction' },
            { "Headers": 'Net Salary', "accessor": 'net_salary' }
        ];

        monthlySalarySummaryDtlTblData.map((summary, index) => {
            let allEarnings = monthlySalaryEarningDtlTblData.filter(detail => detail.employee_id === summary.employee_id);
            let allDeductions = monthlySalaryDeductionDtlTblData.filter(detail => detail.employee_id === summary.employee_id);

            earningHeads.forEach(earningType => {
                const currentEarning = allEarnings.find(earning => earning.earning_heads_id === earningType.earning_heads_id);
                summary[earningType.earning_head_name] = currentEarning ? currentEarning.earning_amount : 0;
            });

            deductionHeads.forEach(deductionType => {
                const currentDeduction = allDeductions.find(deduction => deduction.deduction_heads_id === deductionType.deduction_heads_id);
                summary[deductionType.deduction_head_name] = currentDeduction ? currentDeduction.deduction_amount : 0;
            });

            summary.sr_no = index + 1   // Push SR.NO.
            jsonToExportExcel['allData'][index] = summary;

            // For Excel GRAND TOTAL
            const lastIndex = Object.keys(jsonToExportExcel.allData).length - 1

            if (lastIndex === index) {
                const totalObj = getTotal();
            
                jsonToExportExcel['allData'][lastIndex + 1] = {
                    department_name: "GRAND TOTAL",
                    ...totalObj,
                    "Basic Salary": totalObj.total_basic,
                    "Prof Tax": totalObj.prof_tax,
                    "Attend Allow": totalObj.totalAttendAllow,
                    "Night Allow": totalObj.totalNightAllow,
                    "Other Allowance": totalObj.totalOtherAllow,
                    "Arrear Paid": totalObj.totalArrearPaid,
                    "PF": totalObj.totalPF,
                    "Canteen": totalObj.totalCanteen,
                    "Vender Canteen": totalObj.totalVenderCanteen,
                    "House Rent": totalObj.totalHouseRent,
                    "Light Bill":totalObj.totalLightBill,
                    "Advance": totalObj.totalAdvance,
                    "Other Deduct": totalObj.totalOtherDeduct,
                    "Arrear Deduct": totalObj.totalArrearDeduct,
                    "Fine": totalObj.totalFine
                };
            }
            
        });

        // Call Common function for ExportToExcel
        await exlsExp.current.excel(jsonToExportExcel, `Worker_Salary_Sheet_Of_${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`);
        setIsLoading(false);
    }
    // ********* ExportToExcel Functionalities Ends. ****************************************

    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Worker Monthly Salary Register</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryYearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_salary_year"
                                            value={yearOpts.find(option => option.value == cmb_salary_year) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryYear(selectedOpt.value);
                                                salaryYearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryMonthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_salary_month"
                                            value={monthOpts.find(option => option.value == cmb_salary_month) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryMonth(selectedOpt.value);
                                                salaryMonthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className='row mt-1'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Contractor</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Select ref={cmb_contractor_id_ref}
                                            options={contractorOptions}
                                            inputId="cmb_contractor_id" // Provide the ID for the input box
                                            value={contractorOptions.length > 1 ? contractorOptions.find(option => option.value === cmb_contractor_id) : null}
                                            onChange={(selectedOpt) => {
                                                cmb_contractor_id_ref.current = selectedOpt;
                                                setContractorId(selectedOpt.value)
                                                FnComboOnChange('Contractor')
                                            }}
                                            placeholder="Search for a contractor..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_contractor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnComboOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnComboOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowSalaryData(); }} variant="button" fontWeight="regular">Show Data</MDButton>
                            </div>
                        </div>

                        {
                            monthlySalarySummaryDtlTblData.length === 0
                                ? <>
                                    <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <>
                                    <hr />

                                    <div className="row py-1">
                                        <div className="text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }} >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { FnPrintMonthlySalarySheet(); }} >Print</MDButton>
                                        </div>
                                    </div>

                                    {/* <div className="row print-content" ref={printComponentRef} style={{ width: '100%' }}>
                                        <WorkerSalaryComponent
                                            summaryData={monthlySalarySummaryDtlTblData}
                                            earningsDtls={monthlySalaryEarningDtlTblData}
                                            deductionsDtls={monthlySalaryDeductionDtlTblData}
                                            earningHeads={earningHeads}
                                            deductionHeads={deductionHeads}
                                        />
                                    </div> */}

                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            <div className="card-header py-0 main_heding mb-0">
                                                <label className="erp-form-label-md-lg">Employee's Salary Details</label>
                                            </div>
                                            {/* Card's Body Starts*/}
                                            <div className="card-body px-2 print-content" ref={printComponentRef}>
                                                <WorkerSalaryComponent
                                                    summaryData={monthlySalarySummaryDtlTblData}
                                                    earningsDtls={monthlySalaryEarningDtlTblData}
                                                    deductionsDtls={monthlySalaryDeductionDtlTblData}
                                                    earningHeads={earningHeads}
                                                    deductionHeads={deductionHeads}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                {
                                    monthlySalarySummaryDtlTblData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintMonthlySalarySheet(); }} >Print </MDButton>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}

                </div>
                {/* Card Ends*/}

            </DashboardLayout>
        </>
    )
}
export default WorkerMonthlySalaryRegisterNew;



export const WorkerSalaryComponent = ({ summaryData, earningsDtls, deductionsDtls, earningHeads, deductionHeads }) => {
    // Function to generate GenericMonthlySalaryTable components
    const renderMonthlySalaryTables = () => {
        // Use reduce to find unique sub-departments based on sub-department_id
        const uniqueSubDepartments = summaryData.reduce((unique, current) => {
            // Check if the sub-department doesn't already exist in unique array
            if (!unique.some(item => item.sub_department_id === current.sub_department_id)) {
                unique.push(current);
            }
            return unique;
        }, []);

        return uniqueSubDepartments?.map((subDepartment, index) => {
            const subDepartmentId = subDepartment.sub_department_id;

            // Filtered data based on department filter
            const filteredAndSortedSalarySummary = summaryData
                .reduce((acc, item) => {
                    if (item.sub_department_id === subDepartmentId) { acc.push(item); }
                    return acc;
                }, [])
                .sort((a, b) => (a.employee_code < b.employee_code ? -1 : (a.employee_code > b.employee_code ? 1 : 0)));
            const filteredEarnings = earningsDtls.filter(item => item.sub_department_id === subDepartmentId);
            const filteredDeductions = deductionsDtls.filter(item => item.sub_department_id === subDepartmentId);

            return (
                <>
                    <GenericWorkerSalaryTable key={index}
                        earningHeads={earningHeads}
                        deductionHeads={deductionHeads}
                        salarySummaryDtlTblData={filteredAndSortedSalarySummary}
                        salaryEarningDtlTblData={filteredEarnings}
                        salaryDeductionDtlTblData={filteredDeductions}
                        departmentName={subDepartment.department_name}
                        subDepartmentName={subDepartment.sub_department_name}
                        isLastTable={index === uniqueSubDepartments.length - 1}    // Means it is the last table or not?
                        allDataForGrandTotal={{
                            allEmployeesSummery: summaryData,
                            allEmployeesEarnings: earningsDtls,
                            allEmployeesDeductions: deductionsDtls,
                        }}
                    />


                </>
            );
        });
    };
    return (
        <div>
            {renderMonthlySalaryTables()}
        </div>
    );
};



