import React, { useState, useEffect, useRef, useMemo} from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import { Form, Table } from "react-bootstrap";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDButton from "components/MDButton";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { RxCrossCircled } from "react-icons/rx";
import { Modal } from 'react-bootstrap';

import $ from 'jquery';
import MDTypography from "components/MDTypography";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import { CircularProgress } from "@material-ui/core";

import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import ComboBox from "Features/ComboBox";
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';


import ConfigConstants from "assets/Constants/config-constant";
import { useNavigate } from "react-router-dom";


function FrmWeavingDailyProductionReportEntry() {

  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  // *********** Get Data from listing Page **********
  const { state } = useLocation();
  const { InspectonProdID = 0, keyForViewUpdate = 'Add', compType = 'Transaction' } = state || {}

  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

  const [actionType, setActionType] = useState('(Add)');
  const [isLoading, setIsLoading] = useState(false);


  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    navigate('/Transactions/TWeavingDailyProductionReport/TWeavingDailyProductionReportListing')
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  //useRef Hooks
  const navigate = useNavigate();
  const importFile = useRef();
  const validate = useRef();
  const comboDataAPiCall = useRef();
  const validateNumberDateInput = useRef();
  const generateAutoNoAPiCall = useRef();

  //Hooks for Form Elements
  const [dt_daily_production_date, setDailyProdDt] = useState(today());
  const [weaving_production_inspection_master_id, setProductionMasterDetailsId] = useState(InspectonProdID);
  const [txt_production_code, setProductionCode] = useState();
  const [txt_file_name, setFileName] = useState();
  const [dailyproductionreportdata, setDailyProductionReportData] = useState([]);


  const [showFileModal, setShowFileModal] = useState(false);
  const fileModalClose = () => {
    setShowFileModal(false);
  }


  ////Declaring Emptyy Object
  let EmptyObject = {
    'inspection_production_date': today(),
    'sizing_beam_no': '',
    'sort_no': '',
    'inspection_production_set_no': '',
    'total_no_of_ends': '',
    'machine_id': '',
    'roll_no': '',
    'width': '',
    'product_pick': '',
    'product_in_meter': '',
    'difference': '',
    'inspection_mtr': '',
    'weight': '',
    'average': ''
  }

  //Rendering Table with EmptyObject
  // useLayoutEffect(() => {
  //   let emptyArrObj = [EmptyObject];
  //   setDailyProductionReportData(emptyArrObj);
  // }, []);


  useEffect(async () => {
    setIsLoading(true);
    if (keyForViewUpdate !== 'Add') {
      await FnCheckUpdate();
    }
    setIsLoading(false);
  }, [])

  const FnCheckUpdate = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns = ['*'];
      globalQuery.table = "xtv_weaving_production_inspection_details"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "weaving_production_inspection_master_id", operator: "=", value: weaving_production_inspection_master_id });
      let getDailyProductionDetailsData = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setDailyProdDt(getDailyProductionDetailsData[0].inspection_production_date);
      setProductionCode(getDailyProductionDetailsData[0].inspection_production_code);
      setDailyProductionReportData(getDailyProductionDetailsData);

      if (keyForViewUpdate === 'view') {
        setActionType('(View)');
        $(".view_hide").hide();
        $("#dt_daily_production_date").attr('disabled', true);
        $('#renderProductionDailyReportTableId input').attr('disabled', true);

      }

    } catch (error) {

    }
  }

  /// function for Generate weaving Inspection Code
  useEffect(async () => {
    debugger
    try {
      const productionDate = $('#dt_daily_production_date').val();
      if (keyForViewUpdate === 'Add' && productionDate !== '') {
        const [year, month, day] = productionDate.split('-');
        const formatted_date = `${day}${month}${year.slice(2)}`;
        const weavingInspectionCode = await generateAutoNoAPiCall.current.generateProductionCode({
          "entity_name": "xt_weaving_production_inspection_master",
          "sub_section": '18',
          "sub_section_short_name": 'INP',
          "field_name": "inspection_production_code",
          "trans_no_length": "4",
          "transaction_date": formatted_date,
          "company_id": COMPANY_ID
        });
        setProductionCode(weavingInspectionCode);
      }
    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')
    }
  }, [dt_daily_production_date]);


  const onImportClick = () => {
    importFile.current.click();
  };


  const importedExcelFile = async (event) => {
debugger
    try {
      setIsLoading(true);
      let dailyproductionDetails = [...dailyproductionreportdata];

      const file = event.target.files[0];
      const fileType = file.type;

      const fileName = file.name;


      //Checking filename in database
      resetGlobalQuery();
      globalQuery.columns = ['*'];
      globalQuery.table = "xt_weaving_production_inspection_master"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "file_name", operator: "=", value: fileName });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      let getAPICallFileName = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      if (getAPICallFileName.length > 0) {
        setShowFileModal(true);
      } else {
        // Validate the file type
        const validTypes = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
          "application/vnd.ms-excel" // .xls
        ];

        if (validTypes.includes(fileType)) {
          $("#error_Excelfile").hide();
          setFileName(fileName);

          const reader = new FileReader();

          // Function to format XLSX date format into 'yyyy-mm-dd'
          const formattedFirstCell = (cell) => {
            if (typeof cell === 'number' && cell > 0 && cell < 60000) {
              const date = XLSX.SSF.parse_date_code(cell);
              if (date) {
                const day = String(date.d).padStart(2, '0');
                const month = String(date.m).padStart(2, '0');
                return `${date.y}-${month}-${day}`;
              }
            }
            return cell;
          };

          const accessorskeysObj = [
            { Headers: "SR NO", key: "sr_no" },
            { Headers: "INSP. DATE", key: "inspection_production_date" },
            { Headers: "GREIGE CHACKER NAME", key: "machine_operator_name" },
            { Headers: "BEAM NO", key: "sizing_beam_no" },
            { Headers: "SORT NO", key: "sort_no" },
            { Headers: "SET NO", key: "inspection_production_set_no" },
            { Headers: "TOTAL ENDS", key: "total_no_of_ends" },
            { Headers: "Loom no ", key: "machine_id" },
            { Headers: "Roll No. ", key: "roll_no" },
            { Headers: "Width ", key: "width" },
            { Headers: "PICK", key: "product_pick" },
            { Headers: "LOOMS MTR", key: "product_in_meter" },
            { Headers: "DIFFRANCE", key: "difference" },
            { Headers: "INSPECTION Mtr", key: "inspection_mtr" },
            { Headers: "WEIGHT", key: "weight" },
            { Headers: "AVERAGE", key: "average" }
          ];

          const processData = () => {
            return new Promise((resolve, reject) => {

              reader.onload = (e) => {
                setIsLoading(true);
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });

                const sheetName = workbook.SheetNames[0]; // Get the first sheet
                const worksheet = workbook.Sheets[sheetName];

                // Convert the sheet to JSON
                const rawData = XLSX.utils.sheet_to_json(worksheet, {
                  header: 1,
                  raw: true,
                });

                let finalJsonArr = [];

                // Assuming that the headers are in the 2nd row (index 1)
                let indexOfHeaderXLXSSheet = rawData.findIndex((row) => {
                  return row.includes('WEIGHT') && row.includes('SORT NO') && row.includes('AVERAGE');
                });


                rawData.map((row, rowIndex) => {
                  console.log(rowIndex + " Data" + row);
                  if (rowIndex > indexOfHeaderXLXSSheet) { // Skip header row
                    let emptyJsonObject = {};

                    for (let colIndex = 0; colIndex < row.length; colIndex++) { // Fixed the loop condition
                      const header = rawData[indexOfHeaderXLXSSheet][colIndex];
                      const cell = row[colIndex]; // Get the cell value at the current column
                      const keyAccessor = accessorskeysObj.find((keyaccessor) => keyaccessor.Headers === header);

                      if (keyAccessor) {
                        if (keyAccessor.Headers === "INSP. DATE") {
                          emptyJsonObject[keyAccessor.key] = formattedFirstCell(cell);
                        } else if (keyAccessor.Headers === "AVERAGE") {
                          emptyJsonObject[keyAccessor.key] = validateNumberDateInput.current.decimalNumber(cell, 4);
                        } else {
                          emptyJsonObject[keyAccessor.key] = cell !== undefined ? cell : ""; // Handle empty cells
                        }
                      }
                    }

                    finalJsonArr.push(emptyJsonObject);
                  }
                });

                console.log(finalJsonArr);

                let dailyproductiondetailsJsonArr = [...finalJsonArr, ...dailyproductionDetails];
                setDailyProductionReportData(dailyproductiondetailsJsonArr);
                $("#import_btn").attr('disabled', true);
                setIsLoading(false);
              };

            });
          };

          reader.readAsBinaryString(file);

          // Clear the file input after processing
          const processedData = await processData();

          event.target.value = "";

        } else {
          $('#error_Excelfile').text("Invalid file type. Please upload an Excel file (.xlsx or .xls).").show();
          return;
        }
      }
    } catch (error) {
      navigate('/Error')
    } finally {
      setIsLoading(false);

    }
  };

  const addremoveRow = (action, rowIndex) => {
    try {
      setIsLoading(true)
      let dailyproductionDetails = [...dailyproductionreportdata];

      switch (action) {
        case 'add':
          setDailyProductionReportData([...dailyproductionDetails, EmptyObject]);
          setTimeout(() => {
            const lastRow = $('#renderProductionDailyReportTableId tr:last');
            lastRow.find('input').first().focus();
          }, 0);
          break;
        case 'remove':
          $(`#tablerowid_${rowIndex} input`).each(function () {
            delete this.parentElement.dataset.tip;
          });
          dailyproductionDetails.splice(rowIndex, 1);

          if (dailyproductionDetails.length > 0) {
            setDailyProductionReportData(dailyproductionDetails);
          } else {
            // If the last row is removed, add an empty object to keep the array non-empty
            setDailyProductionReportData([EmptyObject]);
          }
          break;
      }

    } catch (error) {
      navigate('/Error')

    } finally {
      setIsLoading(false)
    }
  }


  const renderProductionDailyReportTable = useMemo(() => {
    return <Table id="renderProductionDailyReportTableId" style={{ display: "block", overflow: "auto", height: dailyproductionreportdata.length > 20 ? '500px' : 'auto' }}
      bordered striped>
      <thead className="erp_table_head">
        <tr>
          <td className="erp_table_th view_hide" style={{ width: "100px", }}>Action</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Inspection Date</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Beam No.</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Sort No.</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Set No.</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Total Ends</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Loom No.</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Roll No.</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Width</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Pick</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Looms Meter</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Difference</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Inspection Meter</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Weight</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Average</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Dispatch < br></br>Quantity</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Dispatch < br></br>Weight</td>
          <td className="erp_table_th" style={{ width: "100px", }}>Weaving Prod. <br></br>Order Status</td>
        </tr>
      </thead>
      <tbody>
        {dailyproductionreportdata?.map((item, index) => (
          <tr rowindex={index} id={`tablerowid_${index}`}>
            <td className={`erp_table_th view_hide`}>
              <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => addremoveRow('add', index)} />
              <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => addremoveRow('remove', index)} />
            </td>

            <td className="erp_table_td">
              <input
                type="date"
                className="erp_input_field mb-0"
                Headers="inspection_production_date"
                style={{ backgroundColor: '#e9ecef' }}
                id={`inspection_production_date_${index}`}
                value={item.inspection_production_date}
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                className="erp_input_field_table_txt mb-0 "
                id={`sizing_beam_no_${index}`}
                Headers='sizing_beam_no' value={item.sizing_beam_no}
                onInput={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text" className="erp_input_field_table_txt mb-0" id={`sort_no_${index}`} value={item.sort_no}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                onChange={(e) => { FnUpdateDailyProductionReportTbl(item, e, index); }} Headers='sort_no' />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`inspection_production_set_no_${index}`}
                value={item.inspection_production_set_no}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='inspection_production_set_no'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`total_no_of_ends_${index}`}
                value={item.total_no_of_ends}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='total_no_of_ends'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`machine_id_${index}`}
                value={item.machine_id}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='machine_id'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`roll_no_${index}`}
                value={item.roll_no}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='roll_no'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`width_${index}`}
                value={item.width}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='width'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`product_pick_${index}`}
                value={item.product_pick}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='product_pick'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`product_in_meter_${index}`}
                value={item.product_in_meter}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='product_in_meter'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`difference_${index}`}
                value={item.difference}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='difference'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`inspection_mtr_${index}`}
                value={item.inspection_mtr}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='inspection_mtr'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`weight_${index}`}
                value={item.weight}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='weight'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>

            <td key={index} className="erp_table_td" >
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`average_${index}`}
                value={item.average}
                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                headers='average'
                onChange={(e) => FnUpdateDailyProductionReportTbl(item, e, index)}
              />
            </td>
            <td className="erp_table_td" style={{ width: '80px' }}>{item.dispatch_quantity}</td>
            <td className="erp_table_td" style={{ width: '80px' }}>{item.dispatch_weight}</td>
            <td className="erp_table_td" style={{ width: '80px' }}>{item.inspection_production_status_desc}</td>
          </tr>
        ))}
      </tbody>
    </Table>

  }, [dailyproductionreportdata]);

  const FnUpdateDailyProductionReportTbl = (rowData, e, index) => {
    try {
      setIsLoading(true);
      let dailyproductionDetails = [...dailyproductionreportdata];
      let clickedColName = e.target.getAttribute('Headers');
      let eventId = document.getElementById(e.target.id);
      let enteredValue = e.target.value;

      // if (enteredValue === '') {
      //   enteredValue = 0;
      // }

      delete eventId.parentElement.dataset.tip;

      if (clickedColName === 'sort_no') {
        rowData[clickedColName] = enteredValue;
      } else if (clickedColName === 'inspection_production_date') {
        rowData[clickedColName] = enteredValue;
      } else if (clickedColName === 'weight' || clickedColName === 'inspection_mtr') {
        rowData[clickedColName] = validateNumberDateInput.current.decimalNumber((enteredValue), 4);
        let average = validateNumberDateInput.current.decimalNumber((parseFloat(rowData['weight']) / parseFloat(rowData['inspection_mtr']) * 1000), 4);
        rowData['average'] = average === Infinity || isNaN(average) ? 0 : average
      }
      else {
        rowData[clickedColName] = validateNumberDateInput.current.decimalNumber((enteredValue), 4);
      }

      dailyproductionDetails[index] = rowData;
      setDailyProductionReportData(dailyproductionDetails);


    } catch (error) {
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }
  const validateDailyProductionReportsTbl = () => {
    debugger
    try {
      let dailyproductionDetails = [...dailyproductionreportdata];

      let validation = true;
      if (dailyproductionDetails.length === 0) {
        $("#error_Excelfile").text('Import File...!').show();
        validation = false;
        return validation
      }
      for (let index = 0; index < dailyproductionDetails.length; index++) {
        let data = dailyproductionDetails[index];
        for (let key in data) {
          if ((key === 'sizing_beam_no' || key === 'sort_no' || key === 'inspection_production_set_no') && (data[key] === '' || data[key] === null || data[key] === undefined)) {
            $(`#${key}_${index}`).parent().attr('data-tip', 'Please fill it..!');
            $(`#${key}_${index}`).focus();
            validation = false;
            return validation;
          }
        }
      }

      return validation;

    } catch (error) {
      console.log('Error :' + error);
    }
  }


  const saveDailyProductionReport = async () => {

    try {
      setIsLoading(true);
      // if (validateDailyProductionReportsTbl() === true) {
        debugger
        let json = { DailyProductionReportMasteData: {}, DailyProductionReport: [], 'commonIds': { 'company_id': COMPANY_ID, 'weaving_production_loom_master_id': 1 } };

        const data = {
          company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          file_name: txt_file_name,
          weaving_production_inspection_master_id: 0,
          inspection_production_code: $('#txt_production_code').val(),
          inspection_production_date: $("#dt_daily_production_date").val(),
          prod_month: $("#dt_daily_production_date").val().split('-')[1],
          prod_year: $("#dt_daily_production_date").val().split('-')[0],
          created_by: UserName,
          modified_by: UserName,
        };
        json.DailyProductionReportMasteData = data;
        console.log("Master Data : " + JSON.stringify(data));

        const trimSortNoVal = (sortNo) => {
          if (typeof sortNo === 'string' && sortNo.includes('-')) {
            return sortNo.split('-')[0];
          } else {
            return sortNo;
          }
        };


        for (let i = 0; i < dailyproductionreportdata.length; i++) {
          let empty_Json = {};
          let element = dailyproductionreportdata[i];

          empty_Json.weaving_production_inspection_details_id = element['weaving_production_inspection_details_id'] !== 0 || element['weaving_production_inspection_details_id'] === undefined || element['weaving_production_inspection_details_id'] === null ? element['weaving_production_inspection_details_id'] : 0;
          empty_Json.company_id = COMPANY_ID;
          empty_Json.company_branch_id = COMPANY_BRANCH_ID;
          empty_Json.financial_year = FINANCIAL_SHORT_NAME;

          empty_Json.inspection_production_date = element.inspection_production_date;
          empty_Json.total_no_of_ends = element.total_no_of_ends;
          empty_Json.prod_year = element.inspection_production_date?.split('-')[0];
          empty_Json.prod_month = element.inspection_production_date?.split('-')[1];
          empty_Json.inspection_production_set_no = element.inspection_production_set_no;
          empty_Json.sizing_beam_no = element.sizing_beam_no;
          empty_Json.product_in_meter = element.product_in_meter;
          empty_Json.inspection_mtr = element.inspection_mtr;
          empty_Json.product_pick = element.product_pick;
          empty_Json.difference = element.difference;
          empty_Json.width = element.width;
          empty_Json.sort_no = trimSortNoVal(element.sort_no);
          empty_Json.roll_no = element.roll_no;
          empty_Json.average = element.average;
          empty_Json.weight = element.weight;
          // empty_Json.inspection_production_status = 'C';
          empty_Json.machine_id = element.machine_id;
          empty_Json.created_by = UserName;
          empty_Json.modified_by = UserName;

          json.DailyProductionReport.push(empty_Json);
        }

        console.clear();
        console.log('Required Data :', json.DailyProductionReport)
        const formData = new FormData();
        formData.append(`DailyProductionReportData`, JSON.stringify(json));
        const requestOptions = {
          method: 'POST',
          body: formData
        };

        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnSaveDailyProductionData`, requestOptions)
        const apiResponse = await apiCall.json();
        console.log('apiResponse :' + apiResponse);

        if (apiResponse.success === 1) {
          setSuccMsg(apiResponse.message);
          setShowSuccessMsgModal(true);
        } else if (apiResponse.success === 0) {
          setErrMsg(apiResponse.error)
          setShowErrorMsgModal(true);
        }
      // }
    } catch (error) {
      navigate('/Error')
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <FrmValidations ref={validate} />
      <ComboBox ref={comboDataAPiCall} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <GenerateMaterialId ref={generateAutoNoAPiCall} />

      <DashboardLayout>

        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress />
              <span>Loading...</span>
            </div>
          </div> : null}

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Weaving Daily Production Report{actionType} </label>
          </div>


          <form id='dailyProductionReportMasterId'>
            <div className='row p-1'>
              {/* 1 st Column */}
              <div className='col-sm-4 erp_form_col_div'>
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Date</Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id='dt_daily_production_date' className="erp_input_field" value={dt_daily_production_date} onChange={(e) => setDailyProdDt(e.target.value)} />
                    <MDTypography variant="button" id="error_dt_daily_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Code</Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" id='txt_production_code' className="erp_input_field" value={txt_production_code} disabled />
                    <MDTypography variant="button" id="error_txt_production_code" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

              </div>

              <div className='col-sm-4 erp_form_col_div view_hide'>
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">File Name</Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" id='txt_file_name' className="erp_input_field" value={txt_file_name} disabled placeholder=".xlsx file" />
                  </div>
                </div>
              </div>


              <div className='col-sm-4 erp_form_col_div view_hide'>
                <input
                  type="file"
                  id="Excelfile"
                  ref={importFile}
                  onChange={(e) => importedExcelFile(e)}
                  style={{ display: "none" }}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <MDButton type="button" id="import_btn" className={`erp-gb-button`} variant="button" fontWeight="regular" onClick={e => { onImportClick(e) }} >Import File</MDButton>
                <MDTypography variant="button" id="error_Excelfile" className="erp_validation error-msg ms-1" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>

            </div>
            <hr />
            <div className="row m-1">
              <label className='erp-form-label-lg'>Production Report</label>
              <div className='col-sm-12 erp_form_col_div'>
                <div className=' m-1'>
                  {renderProductionDailyReportTable}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="erp_frm_Btns">
          <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/Transactions/TWeavingDailyProductionReport/TWeavingDailyProductionReportListing')}>Back</MDButton>
          <MDButton type="submit" id="save_Button" className="erp-gb-button ms-2 view_hide" variant="button"
            onClick={() => saveDailyProductionReport()} fontWeight="regular">Save</MDButton>
        </div >

        {/* Delete Modal */}
        <Modal show={showFileModal} backdrop="static" keyboard={false} centered>
          <Modal.Body className='erp_modal_body'>
            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
            <div className="erp-form-label">File Already Exists..! Please Import Proper File.</div>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <MDButton variant="success" className='erp-gb-button' onClick={fileModalClose}>
              Ok
            </MDButton>&nbsp;
          </Modal.Footer>
        </Modal>

        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
      </DashboardLayout>
    </>

  )
}

export default FrmWeavingDailyProductionReportEntry