import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";

import Datatable from "components/DataTable";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import ReactDOMServer from 'react-dom/server';
import ExcelExport from "Features/Exports/ExcelExport";
import GenericAttendanceSummary from './GenericAttendanceSummary'

function MonthlyAttendanceSummary() {

    // Globally Constants.
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME, FINANCIAL_SHORT_NAME, COMPANY_ADDRESS } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    // ****** Hook For Navigation
    const navigate = useNavigate();
    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let salaryMonthComboRef = useRef(null);
    let salaryYearComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);
    const exlsExp = useRef();

    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);
    const [txt_employee_code, setEmployeeCode] = useState();


    // Input field's Hooks;
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('0');
    const [cmb_salary_month, setSalaryMonth] = useState('');
    const [cmb_salary_year, setSalaryYear] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');

    // combo's options hooks;
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);

    // Hooks For table;
    const [attendanceDtlTblData, setAttendanceDtlTblData] = useState([]);
    const [attendanceDtlTblCols, setAttendanceDtlTblCols] = useState([]);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);


    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);

    // FINANCIAL_SHORT_NAME wants like 2024
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );

    // On Page Load.
    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);
    const validateShiftManagementDetails = () => {
        if (cmb_employee_type_id === '') {
            let errorElement = document.getElementById("error_cmb_employee_type_id");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_employee_type_id").focus();
            return false;
        }

        return true;
    }

    const FnComboOnChange = async (comboName) => {
        debugger
        try {
            switch (comboName) {
                case "Department":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    // subDepartmentComboRef.current = null;
                    // setSubDepartmentId('');
                    let selectedEmployeeTypes = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpt = departmentComboRef.current?.department_id || 'All';

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    // if (selectedEmployeeTypes && selectedEmployeeTypes !== 'All') {
                    //     globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeTypes });
                    // }
                    if (selectedEmployeeTypes !== undefined && selectedEmployeeTypes !== null && selectedEmployeeTypes !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeTypes });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                            ?.map(item => item?.field_name)
                            ?.filter(id => id !== undefined && id !== null && id !== '');;
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
                    }
                    if (selectedDpt && selectedDpt !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpt });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
                            // Add 'All' only if there are other employee options
                            if (rcvdEmpls.length > 0) {
                                rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            }
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');
                    // }
                    break;

                case "subDepartment":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedDepTS = departmentComboRef.current.department_id;
                    const subDepartmentLists = allDepartmentsList.filter(department =>
                        (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                    );
                    subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentLists);

                    // subDepartmentComboRef.current = null;
                    // setSubDepartmentId('');
                    let selectedEmpType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpts = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDPts = subDepartmentComboRef.current?.department_id || 'All';

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    // if (selectedEmpType && selectedEmpType !== 'All') {
                    //     globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmpType });
                    // }
                    if (selectedEmpType !== undefined && selectedEmpType !== null && selectedEmpType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmpType });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                            ?.map(item => item?.field_name)
                            ?.filter(id => id !== undefined && id !== null && id !== '');;
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
                    }
                    if (selectedDpts && selectedDpts !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpts });
                    }
                    if (selectedSubDPts && selectedSubDPts !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDPts });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
                            // Add 'All' only if there are other employee options
                            if (rcvdEmpls.length > 0) {
                                rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            }
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');

                    break;
                case "EmployeeType":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedEmployeeType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDepartments = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDepartment = subDepartmentComboRef.current?.department_id || 'All';
                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    // if (selectedEmployeeType && selectedEmployeeType !== 'All') {
                    //     globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeType });
                    // }
                    if (selectedEmployeeType !== undefined && selectedEmployeeType !== null && selectedEmployeeType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: selectedEmployeeType });
                    } else {
                        let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                        setEmployeeTypeId(defaultEmplType.value);
                        employeeTypesComboRef.current = defaultEmplType;
                        let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                            ?.map(item => item?.field_name)
                            ?.filter(id => id !== undefined && id !== null && id !== '');;
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "IN", values: emplTypes });
                    }
                    if (selectedDepartments && selectedDepartments !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDepartments });
                    }
                    if (selectedSubDepartment && selectedSubDepartment !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
                            // Add 'All' only if there are other employee options
                            if (rcvdEmpls.length > 0) {
                                rcvdEmpls.unshift({ employee_id: '', value: 'All', label: 'All' });
                            }
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');
                    break;
                    case 'EmployeeCode':
                    debugger
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);
                    let findEmployee = null;

                    if (serachemployeeCode.length >= 2) {
                        findEmployee = employeeOpts.find((employee) => {
                            return employee.employee_code === serachemployeeCode
                                || employee?.label?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                || employee?.old_employee_code === serachemployeeCode
                                // || employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.employee_id);
                        employeeComboRef.current = {
                            employee_id: findEmployee.employee_id,
                            employee_code: findEmployee.employee_code,
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                        };
                    }
                    break;
                case 'EmplCode':
                    // setEmployeeCode(employeeComboRef.current.employee_code);
                    if (employeeComboRef.current && employeeComboRef.current.employee_code) {
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    }

                    break;
                case 'Employee':
                    debugger
                    var employeeID = employeeComboRef.current?.employee_id;
                    if (employeeID !== "") {
                        // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeComboRef.current.employee_id);
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    } else {
                        setEmployeeCode('');
                    }

                default:
                    break;
            }

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    const FnFillComobos = async () => {
        try {
            // Set Default Year.
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setSalaryYear(defaultYear.value);
            salaryYearComboRef.current = defaultYear;
            // Set Default Month.
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setSalaryMonth(defaultMonth.value);
            salaryMonthComboRef.current = defaultMonth;


            // resetGlobalQuery();
            // globalQuery.columns = ["field_id", "field_name"];
            // globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeTypeGroup' });
            // comboDataFunc.current.fillFiltersCombo(globalQuery)
            //     .then(rcvdEmplTypes => {
            //         const emplTypes = [
            //             // { value: '', label: 'Select', field_id: '', field_name: '', },
            //             { value: '0', label: 'All', field_id: 'All', field_name: 'All', },
            //             ...rcvdEmplTypes.map((emplType) => ({ ...emplType, value: emplType.field_id, label: emplType.field_name, })),
            //         ];
            //         setEmployeeTypesOpts(emplTypes);
            //     });
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "property_value", operator: "=", value: 'S' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    // setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeTypeGroup')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });
            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    // subDepartments.unshift({ value: 'All', label: 'All' });
                    // setSubDepartmentOpts(subDepartments);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {

        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
        if (isEmpty(salaryMonthComboRef.current?.value)) { $('#error_cmb_salary_month').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    const goBack = () => {
        navigate("/Dashboard")
    }


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintImmediatePunch = useReactToPrint({
        content: () => {
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            // Remove the print button from the cloned content

            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        // documentTitle: "Monthly Attendance Summary"
    });

    const printOutHeader = () => {
        debugger
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Monthly Attendance Summary

                                ({employeeTypesComboRef.current?.label === 'Select' || employeeTypesComboRef.current?.label === undefined || employeeTypesComboRef.current?.label === '' ? 'All' : employeeTypesComboRef.current?.label})
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                For Month: {salaryMonthComboRef.current?.label}   Year: {salaryYearComboRef.current?.value}
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    };



    // ********* PrintOut Functionalities Ends. ****************************************


    const capitalizeStr = str => str.charAt(0).toUpperCase() + str.slice(1);
    const formatTableHeader = key => key.split('_').map(capitalizeStr).join(' ');
    const FnLoadAttendanceData = async () => {
        debugger
        try {
            setIsLoading(true);
            const validate = validateShiftManagementDetails();
            if (validate) {
                let selectedYear = salaryYearComboRef.current?.value || null;
                let selectedMonth = salaryMonthComboRef.current?.value || null;
                let selectedDepartment = departmentComboRef.current?.department_id || null;
                let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
                let selectedEmplType = employeeTypesComboRef.current?.field_name || null;
                let selectedEmployee = employeeComboRef.current?.employee_id || null;

                if (selectedEmployee === null) {
                    FnComboOnChange("subDepartment");
                    // if (employeeOpts.length > 1 && employeeOpts[0].employee_id !== '') {
                    setEmployeeId('All');
                    // }
                }

                if (selectedDepartment === null) {
                    setDepartmentId('All')
                }

                if (selectedSubDepartment === null) {
                    setSubDepartmentId('All')
                }
                let totalSalaryDaysColumn;

                if (selectedEmplType === "All" || selectedEmplType === "Workers" || selectedEmplType === null) {
                    totalSalaryDaysColumn = `
                    CONCAT(
                        TRIM(TRAILING '.0' FROM FORMAT(htatm.total_salary_days, 1)), 
                        ' (', 
                        TRIM(TRAILING '.0' FROM FORMAT(htatm.monthly_hours, 1)), 
                        ')'
                    ) AS total_salary_days`;
                } else {
                    totalSalaryDaysColumn = "htatm.total_salary_days";
                }
                resetGlobalQuery();
                globalQuery.columns = [
                    "empldep.department_name", "emplsubdep.department_name as sub_department_name",
                    "empl.employee_name",
                    "empl.employee_code",
                    totalSalaryDaysColumn,
                    // "CONCAT(htatm.total_salary_days, ' (', htatm.monthly_hours, ')') AS total_salary_days",
                    // "CONCAT(FORMAT(htatm.total_salary_days), ' (', FORMAT(htatm.monthly_hours, 1), ')') AS total_salary_days",

                    "htatm.department_id",
                    "htatm.sub_department_id",

                    // Concatenate all presenty columns into a single column without including empty values
                    "CONCAT_WS(' - ', " +
                    "CASE WHEN htatm.presenty1 != '' THEN htatm.presenty1 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty2 != '' THEN htatm.presenty2 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty3 != '' THEN htatm.presenty3 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty4 != '' THEN htatm.presenty4 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty5 != '' THEN htatm.presenty5 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty6 != '' THEN htatm.presenty6 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty7 != '' THEN htatm.presenty7 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty8 != '' THEN htatm.presenty8 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty9 != '' THEN htatm.presenty9 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty10 != '' THEN htatm.presenty10 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty11 != '' THEN htatm.presenty11 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty12 != '' THEN htatm.presenty12 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty13 != '' THEN htatm.presenty13 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty14 != '' THEN htatm.presenty14 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty15 != '' THEN htatm.presenty15 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty16 != '' THEN htatm.presenty16 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty17 != '' THEN htatm.presenty17 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty18 != '' THEN htatm.presenty18 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty19 != '' THEN htatm.presenty19 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty20 != '' THEN htatm.presenty20 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty21 != '' THEN htatm.presenty21 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty22 != '' THEN htatm.presenty22 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty23 != '' THEN htatm.presenty23 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty24 != '' THEN htatm.presenty24 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty25 != '' THEN htatm.presenty25 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty26 != '' THEN htatm.presenty26 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty27 != '' THEN htatm.presenty27 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty28 != '' THEN htatm.presenty28 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty29 != '' THEN htatm.presenty29 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty30 != '' THEN htatm.presenty30 ELSE NULL END, " +
                    "CASE WHEN htatm.presenty31 != '' THEN htatm.presenty31 ELSE NULL END" +
                    ") AS Presenty_Details"
                ];

                globalQuery.table = "ht_attendance_monthly htatm";
                globalQuery.conditions.push({ field: "htatm.is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "htatm.company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "htatm.attendance_month", operator: "=", value: selectedMonth });  // Here pass the selected monthNumber
                globalQuery.conditions.push({ field: "htatm.attendance_year", operator: "=", value: selectedYear });


                // if (selectedEmplType && selectedEmplType !== 'All') {
                //     globalQuery.conditions.push({ field: "htatm.employee_type", operator: "=", value: selectedEmplType });
                // }
                if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.employee_type", operator: "=", value: selectedEmplType });
                } else {
                    let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                    setEmployeeTypeId(defaultEmplType.value);
                    employeeTypesComboRef.current = defaultEmplType;
                    let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                        ?.map(item => item?.field_name)
                        ?.filter(id => id !== undefined && id !== null && id !== '');;
                    globalQuery.conditions.push({ field: "htatm.employee_type", operator: "IN", values: emplTypes });
                }
                if (selectedEmployee && selectedEmployee !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.employee_id", operator: "=", value: selectedEmployee });
                }
                if (selectedDepartment && selectedDepartment !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.department_id", operator: "=", value: selectedDepartment });
                }
                if (selectedSubDepartment && selectedSubDepartment !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.sub_department_id", operator: "=", value: selectedSubDepartment });
                }

                globalQuery.orderBy = ['htatm.department_id DESC', 'htatm.sub_department_id DESC', 'htatm.employee_code Asc',];

                globalQuery.joins = [
                    {
                        "table": `cm_employee empl`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "empl.employee_id",
                                "right": "htatm.employee_id",
                            },
                            {
                                "left": "empl.company_id",
                                "right": "htatm.company_id",
                            },
                        ]
                    },
                    {
                        "table": `cm_department empldep`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "empldep.department_id",
                                "right": "htatm.department_id",
                            },
                            // {
                            //     "left": "empldep.company_id",
                            //     "right": "htatm.company_id",
                            // },
                        ]
                    },
                    {
                        "table": `cm_department emplsubdep`,
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "emplsubdep.department_id",
                                "right": "htatm.sub_department_id",
                            },
                            // {
                            //     "left": "emplsubdep.company_id",
                            //     "right": "htatm.company_id",
                            // },
                        ]
                    },

                ]
                let getAttendanceData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                const excludedKeysToDisplay = ["company_id", "department_id", "sub_department_id", "attendance_process_id",
                    "process_date", "employee_type", "attendance_month", "attendance_year", "attendance_flag",
                    "company_id", "company_branch_id", "department_id", "sub_department_id", "employee_id",
                ];

                if (getAttendanceData.length > 0) {
                    getAttendanceData = getAttendanceData.map((record, index) => ({
                        sr_no: index + 1,
                        ...record,
                    }));

                    const headersArray = Object.keys(getAttendanceData[0])
                        .filter(key => !excludedKeysToDisplay.includes(key))
                        .map(key => ({
                            Headers: formatTableHeader(key),
                            accessor: key
                        }));

                    setAttendanceDtlTblCols(headersArray);
                    setAttendanceDtlTblData(getAttendanceData);
                    console.table(getAttendanceData);

                } else {
                    setAttendanceDtlTblCols([]);
                    setAttendanceDtlTblData([]);
                }
            }
        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }finally {
            setIsLoading(false)
        }
    }

    //-----------------------------------Export functionality -------------------
    const FnExportShiftWisePunchDetails = async () => {
        debugger
        try {
            setIsLoading(true)
            if (attendanceDtlTblData.length !== 0) {
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] } // , 25 for remark;

                let filtrKeyValue = {};


                filtrKeyValue['0'] = "Month : " + salaryMonthComboRef.current.label
                filtrKeyValue['1'] = "Year : " + salaryYearComboRef.current.label

                let departmentName = departmentComboRef.current?.label || '';
                if (departmentName === '' || departmentName === 0 || departmentName === undefined) {
                    departmentName = 'All';
                }

                filtrKeyValue['2'] = "Department Name : " + departmentName;
                let subDepartmentName = subDepartmentComboRef.current?.label || '';
                if (subDepartmentName === '' || subDepartmentName === 0 || subDepartmentName === undefined) {
                    subDepartmentName = 'All';
                }

                filtrKeyValue['3'] = "Sub Department Name : " + subDepartmentName;
                let employeeType = employeeTypesComboRef.current?.field_name || '';
                if (employeeType === '' || employeeType === 0 || employeeType === undefined) {
                    employeeType = 'All';
                }

                filtrKeyValue['4'] = "Employee Type : " + employeeType;
                let employeeName = employeeComboRef.current?.employee_name || '';
                // let employeeName = document.getElementById('cmb_employee_id').value;
                if (employeeName === '' || employeeName === 0 || employeeName === undefined) {
                    employeeName = 'All';
                }

                filtrKeyValue['5'] = "Employee Name : " + employeeName;
                jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;
                jsonToExportExcel['columns'] = attendanceDtlTblCols;
                attendanceDtlTblData.map((punchDetails, Index) => {
                    jsonToExportExcel['allData'][Index] = punchDetails;
                });


                jsonToExportExcel['headings']['ReportName'] = "Monthly Attendance Summary"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = COMPANY_ADDRESS

                await exlsExp.current.excel(jsonToExportExcel, 'Monthly_Attendance_summary' + '_For_' + salaryMonthComboRef.current.label + '-' + salaryYearComboRef.current.label)
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }


    // Component View Return
    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}


                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Monthly Attendance Summary</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">

                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryYearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_salary_year"
                                            value={yearOpts.find(option => option.value == cmb_salary_year) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryYear(selectedOpt.value);
                                                salaryYearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryMonthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_salary_month"
                                            value={monthOpts.find(option => option.value == cmb_salary_month) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryMonth(selectedOpt.value);
                                                salaryMonthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnComboOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnComboOnChange("subDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnComboOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Employee Name :<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-3">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                            onChange={(e) => { FnComboOnChange('EmployeeCode'); }}
                                            onBlur={(e) => { FnComboOnChange('EmplCode'); }}
                                            maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {/* <div className="col-sm-4"> 
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'> */}
                                    <div className="col">
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                                FnComboOnChange('Employee');
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="save-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnLoadAttendanceData(); }}
                                >Show</MDButton>
                                <MDButton type="button" className="ms-2 erp-gb-button" onClick={FnExportShiftWisePunchDetails} variant="button" fontWeight="regular" disabled={attendanceDtlTblData.length === 0}>Export To Excel</MDButton>

                            </div>
                        </div>

                        <hr />

                        <div className="row px-lg-2 d-block">
                            <div className="col-lg-12 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-md-lg">Employee's Monthly Attendance Summary </label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">

                                        {
                                            attendanceDtlTblData.length === 0
                                                ? <>
                                                    <hr />
                                                    <Card id="NoRcrdId" >
                                                        <Card.Body>No records found...</Card.Body>
                                                    </Card>
                                                </>
                                                : <>
                                                    {/* <hr /> */}
                                                    <div className="row print-content" ref={printComponentRef}>
                                                        <AttendanceSummaryComponent
                                                            summaryData={attendanceDtlTblData}
                                                            employeeType={employeeTypesComboRef.current?.label === 'Select' || employeeTypesComboRef.current?.label === undefined || employeeTypesComboRef.current?.label === '' ? 'All' : employeeTypesComboRef.current?.label}

                                                        />
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>
                                {
                                    attendanceDtlTblData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2 print-button"

                                                onClick={(e) => { FnPrintImmediatePunch(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>

        </>
    )
}
export default MonthlyAttendanceSummary;


export const AttendanceSummaryComponent = ({ summaryData,employeeType }) => {
    debugger
    // Function to generate GenericAttendanceSummary components
    const renderAttendanceSummaryTables = () => {
        // Use reduce to find unique sub-departments based on sub-department_id
        const uniqueSubDepartments = summaryData.reduce((unique, current) => {
            // Check if the sub-department doesn't already exist in unique array
            if (!unique.some(item => item.sub_department_id === current.sub_department_id)) {
                unique.push(current);
            }
            return unique;
        }, []);

        return uniqueSubDepartments?.map((subDepartment, index) => {
            const subDepartmentId = subDepartment.sub_department_id;

            // Filtered data based on department filter
            // Filtered data based on department filter
            const filteredAndSortedSalarySummary = summaryData
                .reduce((acc, item) => {
                    if (item.sub_department_id === subDepartmentId) { acc.push(item); }
                    return acc;
                }, [])
                .sort((a, b) => (a.employee_code < b.employee_code ? -1 : (a.employee_code > b.employee_code ? 1 : 0)));
            // const filteredEarnings = earningsDtls.filter(item => item.sub_department_id === subDepartmentId);
            // const filteredDeductions = deductionsDtls.filter(item => item.sub_department_id === subDepartmentId);

            return (
                <GenericAttendanceSummary key={index}

                    attendanceSummaryDtlTblData={filteredAndSortedSalarySummary}
                    employeeType={employeeType}
                    departmentName={subDepartment.department_name}
                    subDepartmentName={subDepartment.sub_department_name}
                    isLastTable={index === uniqueSubDepartments.length - 1}    // Means it is the last table or not?
                    allDataForGrandTotal={{
                        allEmployeesSummery: summaryData,

                    }}
                />
            );
        });
    };
    return (
        <div>
            {renderAttendanceSummaryTables()}
        </div>
    );
};