import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import MDTypography from 'components/MDTypography';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import MDButton from "components/MDButton";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ExcelExport from "Features/Exports/ExcelExport";
import Select from 'react-select';
import MaterialSearchBar from "FrmGeneric/MaterialSearchBar";
import ReactPaginate from "react-paginate";

function RawMaterialStockReport() {

    const configConstants = ConfigConstants();
    const { COMPANY_NAME, COMPANY_ID, AFTER_DECIMAL_PLACES,COMPANY_ADDRESS } = configConstants;

    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split("T")[0];

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);
    const [category1Options, setCategory1Options] = useState([]);
    const [cmb_product_category1_id, setProduct_category1_id] = useState("All");
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    const [cmb_product_type_id, setProduct_type_id] = useState(12);

    let productTypeComboRef = useRef(null);
    let category1ComboRef = useRef(null);

    const [category2Options, setCategory2Options] = useState([]);
    const [cmb_product_category2_id, setProduct_category2_id] = useState("All");
    let category2ComboRef = useRef(null);

    const [by_material, setByMaterial] = useState(false);
    const [search_material_id, setSearchMaterialId] = useState('');

    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);
    const exlsExp = useRef();

    //data hook
    const [storeStockReportData, setStoreStockReportData] = useState([])

    // Pagination Variables 
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
        // { label: "All", value: 0 },
    ]
    const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);

    //Returning to Dashboard
    const goBack = () => { navigate("/Dashboard") }

    useEffect(() => {
        const loadFiltersCombo = async () => {
            setIsLoading(true)
            await loadCombos();
            // await FnShowRawMaterialData(0 , entriesPerPage);
            setIsLoading(false)
        }
        loadFiltersCombo();
    }, [])


    //Validating Dates
    function isValidDate(dateString) {
        debugger
        // Check if date is in the format DD-MM-YYYY
        const regex = /^(?<day>0[1-9]|[12][0-9]|3[01])-(?<month>0[1-9]|1[0-2])-(?<year>\d{4})$/;
        const match = regex.exec(dateString);

        if (!match) {
            console.log("Date format is incorrect:", dateString);
            return false; // If the format is not correct
        }

        const day = Number(match.groups.day);
        const month = Number(match.groups.month);
        const year = Number(match.groups.year);

        // Check for leap year if it's February
        if (month === 2) {
            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            if (day > 29 || (day === 29 && !isLeapYear)) {
                console.log("Invalid day for February:", day);
                return false; // Invalid day for February in a leap year
            }
        }

        // Validate months with 30 days (April, June, September, November)
        if ([4, 6, 9, 11].includes(month) && day > 30) {
            console.log("Invalid day for month with 30 days:", dateString);
            return false; // These months cannot have more than 30 days
        }

        // Create a date object with the given input
        const date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            console.log("Invalid date object created:", dateString, date);
            return false; // The date is invalid (like 32nd day, or 13th month)
        }

        console.log("Date is valid:", dateString);
        return true;
    }

    function convertToDDMMYYYY(dateString) {
        // Assuming the input is in the format YYYY-MM-DD
        const [year, month, day] = dateString.split('-');
        // Return the date in DD-MM-YYYY format
        return `${day}-${month}-${year}`;
    }

    function FnChkIsValidFilters() {
        debugger
        let dt_from_date = $("#dt_from_date").val();  // Expecting input in YYYY-MM-DD format
        let dt_to_date = $("#dt_to_date").val();      // Expecting input in YYYY-MM-DD format

        if (dt_to_date === '') {
            $('#error_dt_to_date').text("Invalid To Date").show();
            return false
        } else if (dt_from_date === '') {
            $('#error_dt_from_date').text("Invalid From Date").show();
            return false;
        }

        // Convert both dates to DD-MM-YYYY format for validation
        dt_from_date = convertToDDMMYYYY(dt_from_date);
        dt_to_date = convertToDDMMYYYY(dt_to_date);

        let isValid = true;

        // Validate From Date
        if (!isValidDate(dt_from_date)) {
            $('#error_dt_from_date').text("Invalid From Date").show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }

        // Validate To Date
        if (!isValidDate(dt_to_date)) {
            $('#error_dt_to_date').text("Invalid To Date").show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }

        // Convert back to YYYY-MM-DD format for accurate date comparison
        const fromDate = new Date(dt_from_date.split('-').reverse().join('-'));
        const toDate = new Date(dt_to_date.split('-').reverse().join('-'));

        // Check if From Date is greater than To Date
        if (fromDate > toDate) {
            $('#error_dt_to_date').text("To Date cannot be smaller than From Date").show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }

        return isValid;
    }

    // ****-------Get Cost Center Data Function--------------***
    const loadCombos = async () => {
        try {
            //load product type options
            resetGlobalQuery();
            globalQuery.columns = ['product_type_id', 'product_type_name', 'product_type_short_name'];
            globalQuery.table = "sm_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const productTypeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const typesOpts = [
                { value: 'All', label: 'All', centerShortName: '' },
                ...productTypeList.map((productType) => ({ ...productType, value: productType.product_type_id, label: `${productType.product_type_name}` })),
            ];
            setProductTypeOptions(typesOpts);
            // const selectedOption = typesOpts.find(item => item.product_type_short_name === requestfor);
            // productTypeComboRef.current = selectedOption;

            resetGlobalQuery();
            globalQuery.columns = ['product_category1_id', 'product_category1_name'];
            globalQuery.table = "sm_product_category1";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: cmb_product_type_id });
            const Category1List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const category1Opts = [
                { value: 'All', label: 'All', centerShortName: '' },
                ...Category1List.map((category1) => ({ ...category1, value: category1.product_category1_id, label: `${category1.product_category1_name}` })),
            ];
            setCategory1Options(category1Opts);
            setProduct_category1_id('All')

            const category2Opts = [
                { value: 'All', label: 'All' }
            ];
            setCategory2Options(category2Opts);
            setProduct_category2_id('All')

            setStoreStockReportData([])

        } catch (error) {
            console.log("error : ", error)
        }
    }

    const comboOnChange = async (key) => {

        try {
            switch (key) {
                case 'category1':
                    let category1 = category1ComboRef.current.value;
                    if (category1 != 'All') {
                        resetGlobalQuery();
                        globalQuery.columns = ['product_category2_id', 'product_category2_name'];
                        globalQuery.table = "sm_product_category2";
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1 });
                        const Category2List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        const category2Opts = [
                            { value: 'All', label: 'All' },
                            ...Category2List.map((category2) => ({ ...category2, value: category2.product_category2_id, label: `${category2.product_category2_name}` })),
                        ];
                        setCategory2Options(category2Opts);
                        setProduct_category2_id('All')
                    } else {
                        const category2Opts = [
                            { value: 'All', label: 'All' }
                        ];
                        setCategory2Options(category2Opts);
                        setProduct_category2_id('All')
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);

        }
    }

    // ****-------show data Function --------------***
    const FnShowRawMaterialData = async (page, pageSize, product_id = '') => {
        try {
            debugger
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            commonIds.category1_id = cmb_product_category1_id.toString();
            commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = product_id === '' ? search_material_id : product_id;
            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            debugger
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getRawMaterialStockReport/${page}/${pageSize}`, requestOptions)
            const responce = await apiCall.json();
            debugger
            console.log("Raw material Stock Report : ", responce);
            if (responce.success = 1) {
                const total = responce.total_count;
                setpageCount(parseInt(pageSize) !== 0 ? Math.ceil(total / pageSize) : 1);
                console.log(responce.storeStockReportData);
                setStoreStockReportData(responce.storeStockReportData)
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }


    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_code'];

    const getMaterialBySearch = async (selectedData) => {
        if (selectedData !== null) {
            try {
                setIsLoading(true)
                let { product_id, product_name, product_code } = selectedData[0];
                let material_name = product_name;
                let material_code = product_code;
                let material_id = product_id;
                console.log("searched Material:  ", material_name, ' : ', material_code, ' : ', material_id);
                debugger
                if (by_material) {
                    setSearchMaterialId(product_id)
                    await FnShowRawMaterialData(PageCurrent, entriesPerPage, product_id);
                    $('#error_selectMaterialRecords_id').hide();
                } else {
                    $('#error_selectMaterialRecords_id').text('Please fill checkbox first').show();
                }
            } catch (error) {
                console.error("Error in Search Material: ", error);
                setSearchMaterialId('')
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        } else {
            setStoreStockReportData([]);
        }
    }


    // ********* ExportToExcel Functionalities Starts. ****************************************
    const addExcelFilter = (label, key, value, existingFilters) => {
        if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
    };

    const FnExportToExcel = async () => {
        // Means No Data For Export. 
        if (storeStockReportData && Object.keys(storeStockReportData).length === 0) { return null; }

        setIsLoading(true);
        try {
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            commonIds.category1_id = cmb_product_category1_id.toString();
            commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = search_material_id;
            commonIds.COMPANY_NAME = COMPANY_NAME
            commonIds.COMPANY_ADDRESS = COMPANY_ADDRESS
            commonIds.ReportName="Raw material Stock Report"
            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getRawMaterialStockReportToExport`, requestOptions)
            // Ensure the response is ok (status 200)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Get the response as a blob (binary large object)
            const blob = await response.blob();
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([blob]));
            // Create a link element to download the file
            const link = document.createElement('a');
            link.href = url;
            // Set the name of the file
            link.setAttribute('download', 'stock_report.xlsx'); // Set your preferred file name
            // Append the link to the document body
            document.body.appendChild(link);
            // Trigger the download by simulating a click
            link.click();
            // Clean up by removing the link from the document
            document.body.removeChild(link);
            console.log("File downloaded successfully");
        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }
    // ********* ExportToExcel Functionalities Ends. ****************************************

    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = async () => {
        try {
            // Fetch the data before printing
            await FnGetStockReportByDateToPrint();

            // Trigger the print after the data has been fetched
            FnPrintIssueReportSheet();
        } catch (error) {
            console.error('Error fetching data for print:', error);
        }
    };


    const FnGetStockReportByDateToPrint = async (product_id = '') => {
        try {
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            commonIds.category1_id = cmb_product_category1_id.toString();
            commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = product_id === '' ? search_material_id : product_id;
            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getRawMaterialStockReport`, requestOptions)
            const responce = await apiCall.json();
            setStoreStockReportData(responce.storeStockReportData)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }



    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        setcurrentPage(0)
        await FnShowRawMaterialData(0, count);
        // await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    }

    const handlePageClick = async (pageNo) => {
        debugger
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        let offset = currentPage * entriesPerPage;
        await FnShowRawMaterialData(offset, entriesPerPage);
    }

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Store Stock Report
                                {
                                    `(${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_from_date)} to ${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_to_date)})`
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    let printTimeout;  // Store timeout reference

    const FnPrintIssueReportSheet = useReactToPrint({
        content: () => {
            // Hide the pagination before printing
            $('.erp_pagination').hide()

            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content (clone the table for printing)
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set desired font size
                }
            }

            // Set timeout to detect print cancel
            printTimeout = setTimeout(() => {
                // Trigger functionality if print is canceled
                handlePrintCancel();
            }, 500); // Adjust the timeout duration if needed
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Restore the pagination after printing
            $('.erp_pagination').show();

            // Clear the timeout since printing is done
            clearTimeout(printTimeout);

            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Store Stock Report_${`Raw_material_stock`}_(${dt_from_date}_to_${dt_to_date})`
    });


    const handlePrintCancel = async () => {
        console.log('Print was canceled.');
        // Add your custom functionality here, like calling the FnShowRawMaterialData function

        try {
            // Fetch paginated data (example with page and pageSize variables)
            await FnShowRawMaterialData(PageCurrent, entriesPerPage);  // Replace page and pageSize with your actual values
            console.log('Data reloaded after print cancel.');
        } catch (error) {
            console.error('Error fetching data after print cancel:', error);
        }
    };

    useEffect(() => {
        // Get the value of the input field with id 'dt_from_date' if it exists
        const dateInput = document.getElementById('dt_from_date');
        if (dateInput) {
            setFromDt(dateInput.value);
        }
    }, []);

    function decimalNumber(decimalValue, afterDecimal = AFTER_DECIMAL_PLACES) {
        // Check if decimalValue is null, undefined, or an empty string
        if (decimalValue === null || decimalValue === undefined || decimalValue === '') {
            return '';
        }

        // Ensure decimalValue is a string for length check
        const decimalStr = String(decimalValue);

        if (decimalStr[decimalStr.length - 1] === '.') {
            return decimalStr;
        } else {
            let num = parseFloat(decimalStr);
            if (isNaN(num)) {
                return ''; // Return empty string if parsing fails
            }
            let roundedNum = Math.round(num * (10 ** afterDecimal)) / (10 ** afterDecimal);
            return roundedNum;
        }
    }

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <ExcelExport ref={exlsExp} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Raw material Stock Report</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        {/* <div className='row p-1'>
                            <div className="erp_form_radio">
                                <div className="fCheck">
                                    <Form.Check
                                        className="erp_radio_button erp-form-label-md"
                                        label="Summary"
                                        type="radio"
                                        value="summary"
                                        name="summaryOrDetails"
                                        onChange={(e) => setReportType(e.target.value)}
                                        defaultChecked
                                    />
                                </div>
                                <div className="sCheck">
                                    <Form.Check
                                        className="erp_radio_button erp-form-label-md"
                                        label="Details"
                                        value="details"
                                        type="radio"
                                        onChange={(e) => setReportType(e.target.value)}
                                        name="summaryOrDetails"
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={productTypeComboRef}
                                            inputId="cmb_product_type_id"
                                            value={productTypeOptions.find(option => option.value === cmb_product_type_id) || null}
                                            options={productTypeOptions}
                                            onChange={(selectedOption) => {
                                                setProduct_type_id(selectedOption.value)
                                                productTypeComboRef.current = selectedOption;
                                                // comboOnChange('category1');
                                            }}
                                            placeholder="Search for product type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            isDisabled={true}

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters(); }}  max={currentDate}/>
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row p-1'>
                            {/* Categories COMBO BOX*/}
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Category 1:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={category1ComboRef}
                                            inputId="cmb_product_category1_id"
                                            value={category1Options.find(option => option.value === cmb_product_category1_id) || null}
                                            options={category1Options}
                                            onChange={(selectedOption) => {
                                                setProduct_category1_id(selectedOption.value)
                                                category1ComboRef.current = selectedOption;
                                                comboOnChange('category1');
                                            }
                                            }
                                            placeholder="Search for Category1..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Category 2:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={category2ComboRef}
                                            inputId="cmb_product_category2_id"
                                            value={category2Options.find(option => option.value === cmb_product_category2_id) || null}
                                            options={category2Options}
                                            onChange={(selectedOption) => {
                                                setProduct_category2_id(selectedOption.value)
                                                category2ComboRef.current = selectedOption;
                                                comboOnChange('category2');
                                            }
                                            }
                                            placeholder="Search for Category2..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                {/*   */}
                                <div className={`row`}>
                                    <div className="col-sm-4">
                                        <input type='checkbox' class="me-1" name="selectMaterialRecords" id="selectMaterialRecords" onChange={(e) => { e.target.checked ? setByMaterial(true) : setByMaterial(false); setSearchMaterialId('') }} /> <label class="erp-form-label pb-1">Search By Material </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <MaterialSearchBar getSearchData={getMaterialBySearch} formIdForValidate="" requiredCols={requiredCols} product_type_id={cmb_product_type_id} product_category1_id={cmb_product_category1_id == 'All' ? 0 : cmb_product_category1_id} product_category2_id={cmb_product_category2_id == 'All' ? 0 : cmb_product_category2_id} />
                                        <MDTypography variant="button" id="error_selectMaterialRecords_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col text-center">
                                    <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowRawMaterialData(0, entriesPerPage); }} variant="button" fontWeight="regular">show Data</MDButton>
                                </div>
                            </div>
                        </div>
                        {
                            storeStockReportData.length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                :
                                <>
                                    <hr />
                                    <div className="row py-1">
                                        <div className="col-sm-6 page_entries">
                                            <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>
                                            <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                                                {pageEntriesOptions.map(pageEntriesOptions => (
                                                    <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                                                ))}
                                            </Form.Select>
                                        </div>
                                        <div className="col-sm-6 text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }}
                                            >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { handlePrint(); }}
                                            >Print</MDButton>
                                        </div>
                                    </div>
                                    <div className="row px-lg-2 d-block  mt-1">
                                        <div className="card p-0">
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                <StoreReportTbl
                                                    storeStockReportData={storeStockReportData}
                                                    validateNumberDateInput={validateNumberDateInput}
                                                    decimalNumber={decimalNumber}
                                                    dt_from_date={convertToDDMMYYYY(dt_from_date)}
                                                    dt_to_date={convertToDDMMYYYY(dt_to_date)}
                                                />

                                            </div>
                                        </div>
                                        {pageCount !== 1 ?
                                            <ReactPaginate
                                                className='erp_pagination'
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link erp-gb-button"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link erp-gb-button"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link erp-gb-button"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"} /> : null}
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                {
                                    storeStockReportData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={handlePrint}
                                            >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    {/* Card's Body Ends*/}
                </div>
                {/* Card Ends*/}


            </DashboardLayout>
        </>
    )
}

export default RawMaterialStockReport




export const StoreReportTbl = ({
    storeStockReportData,
    validateNumberDateInput,
    decimalNumber,
    dt_from_date,
    dt_to_date
}) => {

    return (
        <>
            <style>
                {`
                @media print {
                    .print-content {
                        padding: 0;
                        box-sizing: border-box;
                    }
                    @page {
                        margin: 0;
                        size: legal landscape;
                        margin: 1%;
                    }
                    html, body {
                        width: 100%;
                        height: 100%;
                    }
                    body {
                        margin: 0;
                        padding: 0;
                    }
                    .scrollable {
                        overflow: visible !important; /* Remove scrolling for print */
                    }
                    .erp_table {
                        table-layout: auto !important; /* Expand table for printing */
                        font-size: 7px !important; /* Adjust font size for print */
                        width: 100% !important; /* Ensure full width table */
                    }
                }
                .scrollable {
                    max-height: 500px;
                    overflow-y: auto;
                    overflow-x: auto;
                }

                .erp_table_th, .erp_table_td {
                    white-space: normal; /* Allow text to wrap */
                    word-wrap: break-word; /* Break long words if needed */
                }


                `}

            </style>

            <div className="scrollable">
                <Table id="issueReportTableId" className="erp_table" style={{ whiteSpace: 'normal', tableLayout: 'auto' }}>
                    <thead className="erp_table_head table-secondary text-center">
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" style={{ width: "70px" }} rowSpan={2}>Sr. No</th>
                            <th className={`erp_table_th align-middle`} style={{ width: "120px" }} rowSpan={2}>Material Code</th>
                            <th className={`erp_table_th align-middle`} style={{ width: "200px" }} rowSpan={2}>Count</th>
                            <th className="erp_table_th align-middle" style={{ width: "200px" }} rowSpan={2}>Lot No.</th>
                            <th className="erp_table_th align-middle" style={{ width: "150px" }} rowSpan={2}>Party Name</th>
                            <th className={`erp_table_th align-middle`} style={{ width: "175px" }} rowSpan={2}>Remarks</th>
                            <th className={`erp_table_th align-middle`} style={{ width: '100px' }} rowSpan={2}>Each Box  Cone</th>
                            <th className="erp_table_th align-middle" style={{ width: "70px" }} rowSpan={2}>No. Of <br /> Total Cone</th>
                            <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Opening As On <br /> {dt_from_date}</th>
                            <th className="erp_table_th align-middle" style={{ width: "300px" }} colSpan={5}>Purchase</th>
                            <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={2}>Adjust</th>
                            {/* <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Return From Sizing</th> */}
                            {/* <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Return From Weaving</th> */}
                            <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Issued To Sizing</th>
                            <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Issued To Weaving</th>
                            <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Return from Sizing</th>
                            <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Return from Weaving</th>
                            {/* <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Return From Supplier</th> */}
                            <th className="erp_table_th align-middle" style={{ width: "180px" }} colSpan={3}>Closing Stock As On <br /> {dt_to_date}</th>
                        </tr>
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th>
                            {/* Purchase */}
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th>
                            <th className="erp_table_th align-middle">Rate</th>
                            <th className="erp_table_th align-middle">Value</th>
                            {/* Adjust */}
                            <th className="erp_table_th align-middle">Add</th>
                            <th className="erp_table_th align-middle">Reduce</th>

                            {/* Return From Sizing */}
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th> 
                            {/* Return From Weaving */}
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th>
                            {/* Issued To Sizing */}
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th>
                            {/* Issued To Weaving */}
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th>

                            {/* Return from Supplier */}
                            {/* <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th> */}
                            {/* Closing Stock */}
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Data rows will be inserted here */}

                        <React.Fragment>
                            {storeStockReportData.map((row, index) => (
                                <tr key={index} rowindex={index}>
                                    <td className="erp_table_td text-end">{index + 1}</td>
                                    {/* <td className="erp_table_td" style={{ whiteSpace: 'normal' }}>{row.product_category1_name}</td>
                                            <td className="erp_table_td" style={{ whiteSpace: 'normal' }}>{row.product_category2_name}</td> */}
                                    <td className={`erp_table_td  `}>{row.material_code}</td>
                                    <td className="erp_table_td">
                                        {row.material_name}
                                    </td>
                                    <td className={`erp_table_td  `}>{row.lot_no}</td>
                                    <td className={`erp_table_td  `}>{row.supp_name}</td>
                                    <td className={`erp_table_td  `}>{row.grn_remark}</td>
                                    <td className={`erp_table_td  `}>{`-`}</td>
                                    <td className={`erp_table_td  `}>{row.total_quantity}</td>
                                    {/* Opening section  */}
                                    <td className="erp_table_td text-end">{row.opening_no_of_boxes}</td>
                                    <td className="erp_table_td text-end">{row.opening_quantity}</td>
                                    <td className="erp_table_td text-end">{row.opening_total_box_weight}</td>
                                    {/* <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(row.opening_average_batch_rate, 4)}</td>
                                    <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(row.opening_total_material_value, 4)}</td> */}

                                    {/* Purchase section  */}
                                    <td className="erp_table_td text-end">{row.purchase_no_of_boxes}</td>
                                    <td className="erp_table_td text-end">{row.purchase_quantity}</td>
                                    <td className="erp_table_td text-end">{row.purchase_total_box_weight}</td>
                                    <td className="erp_table_td text-end">{decimalNumber(row.purchase_average_batch_rate, 4)}</td>
                                    <td className="erp_table_td text-end">{decimalNumber(row.purchase_total_material_value, 4)}</td>

                                    {/* Adjust section  */}
                                    {/* <td className="erp_table_td text-end">{row.adjust_by_add}</td> 
                                    <td className="erp_table_td text-end">{row.adjust_by_reduce}</td> */}

                                    <td className="erp_table_td text-end">{row.sub_department_id}</td> 
                                    <td className="erp_table_td text-end">{row.sub_department_name}</td>


                                        {row.sub_department_id ? (
                                            (row.sub_department_id === 62 || row.sub_department_id === "62" || row.sub_department_id === 95 || row.sub_department_id === "95")  ? (
                                                <>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">{row.issue_no_of_boxes}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.issue_average_batch_rate, 4)}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.issue_total_material_value, 4)}</td>
                                                

                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">{row.product_material_issue_return_boxes}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.product_material_issue_return_quantity, 4)}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.product_material_issue_return_weight, 4)}</td>
                                                    
                                                   
                                                </>
                                            ) : (row.sub_department_id === 94 || row.sub_department_id === "94")  ? (
                                                <>
                                                    <td className="erp_table_td text-end">{row.issue_no_of_boxes}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.issue_average_batch_rate, 4)}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.issue_total_material_value, 4)}</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>

                                                    <td className="erp_table_td text-end">{row.product_material_issue_return_boxes}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.product_material_issue_return_quantity, 4)}</td>
                                                    <td className="erp_table_td text-end">{decimalNumber(row.product_material_issue_return_weight, 4)}</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>

                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                    <td className="erp_table_td text-end">0</td>
                                                </>
                                            )
                                        ) : (
                                            <>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>

                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                <td className="erp_table_td text-end">0</td>
                                                    
                                            </>
                                        )}

                                    {/* Issued To weaving section  */}
                                    <td className="erp_table_td text-end">{row.closing_no_of_boxes}</td>
                                    <td className="erp_table_td text-end">{row.closing_balance_quantity}</td>
                                    <td className="erp_table_td text-end">{row.closing_total_box_weight}</td>
                                    {/* <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(row.closing_average_batch_rate, 4)}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(row.closing_total_material_value, 4)}</td> */}

                                </tr>
                            ))}

                            <tr className='table-light'>
                                <td className="erp_table_td align-middle" colSpan={'7'}><strong>Totals:</strong></td>
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.total_quantity, 0)}</th>
                                {/* Opening */}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.opening_no_of_boxes, 0)}</th>
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.opening_quantity, 0)}</th>
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + summary.opening_total_box_weight, 0), 4)}</th>

                                {/* purchase */}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.purchase_no_of_boxes, 0)}</th>
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.purchase_quantity, 0)}</th>
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + summary.purchase_total_box_weight, 0), 4)}</th>
                                <td className="erp_table_td" > </td>
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + summary.purchase_total_material_value, 0), 4)}</th>

                                {/* adjust */}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.adjust_by_add, 0)}</th>
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.adjust_by_reduce, 0)}</th>

                                {/* issue Sizing*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 94 || summary.sub_department_id === "94")  ? summary.issue_no_of_boxes : 0), 0)}</th> {/* issue boxes*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 94 || summary.sub_department_id === "94")  ? summary.issue_average_batch_rate : 0), 0)}</th>{/* issue cones*/}
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 94 || summary.sub_department_id === "94")  ? summary.issue_total_material_value : 0), 0), 4)}</th>{/* issue weight kg*/}

                                {/* issue Weaving*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 62 || summary.sub_department_id === "62" || summary.sub_department_id === 95 || summary.sub_department_id === "95" )  ? summary.issue_no_of_boxes : 0), 0)}</th> {/* issue boxes*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 62 || summary.sub_department_id === "62" || summary.sub_department_id === 95 || summary.sub_department_id === "95" )  ? summary.issue_average_batch_rate : 0), 0)}</th>{/* issue cones*/}
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 62 || summary.sub_department_id === "62" || summary.sub_department_id === 95 || summary.sub_department_id === "95" )  ? summary.issue_total_material_value : 0), 0), 4)}</th>{/* issue weight kg*/}

                                {/* return from Sizing*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 94 || summary.sub_department_id === "94")  ? summary.product_material_issue_return_boxes : 0), 0)}</th> {/* issue boxes*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 94 || summary.sub_department_id === "94")  ? summary.product_material_issue_return_quantity : 0), 0)}</th>{/* issue cones*/}
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 94 || summary.sub_department_id === "94")  ? summary.product_material_issue_return_weight : 0), 0), 4)}</th>{/* issue weight kg*/}

                                {/* return from  Weaving*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 62 || summary.sub_department_id === "62" || summary.sub_department_id === 95 || summary.sub_department_id === "95" )  ? summary.product_material_issue_return_boxes : 0), 0)}</th> {/* issue boxes*/}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 62 || summary.sub_department_id === "62" || summary.sub_department_id === 95 || summary.sub_department_id === "95" )  ? summary.product_material_issue_return_quantity : 0), 0)}</th>{/* issue cones*/}
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + ((summary.sub_department_id === 62 || summary.sub_department_id === "62" || summary.sub_department_id === 95 || summary.sub_department_id === "95" )  ? summary.product_material_issue_return_weight : 0), 0), 4)}</th>{/* issue weight kg*/}

                                {/* closing */}
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.closing_no_of_boxes, 0)}</th>
                                <th className="erp_table_th text-end" >{storeStockReportData.reduce((sum, summary) => sum + summary.closing_balance_quantity, 0)}</th>
                                <th className="erp_table_th text-end" >{decimalNumber(storeStockReportData.reduce((sum, summary) => sum + summary.closing_total_box_weight, 0), 4)}</th>
                            </tr>
                        </React.Fragment>

                    </tbody>
                </Table>
            </div>
        </>
    );
};
