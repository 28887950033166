import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';

import { useLocation, useNavigate } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MDButton from "components/MDButton";
// Error Fields
import MDTypography from "components/MDTypography";
// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import ConfigConstants from 'assets/Constants/config-constant';
import FrmValidations from 'FrmGeneric/FrmValidations';

function FrmProductUnitEntry(props) {
  //changes by ujjwala on 6/1/2024 case no 1
  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
  const { state } = useLocation();
  const location = useLocation();
  const { productUnitId = 0, keyForViewUpdate, compType } = state || {}

  const child = useRef();
  const validate = useRef();
  //end by ujwala
  const [producttypeTypeOption, setProducttypeTypeOption] = useState([]);
  const {closeAddModal}=props
  let companyId = sessionStorage.getItem('companyID')
  let activeValue = '';

  // Add Product Type Form Fields
  const [product_unit_id, setProductUnitId] = useState(productUnitId);
  const [txt_product_unit_name, setProductUnitName] = useState('');
  const [txt_product_unit_short_name, setProductUnitShortName] = useState('');
  const [txt_remark, setRemark] = useState('');
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')
  // For navigate
  const navigate = useNavigate();

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  // const handleCloseSuccessModal = () => { setShowSuccessMsgModal(false); }

  // Success Msg HANDLING

  // const handleCloseSuccessModal = () => {
  //   setShowSuccessMsgModal(false);
  //   if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
  //     navigate(`/Masters/FrmProductUnitEntry`)
  //   }
  // }

  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false)
    if(sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo'){
      navigate(`/Masters/FrmProductUnitListing`);
    }
    else{
      closeAddModal()
    }
  };


  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');
  //changes by ujjwala on 6/1/2024 case no 1
  useEffect(() => {
    const loadDataOnload = async () => {
      await ActionType()
      // await FnFetchPropertyRecords();
      if (productUnitId !== 0) {
        await FnCheckUpdateResponce()
      }
    }
    loadDataOnload()
  }, [])

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        //if else for Add or Update
        if(!props.addNewProd){
          setActionType('(Modification)');
          setActionLabel('Update')
          $('#txt_product_unit_name').attr('disabled', true);
          $('#txt_product_unit_short_name').attr('disabled', true);
        }
        else{
          setActionType('(Creation)');
          setActionLabel('Add')
        }
        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("productunitFormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Creation)');
        setActionLabel('Add')
        break;
    }
  };

  const validateFields = () => {
    validate.current.validateFieldsOnChange('productunitFormId')
  }
  //end by ujjwala
  const FnFetchPropertyRecords = async () => {
    var controlName = ["smv_product_unit"];
    if (child.current) {
      var productUnit = await child.current.fillMasterData(controlName[0], "", "")
      console.log("propertyList: ", productUnit);
      setProducttypeTypeOption(productUnit)
    }
  }

  const FnCheckUpdateResponce = async () => {
    debugger
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productunit/FnShowParticularRecordForUpdate/${product_unit_id}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();
      let resp = updateRes.data
      setProductUnitId(resp.product_unit_id)
      setProductUnitName(resp.product_unit_name);
      setProductUnitShortName(resp.product_unit_short_name);
      setRemark(resp.remark);
      $('#saveBtn').show();
      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("productunitFormId");
      if (checkIsValidate === true) {
        let active;
        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
        }
        const data = {
          company_id: COMPANY_ID,
          product_unit_id: product_unit_id,
          created_by: UserName,
          modified_by: product_unit_id === 0 ? null : UserName,
          product_unit_name: txt_product_unit_name,
          product_unit_short_name: txt_product_unit_short_name,
          remark: txt_remark,
          is_active: active,

        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productunit/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()

        if (responce.success !== "1") {
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)
        } else {
          const evitCache = child.current.evitCache();
          console.log(evitCache);
          clearFormFields();
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);

        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };
  const clearFormFields = () => {
    setProductUnitName('');
    setProductUnitShortName('');
    setRemark('');
  }

  return (
    <>
      <ComboBox ref={child} />
      <FrmValidations ref={validate} />
      <div className='erp_top_Form'>
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Product Unit {actionType} </label>
          </div>
          <form id="productunitFormId">
            <div className="row erp_transporter_div">

              {/* first row */}

              <div className="col-sm-6 ">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Unit Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_unit_name" className="erp_input_field" value={txt_product_unit_name} onChange={e => { setProductUnitName(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_unit_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Unit Short Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_unit_short_name" className="erp_input_field" value={txt_product_unit_short_name} onChange={e => { setProductUnitShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" />
                    <MDTypography variant="button" id="error_txt_product_unit_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

              </div>

              {/* second row */}

              <div className="col-sm-6 ">
                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} optional="optional" maxLength="255" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* //changes by ujjwala on 6/1/2024 case no 1 */}
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"

              onClick={() => {
                const path = compType === 'Register' ? '/Masters/FrmProductUnitListing/reg' : '/Masters/FrmProductUnitListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
            <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>
          </div >
          {/* end by ujjwala */}
        </div>
        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      </div>
    </>
  )
}

export default FrmProductUnitEntry
